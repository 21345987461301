import React from "react";
import {Form} from 'react-bootstrap';

import 
{
    BoxAddProduto, 
    BoxHeader, 
    BoxContainer, 
    BoxIconeVoltar, 
    BoxFoto,
    BoxObs,
    BoxQtdProduto,
    BoxQtdMais,
    BoxQtdMenos,
    BoxInput,
    BoxQtdGeral,
    BoxAddSacola,
    BoxBotaototal,
    BoxModal

} from './style' 

import IconeVoltar from '../../assets/Commons/ArrowIcon.svg';
import { getToken, logout } from "../../service/auth";
import UsuarioProdutoApiNode from "../../service/produtosApiNode";
import {createDb} from '../../db/index'
import { ProductComplement } from "../../components/ProductComplement";


class AddProdutos extends React.Component{
    constructor(props){
        super(props)

        this.state ={
            produto:[],
            preco_venda:0.00,
            QtdProduto:1,
            total:0.00,
            disabled: false,
            obs:'',
        }
    }

    async componentDidMount (){
        // essa função this.CarregandoProdutoParaEdicao deve estar nesta ordem de precedencia para evitar erro de estado
        this.CarregandoProdutoParaEdicao();    
    }

    updateTotalProduct = (value) => {
        const {produto, total, QtdProduto} = this.state;

        produto[0].totalComplemento = produto[0].totalComplemento + (value);
        
        if (produto[0].totalComplemento > 0  ) {
            let resultComplement = produto[0].totalComplemento * QtdProduto
            let totalProduct =  produto[0].precoUnitario * QtdProduto;
            totalProduct = totalProduct + resultComplement
            
        
            this.setState({produto: produto, total:  totalProduct})
        }
        else{

            this.setState({produto: produto, total: produto[0].precoUnitario * QtdProduto }) 
        }

    }

    updateTotalProductRadio = (value) => {

        if (value.marcado === 'S') return this.updateTotalProduct(value.preco)
        if (value.marcado === 'N')return this.updateTotalProduct(- value.preco)
    }

    alterarProduto = async() => {
        try {

            const validateProductComplement = this.props.complementProduct.filter(item => item.qtd2 < item.qtd && item.tipo === 'OB' ? item.id : 0 )

            if (validateProductComplement.length === 0){
                const {QtdProduto, obs, produto} = this.state;
                const {params:{nome}} = this.props.location.match;
                const  [{
                            id,
                            usuarioId,
                            foto,
                            codigo,
                            produtoId,
                            precoUnitario,
                            descricaoProduto,
                            grupoId,
                            
                        }] = produto


                const  Nome = produto[0].nome;
                
                const service = new UsuarioProdutoApiNode();
                const result  = await service.updateProduto(id,{
                    produtoId:produtoId,
                    usuarioId: usuarioId,
                    foto:foto,
                    codigo:codigo,
                    nome: Nome,
                    link_web:nome,
                    obs: obs,
                    precoUnitario: precoUnitario,
                    total: ((QtdProduto * precoUnitario) +  (produto[0].totalComplemento * QtdProduto)),
                    descricaoProduto: descricaoProduto,
                    grupoId:grupoId,
                    qtd: QtdProduto,
                    banco: this.props.banco_cardapio,
                    acompanhamento:this.props.complementProduct.length > 0 ? true: false,
                    totalComplemento: produto[0].totalComplemento,
                    produtoAcompanhamento:this.props.complementProduct
                });



                if (result.status === 200){
                    this.voltarProdutos();
                    this.props.totalSacola();

                }
            }
            else{
                this.verifyMandatoryComplement(validateProductComplement)
            } 
    
        
        } catch (error) {
            console.log(error)
            
        }
    }

    removendoLocalStorege = () => {
        logout('IDUSUARIO');
        logout('TOKEN');
        logout('CPF_CNPJ');
        logout('CLIENTE');
        logout('NUMERO_WEB');
        logout('VALORTROCO');
        logout('FORMAPAGAMENTO');
        logout('NOMEFORMAPAGAMENTO');
    }

    addProduto = async() => {
        try {

            const validateProductComplement = this.props.complementProduct.filter(item => item.qtd2 < item.qtd && item.tipo === 'OB' ? item.id : 0 )


         
            if (validateProductComplement.length === 0){

                //    removendo o id
                this.props.complementProduct.map(item => delete item.id);
                //    removendo o id
                this.props.complementProduct.map(item => item.itens.map(item => delete item.id));

                this.setState({disabled: true});
                const {produto, obs, QtdProduto} = this.state;
                const {params:{nome}} = this.props.location.match;
    
                const [{codigo, grupoId, id, foto, precoUnitario, descricaoProduto}] = produto;
                let Nome = produto[0].nome

                const total = ((precoUnitario * QtdProduto) + (produto[0].totalComplemento * QtdProduto));
                const usuarioId = getToken('IDUSUARIO');

                const service = new UsuarioProdutoApiNode();
                const result = await service.Add({
                    usuarioId,
                    produtoId: id,
                    grupoId,
                    nome:Nome,
                    link_web: nome,
                    foto: foto === undefined ? 'semFoto' : foto,
                    codigo,
                    obs,
                    precoUnitario,
                    total,
                    descricaoProduto,
                    qtd:QtdProduto,
                    banco: this.props.banco_cardapio,
                    acompanhamento:this.props.complementProduct.length > 0 ? true: false,
                    totalComplemento: produto[0].totalComplemento,
                    produtoAcompanhamento:this.props.complementProduct
                });

                if (result.status === 201) {
                    this.setState({disabled: false});
                    this.voltarProdutos();
                    this.props.totalSacola();
                
                }
            }
            else{

                this.verifyMandatoryComplement(validateProductComplement);
            }
   

        } catch (error) {
            this.setState({disabled: false});
            createDb();
        }
    }


    verifyMandatoryComplement = (array) =>  {
        let id = array[0].id;
        const element = document.getElementById(`productComplement${id}`);
        const indexArray = element.tabIndex
        this.props.updateComplementProductColor(true ,indexArray)
        element.focus();
    }

    CarregandoProdutoParaEdicao = async () => {
        const location = this.props.location    
        const rota = location.match.path;
        const {params: {id}} = this.props.location.match;

  

        if (rota === "/Sacola/AdicionarProduto/:id/:nome") {
            this.verificarUsuarioLogado()
        }
        else{
            await this.props.produtoAcompanhamento(id);
            await this.props.carregarProduto();
            this.setState({produto: this.props.produtosAdd, total: this.props.produtosAdd[0].precoUnitario * this.state.QtdProduto})
        }
    }

    pegandoProdutoBanco = async () => {
        try {
            const {params:{id}} = this.props.location.match
            this.props.updateComplemento([]);
            const service = new UsuarioProdutoApiNode();

            const result = await service.getProduto({
                id,
                banco: this.props.banco_cardapio
            });


            if (result.status === 200) {
                this.props.updateComplementProductOne(id);
                this.setState({
                    QtdProduto: await parseInt(result.data.qtd),
                    total: await parseFloat(result.data.total),
                    preco_venda:await  result.data.precoUnitario,
                    produto: await [result.data],
                    obs:await result.data.obs,
                    banco: this.props.banco_cardapio
                })
            }
    

        } catch (error) {
            console.log(error)
        }
    }

    verificarUsuarioLogado =  () => {
        const usuarioLogado = getToken('IDUSUARIO');

        if (usuarioLogado !== '' && usuarioLogado !== null){
        this.pegandoProdutoBanco();
        } 
        else{
            this.getProdutoIndexDB();
        }
    }

    getProdutoIndexDB = () => {
        const {params:{id}} = this.props.location.match; 

        let db ;
        
        const request = indexedDB.open('cardapio', 2 );  

        request.onsuccess =  () => {
            db = request.result;

            const transaction = db.transaction(["Produtos"], "readwrite")
            const objectStore = transaction.objectStore("Produtos")
            const result =  objectStore.get(parseInt(id))
      
            result.onsuccess = async () => {
            
                this.setState({
                    QtdProduto: await result.result.qtd,
                    total: await parseFloat((result.result.total)),
                    produto:  await [result.result],
                    obs: await result.result.obs
                })

             
                this.props.addIndexDbComplementProduct(result.result.produtoAcompanhamento)
            }
        }
    }

    changeQtdMenos = (value)  => {
        const{produto} = this.state;
        let valor = value - 1;

        let total = this.props.complementProduct.length > 0 ? ((produto[0].precoUnitario * valor) + (produto[0].totalComplemento * valor)) : (produto[0].precoUnitario * valor);

        if (valor < 1) {
            valor = 1
            total =  this.props.complementProduct.length > 0 ? ((produto[0].precoUnitario * valor) + (produto[0].totalComplemento * valor)) : 
            (produto[0].precoUnitario * valor)   

    
        }

        this.setState({
            QtdProduto: valor,
            total: total
        })
    }

    changeQtdMais = (value)  => {

        const{produto} = this.state;


        let valor = value + 1;

        let total = this.props.complementProduct.length > 0 ? ((produto[0].precoUnitario * valor) + (produto[0].totalComplemento * valor)) : (produto[0].precoUnitario * valor)

        if (valor <= 100) {
            this.setState({
                QtdProduto: valor,
                total: total
            })
        }
 
    }

    gravarBancoOuLocal = async() => {

        const usuarioLogado = getToken('IDUSUARIO');
        const location = this.props.location ;
        const rota = location.match.path;

        let statusLoja = this.props.parametro.map(item => item.status_cardapio);

        if (statusLoja.toString() === 'S') {
            if (usuarioLogado !== '' && usuarioLogado !== null){

                if (rota === "/Sacola/AdicionarProduto/:id/:nome") {
                    this.alterarProduto();
                }
                else{
                    this.addProduto();
                }
    
            } 
            else{
                this.gravandoIndexDB();
            }
        }
        else{
            alert('Estabelecimento fechado!')
        }
   
    }

    gravandoIndexDB = () => {  
        const location = this.props.location ;
        const rota = location.match.path;

            const validateProductComplement = this.props.complementProduct.filter(item => item.qtd2 < item.qtd && item.tipo === 'OB' ? item.id : 0 )

            if (validateProductComplement.length === 0) {
                if (rota === "/Sacola/AdicionarProduto/:id/:nome") {
                    this.atualizandoValor();
                }
                else{
                    this.addBancoLocal();
                }
                this.props.totalSacola();
                this.voltarProdutos() ;
            }
            else{
                this.verifyMandatoryComplement(validateProductComplement);
            }



    }

    addBancoLocal = () => {
        const arrayObjeto = this.refatorandoArray();
        delete arrayObjeto[0].id;
        
        let db;
        const request = indexedDB.open('cardapio', 2 );  

        request.onsuccess =  () => {

            db = request.result;   

            const transaction = db.transaction(["Produtos"], "readwrite");
            const objectStore =  transaction.objectStore("Produtos")
            arrayObjeto.map(item => objectStore.add(item))     
            db.close();
        }

    }

    refatorandoArray = () => {

        const { QtdProduto, obs, produto,total} = this.state;

        const {params :{id}} = this.props.location.match;
        const {params :{nome}} = this.props.location.match;

        const arrayObjeto = []
        let Nome = produto[0].nome;
        let link_web = nome;
        let codigo =  produto[0].codigo;
        let qtd = QtdProduto;
        let grupoId = produto[0].grupoId;
        let usuarioId = 0;
        let foto =  produto[0].foto;
        let precoUnitario =  produto[0].precoUnitario;
        let descricaoProduto =  produto[0].descricaoProduto;
        let totalComplemento = produto[0].totalComplemento;

        arrayObjeto.push({
            id : parseInt(id),
            produtoId:produto[0].id,
            nome:Nome.toString(), 
            link_web,
            codigo:codigo.toString(), 
            precoUnitario:parseFloat(precoUnitario), 
            grupoId: parseInt(grupoId),
            usuarioId:parseInt(usuarioId),
            total: parseFloat(total).toFixed(2), 
            qtd:qtd, 
            obs:obs , 
            foto:foto,
            descricaoProduto: descricaoProduto.toString(),
            totalComplemento,
            acompanhamento:this.props.complementProduct.length > 0 ? true: false,
            produtoAcompanhamento:this.props.complementProduct
        })

        return arrayObjeto  
    }

    verificandoSeExiste = () => {
        const arrayObjeto = this.refatorandoArray();
        let db;
        let request = indexedDB.open('cardapio', 2 );  

        request.onsuccess =  () => {
            db = request.result;   
            const transaction = db.transaction(["Produtos"], "readwrite");
            const objectStore =  transaction.objectStore("Produtos")
            arrayObjeto.map(item => objectStore.put(item))           

            db.close();
        }
    }

    atualizandoValor = () => {
        this.verificandoSeExiste()
    }

    fecharModalAdd = (e) => {
        if (this.props.idd === e.target.id) {
            this.props.resetComplementProduct();
            this.props.FecharModal();
        }
    }

    voltarProdutos = () => {
        this.props.resetComplementProduct();
        this.props.FecharModal();
    }

    render() {
        const {produto, QtdProduto, disabled, total} = this.state;
        return(
            <>
                <BoxModal id={this.props.idd} onClick={(e) => this.fecharModalAdd(e)} >
                        <BoxContainer >
                            <BoxHeader>
                                <BoxIconeVoltar src={IconeVoltar}  onClick={() => this.voltarProdutos()}/>
                                <h1>Adicionar Item</h1>
                            </BoxHeader>

                            <BoxAddProduto>
                                      
                                <div className="image-wrapper">  
                                    {                           
                                        produto.length > 0 ? (         
                        
                                                <div id='container-image'>
                                                    <BoxFoto src={`${this.props.foto}${produto.map(item => item.foto)} `} />                                           
                                                </div>
                                                        
                                        )
                                        :(
                                            null
                                        )
                                    }
                                </div> 
                           
                                <div id='containerNome'>
                                    <p id='nome'>
                                        {
                                            produto.length > 0 ? (
                                                produto[0].nome
                                            ):(
                                                null
                                            )
                                            
                                        }
                                    </p>

                                    <p id='infoproduto'>
                                
                                        {
                                            produto.length > 0 ? (
                                                produto[0].descricaoProduto 
                                            )
                                            :(
                                                null
                                            )
                                        }
                                        
                                    </p>
                                </div>

                                {
                                    this.props.complementProduct.length > 0?(
                                        <ProductComplement 
                                            complementProduct={this.props.complementProduct}
                                            updateComplementProduct={this.props.updateComplementProduct}
                                            updateComplementProductItemsQtsAnyLess={this.props.updateComplementProductItemsQtsAnyLess}
                                            updateTotalProduct= {this.updateTotalProduct}
                                            updateComplementProductItemsRadio={this.props.updateComplementProductItemsRadio}
                                            updateTotalProductRadio={this.updateTotalProductRadio}
                                        />
                                    )
                                    :(
                                        null
                                    )
                                }
                                        

                                <div id='obs'>               
                                    <p>Alguma observação?</p>
                                </div>

                                <BoxObs
                                    placeholder="Ex: Sem molho"
                                    onChange={(e) => this.setState({obs: e.target.value})}                         
                                    value={this.state.obs}   
                                >
                                </BoxObs>
                            </BoxAddProduto>

                            <BoxQtdProduto>

                                <BoxQtdGeral>
                                    <BoxQtdMenos
                                        onClick={() => this.changeQtdMenos(this.state.QtdProduto)}
                                    >
                                        -
                                    </BoxQtdMenos>    

                                        <BoxInput>
                                            <Form.Control 
                                                readOnly={true}
                                                maxLength={3}
                                                value={QtdProduto}
                                            >

                                            </Form.Control>
                            
                                        </BoxInput>

                                    <BoxQtdMais
                                        onClick={() => this.changeQtdMais(QtdProduto)}
                                    >
                                        +
                                    </BoxQtdMais> 
                                </BoxQtdGeral>

                                <BoxBotaototal>
                                    <button onClick={() => this.gravarBancoOuLocal()} disabled={disabled}>
                                        <BoxAddSacola>
                                            <span>Adicionar

                                                    {
                                                        produto.length > 0 ?  (
                                                            <p>
                                                                {parseFloat(total).toLocaleString('pt-br', {style:'currency', currency:'BRL'}) } 
                                                            </p>
                                                        )
                                                        :(  
                                                            null
                                                        )
                                                    }
                 
                                            </span>
                                           
                                        </BoxAddSacola>
                                    </button>
                                </BoxBotaototal>
                            </BoxQtdProduto>            
                        </BoxContainer>
                </BoxModal>
            </>
        )
    }
}
export default AddProdutos
