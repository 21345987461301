
import {LogoClientFrame, Container,ContainerWrapper, LogoBannerClient, ContainerLogoBannerClient } from "./style"

export const LogoClient = ({parametro, banner}) =>{
 
    return (
    <Container>      

        <ContainerLogoBannerClient>

            {
                banner.length > 0 ? (
                    <LogoBannerClient  src={banner.map(item => `/${item.link}${item.foto_descricao}`)}/>
                )
                :(
                    null
                )
            }
            
        </ContainerLogoBannerClient> 

        <ContainerWrapper>
            <LogoClientFrame  src={parametro.map(item => (item.site_url_logo))} />
        </ContainerWrapper>

    </Container>
    )
}