import React from "react";
import { Row, Col } from "react-bootstrap";
import { BoxContainer } from "./style";

function NotFound() {
  return (
    <BoxContainer>
      <Row>
        <Col>
          <h1>Not Found 404</h1>
        </Col>
      </Row>
    </BoxContainer>
  );
}
export default NotFound;
