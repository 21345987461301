import styled from "styled-components";

export const BoxContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  flex-direction: column;
  background: ${props=>props.theme.background.Primary};
  width: 100%;
  max-width: 520px;

  #wrapper-container {
    display: flex;
    flex-direction: column;
    max-width: 520px;
    overflow-y: auto;
    height: 100%;
  }

  @media (min-width: 280px) and (max-width: 520px) {
    left: 0;
    width: 100%;
  }
`;

export const BoxModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2020;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const BoxHeader = styled.header`
  width: 100%;
  text-align: center;
  padding: 1em;
  background: ${props=>props.theme.background.Primary};
  height: 52px;
  border-bottom: solid 1px ${props=>props.theme.colors.Grey_2};

  h1 {
    margin: 0;
    padding: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${props=>props.theme.text.Black_1};
  }
`;

export const BoxIconeVoltar = styled.img`
  width: 24px;
  height: 24px;
  display: flex;
  position: absolute;
  top: 12px;
  border: none;
  cursor: pointer;
`;

export const BoxContainerStatus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  margin: 24px 32px 32px;
  height: auto;
  border: 1px solid ${props=>props.theme.colors.Grey_2};
  border-radius: 10px;
`;

export const BoxWrapperPedido = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  width: 100%;
  height: auto;

  #status-pedido-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 123px;
    gap: 4px;
    margin-bottom: 10px;

    #status-pedido {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }

    #numero-pedido {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
    }
  }
`;

export const BoxStatusPedido = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 10px;
  gap: 8px;
  background: ${(props) => props.statusOrder};
  border-radius: 15px;
`;

export const BoxContainerItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 16px;
  gap: 12px;
  margin-top: 16px;
  width: 100%;
  border-bottom: 2px dashed ${props=>props.theme.colors.Grey_7};
`;


export const BoxContainerFooterTotal = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px 0px 16px;
  gap: 10px;
  width: 100%;
  height: 29px;
`;

export const BoxItems = styled(BoxContainerFooterTotal)`
  padding: 0px;
  flex-wrap: wrap;
  height: auto;
  //display: flex;
  //justify-content: space-between;
  //align-items: flex-start;
  //gap: 10px;
  //width: 100%;
`;

export const BoxContainerDeliveryFee = styled(BoxItems)`
  height: 18px;
  flex-wrap: nowrap;
  /* display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  width: 100%; */
`;

export const BoxItemsText = styled.div`
  display: flex;
  align-items: center;
  padding: 0px;
  gap: 5px;
  width: 70%;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  /* p {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  } */
`;

//tomar como base componente existente
export const BoxItemsTextTotal = styled(BoxItemsText)`
  width: 25%;
  height: 100%;
  justify-content: right;
  /* display: flex;
  align-items: center;
  padding: 0px;
  gap: 5px; */
  /* font-weight: 400;
  font-size: 14px;
  line-height: 18px; */
  /* p {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
  } */
`;

export const BoxQtd = styled.div`
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0px;
  height: 0px;
  padding: 8px;
  background: ${props=>props.theme.background.Grey_1};
  border-radius: 50%;
  color: ${props=>props.theme.text.Black_1};
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
`;


export const BoxContainerTextDeliveryFee = styled.div`
  display: flex;
  align-items: center;
  padding: 0px;
  gap: 5px;
  width: 170px;
  height: 18px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${props=>props.theme.text.Primary};

  /* p {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${props=>props.theme.text.Primary};
  } */
`;

export const BoxContainerTotalDeliveryFee = styled(BoxContainerTextDeliveryFee)`
  width: auto;
  /* justify-content: right; */
  /* background-color: aqua; */
`;

// export const BoxContainerTotalDeliveryFee = styled.div`
//   display: flex;
//   align-items: center;
//   padding: 0px;
//   justify-content: right;
//   gap: 5px;
//   width: 110px;
//   height: 18px;

//   p {
//     font-weight: 400;
//     font-size: 14px;
//     line-height: 18px;
//     color: ${props=>props.theme.text.Primary};
//   }
// `;



export const BoxContainerFooterTotalValue = styled.div`
  display: flex;
  align-items: center;
  padding: 0px;
  gap: 5px;
  width: 100px;
  height: 17px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${props=>props.theme.text.Black_1};
  /* p {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: ${props=>props.theme.text.Black_1};
  } */
`;

export const BoxContainerFooterTotalText = styled(BoxContainerFooterTotalValue)`
  justify-content: right;
  /* display: flex;
  align-items: center;
  padding: 0; */
  /* gap: 5px;
  width: 100px;
  height: 17px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: ${props=>props.theme.text.Black_1};
  /* P { */
    /* font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: ${props=>props.theme.text.Black_1};
  } */ 
`;

export const BoxContainerMostdetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 16px;
  margin: 24px 32px 32px;
  gap: 12px;
  max-width: 520px;
  height: 304px;
`;

export const BoxWrapperMostDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 16px;
  gap: 16px;
  width: 100%;
  height: 111px;
  border-bottom: 1px solid ${props=>props.theme.colors.Grey_2};

  h1 {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: ${props=>props.theme.text.Black_1};
  }

  #container-wrapper-info-delivery {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 4px;
    width: 100%;
    height: 62px;
  }

  #wrapper-numeric-text, #wrapper-date-text , #wrapper-hour-text {
    display: flex;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    width: 100%;
    height: 18px;
  }

  /* #wrapper-date-text {
    display: flex;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    width: 100%;
    height: 18px;
  }

  #wrapper-hour-text {
    display: flex;
    align-items: flex-start;
    padding: 0px;
    gap: 10px;
    width: 100%;
    height: 18px;
  } */

  /* #hour-request {
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 0px;
    gap: 5px;
    width: 50%;
    height: 18px;

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: ${props=>props.theme.text.Primary};
    }
  } */

  /* #hour-text {
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 0px;
    gap: 5px;
    width: 50%;
    height: 18px;

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: ${props=>props.theme.text.Primary};
    }
  } */

  #date-request, #number-request, #hour-request {
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 0px;
    gap: 5px;
    width: 50%;
    height: 18px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${props=>props.theme.text.Primary};
    /* p {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: ${props=>props.theme.text.Primary};
    } */
  }
  
  /* #number-request {
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 0px;
    gap: 5px;
    width: 50%;
    height: 18px;

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: ${props=>props.theme.text.Primary};
    }
  } */

  #date-text, #number-text , #hour-text {
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 0px;
    gap: 5px;
    width: 50%;
    height: 18px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${props=>props.theme.text.Primary};
    /* p {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: ${props=>props.theme.text.Primary};
    } */
  }


  /* #number-text {
    display: flex;
    justify-content: right;
    align-items: center;
    padding: 0px;
    gap: 5px;
    width: 50%;
    height: 18px;

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: ${props=>props.theme.text.Primary};
    }
  } */
`;

export const BoxWrapperDeliveryAddress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: 100%;
  height: 82px;
`;

export const BoxWrapperFormOfPayment = styled(BoxWrapperDeliveryAddress)`
  height: 71px;
  /* display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  gap: 12px;
  width: 100%; */
`;

export const BoxHeaderDeliveryAddress = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  width: 100%;
  height: 18px;

  h2 {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${props=>props.theme.text.Black_1};
  }
`;

export const BoxWrapperAddress = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px 0px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${props=>props.theme.text.Primary};
  gap: 4px;
  width: 100%;
  height: 52px;
  border-bottom: 1px solid ${props=>props.theme.colors.Grey_2};

  /* p {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${props=>props.theme.text.Primary};
  } */
`;


export const BoxWrapperFormOfPaymentHeader = styled.div`
  display: flex;
  align-items: flex-start;
  padding: 0px;
  gap: 10px;
  width: 100%;
  height: 18px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${props=>props.theme.text.Black_1};
  /* p {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${props=>props.theme.text.Black_1};
  } */
`;

export const TextWrapper = styled.p`
   color: ${(props)=> props.statusColorText === props.theme.text.Yellow_2 ? props.theme.text.Yellow_1 : props.theme.text.White_1 };
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
`;

export const BoxWrapperFormOfPaymentText = styled(BoxWrapperFormOfPaymentHeader)`
  gap: 4px;
  width: 100%;
  height: 41px;
  color: ${props=>props.theme.text.Primary};
  /* display: flex; */
  /* flex-direction: column; */
  /* align-items: flex-start; */
  /* padding: 0px; */
  /* font-weight: 400; */
  /* font-size: 14px; */
  /* line-height: 18px; */

  /* p {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${props=>props.theme.text.Primary};
  } */
`;
