import * as S from "./style";
import iconCountBagItems from "../../assets/InfoBag/iconCountBagItems.svg";
import iconBubbleFormBagInfo from "../../assets/InfoBag/iconBubbleFormInfoBag.svg";
import iconBubbleFormBagInfoRed from "../../assets/InfoBag/iconBubbleFormInfoBagRed.svg";
export const InfoBagBox = (props) => {
  const { totalPurchases, totalItemsBag } = props;

  const mudarUrl = () => {
    if (totalItemsBag > 0) {
      props.carregarProdutosSacola();
    }
  };

  return (
    <S.Container>
      <S.InfoBox onClick={mudarUrl} totalItemsBag={totalItemsBag}>
        <S.InfoBagBoxWrapper
          changeBackground={totalItemsBag > 0 ? props=>props.theme.colors.Red : props=>props.theme.colors.Grey_5}
        >
          <div className="qtd-items-bag">
            <S.BoxQtdProdutosSacola
              changeBackground={totalItemsBag > 0 ? props=>props.theme.colors.Red_2 : props=>props.theme.colors.Black}
            >
              <p className="items-in-bag">{totalItemsBag}</p>
            </S.BoxQtdProdutosSacola>
            <S.BoxCountBagItems>
              <img src={iconCountBagItems} />
            </S.BoxCountBagItems>
          </div>
          <S.InfoBagBoxTextWrapper>
            {totalItemsBag > 0 ? (
              <>
                <div className="wrapper-1">
                  <p className="phrase-2">Abra a Sacola</p>
                  <p className="phrase-3">; {")"}</p>
                </div>
              </>
            ) : (
              <>
                <div className="wrapper-1">
                  <p className="phrase-1">Sacola vazia : {"("}</p>
                </div>
              </>
            )}
          </S.InfoBagBoxTextWrapper>
          <S.InfoBagBoxTotalValuesItems>
            <p className="total-values-items">
              {parseFloat(totalPurchases).toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })}
            </p>
          </S.InfoBagBoxTotalValuesItems>
        </S.InfoBagBoxWrapper>
        <S.InfoBagBoxBubbleForm>
          {totalItemsBag > 0 ? (
            <img src={iconBubbleFormBagInfoRed} alt="" />
          ) : (
            <img src={iconBubbleFormBagInfo} alt="" />
          )}
        </S.InfoBagBoxBubbleForm>
      </S.InfoBox>
    </S.Container>
  );
};
