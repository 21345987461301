import styled from "styled-components";


export const LogoClientFrame = styled.img`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 16.15px;
    position: absolute;
    width: 84px;
    height: 84px;
    border: 4.30769px solid #FFFFFF;
    border-radius: 20.8205px;
    flex: none;
    order: 2;
    flex-grow: 0;
    z-index: 2;



`;

export const ContainerLogoBannerClient = styled.div`
    display: flex;

    justify-content: center;
    width: 100%;
    
    height: 320px;

    @media (max-width:520px) {
        height: 158px;
    }

`;

export const LogoBannerClient = styled.img `
    max-width:1280px;
    object-fit: cover;

    border-radius: 0px 0px 32px 32px;
    @media (max-width:1360px) {
        width:100%;
        object-fit: contain; 
        height: 320px; 
    }

    @media (max-width:520px) {
        height: 158px;
        object-fit: cover;
    }
    
`;

export const ContainerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0px;
    gap: 16.15px;
    width: 100%;
    flex: none;
    order: 2;
    flex-grow: 0;
    z-index: 2;

`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0px;
    flex: none;
    width: 100%;
    height: 320px;

    @media (max-width:520px) {
        height: 158px;
    }

`;