import {createGlobalStyle} from "styled-components"

const GlobalStyle = createGlobalStyle`
   
   * {
        padding: 0;
        margin:0;
     }

    p{
        margin:0;
    } 

    body{
        font-family: 'Sora', sans-serif;
    }

`;

export default GlobalStyle;

    
