import baseAPI from './api';
import baseURLs from '../configs/baseURL';


//  criando uma classe gerica para ProdutoServices, para reutilizar o codigo toda vez que eu precisar chamar o end poit parametro
class ParametroServices {
    constructor (){
        this.api = baseAPI(baseURLs.SITE);
    }

    // retornando todos os dados do end point parametro
    async getAll(cnpj) {  
        const result = await this.api.post('carregarParametroCardapio',  cnpj );
        // pegando o retorno que vem no response
        
        return result.data;
        
    }

    async getBanner (cnpj) {
        const result = await this.api.post('banner', cnpj);

        return result.data;
    }

}


export default ParametroServices;