import * as S from './styles'
import IconVisiblePassword from '../../assets/Commons/IconVisiblePassword.svg'
import IconNotVisiblePassword from  '../../assets/Commons/IconNotVisiblePassword.svg'
import { useState } from 'react'

export const VisiblePassword = ({genericFunction}) => {


    const [visible, setVisible] = useState(false)

    const visibleText = () => {
        setVisible(!visible)
        genericFunction()

    }


    return(
        <S.Container>
            <S.IconPassword 
                src={visible ? IconNotVisiblePassword : IconVisiblePassword} 
                onClick={() => visibleText()} 

            />
        </S.Container>

    )
}