import styled from "styled-components";

export const BoxContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  flex-direction: column;
  background: ${props=>props.theme.background.Primary};
  max-width: 520px;
  @media (min-width: 280px) and (max-width: 520px) {
    width: 100%;
  }
`;

export const BoxModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2020;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const BoxIconeVoltar = styled.img`
  /* width: 24px;
  height: 24px; */
  display: flex;
  position: absolute;
  top: 12px;
  border: none;
  cursor: pointer;
`;

export const BoxAddProduto = styled.div`
  padding: 24px 12px 12px;
  display: flex;
  overflow-x: auto;
  display: flex;
  width: 520px;
  flex-direction: column;
  padding-bottom: 1em;

  @media (min-width: 280px) and (max-width: 520px) {
    width: 100%;
  }
`;

export const BoxHeader = styled.header`
  width: 100%;
  text-align: center;
  padding: 1em;
  background: ${props=>props.theme.background.Primary};
  height: 52px;
  border-bottom: solid 1px ${props=>props.theme.colors.Grey_2};

  h1 {
    margin: 0;
    padding: 0;
    text-align: center;
    /* justify-content: center; */
    /* align-items: center; */
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${props=>props.theme.text.Black_1};
  }
  `;

export const BoxInfoProdutos = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${props=>props.theme.text.Black_1};
  font-weight: 400;
  margin-left: 32px;
`;

export const BoxSacolaProdutos = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0.5em;
  align-items: center;
  font-weight: 600;
  font-size: 12px;

  .fotoProduto {
    flex-direction: row;
    display: flex;
    width: 52px;
  }

  @media (min-width: 280px) and (max-width: 520px) {
    width: 100%;
  }
`;

export const WrapperBoxTotais = styled.div`
  width: 100%;
  max-width: 520px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const BoxTotais = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 520px;
  width: 454px;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  color: ${props=>props.theme.text.Black_1};
  border-top: 1px solid ${props=>props.theme.colors.Grey_2};
  padding: 24px;
  background-color: ${props=>props.theme.background.Grey_3};

  #total-text {
    width: 100%;
    max-width: 520px;
    display: flex;
    justify-content: space-between;
    font-weight: 700;
    font-size: 14px;
    border-top: 2px dashed ${props=>props.theme.colors.Grey_7};
    margin-top: 8px;
    padding-top: 16px;
  }

  #subtotal,
  #frete {
    width: 100%;
    max-width: 520px;
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
  }

  @media (min-width: 320px) and (max-width: 914px) {
    width: 100%;
    margin: 0 12px 0 12px;
  }
`;

export const DeliveryOptionsWrapper = styled.div`
  width: 100%;
  border-top: 1px solid ${props=>props.theme.colors.Grey_7};
  box-sizing: content-box;

  .buttons-wrapper {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
`;

export const DeliveryAskForMore =  styled.div`
  width: 100%;
  margin-bottom: 28px;
  height: 33px;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  margin-top: 24px;
  color: ${props=>props.theme.text.Red_1};

    .ask-for-more-text-wrapper {
      cursor: pointer;
      max-width: 100%;
      height: 33px;
      display: flex;
      align-items: center;
      justify-content: center;

      p{
        text-decoration-line: underline;
      }
  }

  `;

export const DeliveryOptionsHeader = styled.div`
  width: 100%;
  margin-bottom: 28px;
  border-bottom: 1px solid ${props=>props.theme.colors.Grey_7};
  height: 50px;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: ${props=>props.theme.text.Red_1};

  .end-order-text-wrapper {
    width: 172px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px ${props=>props.theme.colors.Red};
    margin-left: 32px;
  }

  /* .end-order-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${props=>props.theme.text.Red_1};

  } */
`;

export const DeliveryOptionsButton = styled.div`
  width: 163px;
  height: 44px;
  border: ${(props) =>
    props.activeButton || props.activeButton2
      ? `1px solid ${props.theme.colors.Red}`
      : `1px solid ${props.theme.colors.Grey_3}`};
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .icon-button {
    display: flex;
    width: 20px;
    height: 20px;
    align-items: center;
    justify-content: center;
  }

  .text-delivery-wrapper {
    margin-left: 12px;
  }

  .text-delivery, .text-withdraw{
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${(props) => (props.activeButton ? props.theme.text.Red_1 : props.theme.text.Grey_1)};
  }

  /* .text-withdraw {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${(props) => (props.activeButton2 ? props.theme.text.Red_1 : props.theme.text.Grey_1)};
  } */
`;

export const BoxMessage = styled.div`
  display: flex;
  position: fixed;
  bottom: 60px;
  justify-content: center;
  max-width: 520px;
  align-items: center;
  padding: 1em;


`;

export const BoxFormaPagamento = styled.div`
  display: flex;
  max-width: 520px;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 12px;
  color: ${props=>props.theme.text.Black_1};
  border-top: solid 1px ${props=>props.theme.colors.Grey_2};
  margin-top: 2em;
  margin-bottom: 0.5em;
  height: 69px;
  margin-bottom: 72px;

  .texto-pagamento-wrapper {
    width: 100%;
    margin-top: 16px;
  }

  .texto-obrigatorio {
    font-size: 12px;
    font-weight: 400;
    color: ${props=>props.theme.text.Primary};

  }


    #textopagamento, #linkpagamento, #texto-pagamento-wrapper{
        display: flex;
        align-items: center;
        justify-content:space-between;
        cursor: pointer;
    }

    #informar{
        cursor: pointer;
        color:${props=>props.theme.text.Red_1};
        margin-left: 8px;
        margin-top: 15px;
        text-decoration: underline;
    }
`;

export const BoxEndereco = styled.div`
  display: flex;
  max-width: 520px;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  font-size: 12px;
  color: ${props=>props.theme.text.Black_1};
  border-top: solid 1px ${props=>props.theme.colors.Grey_2};
  margin-top: 2em;
  margin-bottom: 0.5em;
  height: 69px;

  #address{
    display: flex;
    width: 100%;
    cursor: pointer;
  }

  .em-construçao {
    background-color: ${props=>props.theme.background.Grey_2};
    width: 100%;
    align-items: center;
    height: 26px;
    display: flex;
    justify-content: center;
    color: ${props=>props.theme.text.White_1};
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    margin-top: 12px;
  }

  .texto-observação {
    font-size: 12px;
    font-weight: 400;
    color: ${props=>props.theme.text.Primary};
  }

  .texto-endereço-wrapper {
    width: 100%;
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    flex-direction: column;
  }

  #container {
    display: flex;
    width: 100%;
    height: 60px;
    justify-content: space-between;
  }

  #texto-observação {
    font-size: 12px;
  }

  #textoendereco,
  #linkendereco,
  #texto-observação-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    div {
      cursor: pointer;
    }
  }

  #linkendereco {
    justify-content: flex-end;
  }

  p {
    margin: 0;
    padding: 0;
  }

  a {
    text-decoration: none;
  }

  @media (min-width: 320px) and (max-width: 914px) {
    width: 100%;
    #address {
      padding: 0 12px 25px 12px;
    }
  }

    #informar{
        cursor: pointer;
        color:${props=>props.theme.text.Red_1};
        margin-left: 8px;
        margin-top: 15px;
        text-decoration: underline;
    }
`;

export const BoxFoto = styled.img`
  display: flex;
  width: 52px;
  justify-content: center;
  margin: 0;
  padding: 0;
  border-radius: 5px;
  background-size: cover;
  background-repeat: no-repeat;
`;

export const BoxIcone = styled.img`
  width: 14px;
  height: 14px;
  cursor: pointer;
`;

// export const BoxIconeEditar = styled(BoxIconeExcluir)`
// `;

export const BoxAcoes = styled.div`
  display: flex;
  justify-content: space-between;
  width: 15%;

  @media (min-width: 320px) and (max-width: 520px) {
    width: 25%;
  }
`;

export const BoxQtdProdutosSacola = styled.div`
  position: relative;
  top: -8px;
  left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 0px;
  height: 0px;
  padding: 8px;
  font-weight: bold;
  background: ${props=>props.theme.background.Black_1};
  border: 1px solid ${props=>props.theme.White};
  border-radius: 50%;
  color: ${props=>props.theme.text.White_1};
  font-size: 11px;
  font-weight: 400;
`;

export const BoxBotaototal = styled.div`
    display: flex;
    flex-wrap: nowrap; 
    width: 100%;
    max-width: 520px;
    height: 73px;
    padding: 0.5em;

    button{
        display: flex;
        width: 100%;
        border-radius: 0.25rem;
        outline: none;
        border: none;
        box-shadow: 0 0 0 0;
        /* background:#C51F2A; */
        background: ${props => props.naoEntregamosAi ? '#eeecec' : '#C51F2A'};
        justify-content: center;
        align-items: center;
        /* color: #FFFFFF; */
        color: ${props => props.naoEntregamosAi ? 'black' : '#FFFFFF'};
        
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;

        .spinner-border{
            margin-left: 10px;
            background-color: ${props=>props.theme.background.Red_1};
            color: ${props=>props.theme.colors.White} !important;
        }
    }
`;

export const BoxQtdProduto = styled.div`
  display: flex;
  position: fixed;
  align-items: center;
  bottom: 0;
  width:100%;
  max-width: 520px;
  justify-content: space-between;
  height: 73px;
  background: ${props=>props.theme.background.White_1};
  right: 0;
  padding: 1em;
  border-top: solid 1px ${props=>props.theme.colors.Grey_2};

  @media (min-width: 320px) and (max-width: 520px) {
    width: 100%;
  }
`;
