import styled from "styled-components";


export const BoxInicial = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  font-style: normal;
  font-weight: 500;
  padding: 2em;
  margin-bottom: 50px;
  background-color: ${props=>props.theme.background.Primary};
  
  .itens-categoria-wrapper {
    width: 100%;
    background-color: green;
    position: sticky;
    top: 0px;
    z-index: 100;
    background-color: ${props=>props.theme.background.Primary};
    display: flex;
    align-items: center;
    border-bottom: solid 1px ${props=>props.theme.colors.Grey_2};
  }

  .spinner {
    justify-content: center;
    min-height: calc(100vh - 55px);
    align-items: center;
  }

  .row {
    margin: 0;
    padding: 0;
  }

  /* #container {
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 0.5em;
    background-color: aqua;
  } */

  /* #status {
    width: 87px;
    justify-content: center;
    border-radius: 4px;
  } */

  /* #saibamais {
    margin-left: 1em;
    justify-content: center;
    text-align: center;
    cursor: pointer;
  } */

  .linkinterno {
    display: flex;
    justify-items: center;
    align-items: center;
    text-decoration: none;
  }

  .categoriaitens {
    display: flex;
    flex-direction: column;
    margin-left: 15px;
    padding: 1em 0 1em 0;

    h2 {
      padding: 0;
      margin: 0;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
    }
  }

  #categoria {
    flex-wrap: nowrap;
    overflow-x: scroll;
    width: 100%;
    margin: 16px 0px;
    position: sticky;
    top: 1px;
    background-color: ${props=>props.theme.background.Primary};
    scrollbar-width: none;
    ::-webkit-scrollbar {
      display: none;
      /* background: blue; */
      height: 0px;
    }
    
    .button, .focusbutton {
      margin-right: 12px;
      background: ${props=>props.theme.background.Grey_1};
      color: ${props=>props.theme.colors.Grey_4};
      white-space: nowrap;
      height: 36px;
      outline: none;
      box-shadow: 0 0 0 0;
      border: none;
      border-radius: 20px;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      padding: 6px 15px;
    }
    .focusbutton {
      /* margin-right: 12px; */
      background: ${props=>props.theme.background.Red_1};
      color: ${props=>props.theme.colors.White};
      /* white-space: nowrap;
      height: 36px;
      outline: none;
      box-shadow: 0 0 0 0;
      border: none;
      border-radius: 20px;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      padding: 6px 15px; */
    }

  }

  /* #body {
    color: red;
    font-weight: 600;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
  } */

  /* #card {
    width: 100%;
    flex-wrap: nowrap;
    background-color: blue;
  } */

  /* #pesquisar {
    margin: 0;
    padding: 0;
    margin-top: 1em;
    background: aqua;
    box-shadow: 0 0 0 0;
    border-radius: 4px;

    input::placeholder {
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
    }

    input {
      outline: none;
      border: none;
      border-radius: 10px;
    }

    input:focus {
      outline: none;
      box-shadow: 0 0 0 0;
    }

    input:active {
      outline: none;
      box-shadow: 0 0 0 0;
    }

    input:hover {
      outline: none;
      box-shadow: 0 0 0 0;
    }
  } */
`;

export const BoxCardPromotion = styled.div`

  display: flex;
  width: 230px;
  height: 298px;
  flex: none;
  flex-direction: column;
  border: 1px solid rgb(242, 242, 242);
  border-radius: 9.22018px;
  margin: 0.5em 0.5em 0.5em 0.5em;
  overflow: hidden;
  align-items: flex-start;

  img{
    width: 100%;
    height: 100%;
  }

  background-color: ${props=>props.theme.background.White_2};


  .link {
    display: flex;
    flex-direction: column;
    width: 230px ;
    height: 298px;
    text-decoration: none;
    /* padding: 1em; */
    gap: 11.83px;
    padding: 8px 8px 14px;
    color: ${props=>props.theme.text.Black_1};
  }

  .row {
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 140px;  
  }

  @media (min-width: 280px) and (max-width: 720px) {
    width: 180px;

    .link{
      width: 180px; 
    }

  }
`;

export const BoxCard = styled.div`
  display: flex;
  width: 100%;
  height: 182px;
  border: 1px solid rgb(242, 242, 242);
  border-radius: 9.22018px;
  margin: 0.5em 0.5em 0.5em 0.5em;
  overflow-x: hidden;
  background-color: ${props=>props.theme.background.White_2};
  
  .link {
    display: flex;
    height: 100%;
    width: 100%;
    text-decoration: none;
    padding: 1em;
    color: ${props=>props.theme.text.Black_1};
  }

  .row {
    margin: 0;
    padding: 0;
    justify-content: center;
    align-items: center;
    height: 100%;
    max-width: 20%;
    margin-right: 18px;
    /* margin: 2em; */
  }

  @media (min-width: 280px) and (max-width: 500px) {
    width: 100%;

    .row{
       min-width: 100px;
    }
  }
`;

export const BoxFoto = styled.img`
  display: flex;
  height: 100%;
  width: 100%;
  object-fit: cover;
  justify-content: center;
  margin: 0;
  padding: 0;
`;

export const BoxIconNavBar = styled.img`
  width: 32px;
  height: 32px;
  cursor: pointer;
`;

// export const BoxIconeLogin = styled.img`
//   width: 32px;
//   height: 32px;
//   cursor: pointer;
// `;

// export const BoxIconAbout = styled.img`
//   height: 32px;
//   width: 32px;
//   cursor: pointer;
// `;

// export const BoxIconPesquisar = styled.img`
//   height: 32px;
//   width: 32px;
//   cursor: pointer;
// `;

// export const BoxIconPedido = styled.img`
//   height: 32px;
//   width: 32px;
//   cursor: pointer;
// `;

export const BoxInfoPedidos = styled.div`
  display: flex;
  width: 100%;
  height: 75px;
  align-items: center;
  padding: 10px 20px;
  background: ${props=>props.theme.background.White_2};
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  z-index: 100
`;

export const BoxSecaoProduto = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 60px;

  .containergrupoprodutos {
    justify-content: center;
    display: flex;
    width: 50%;
  }

  @media (min-width: 280px) and (max-width: 500px) {
    .containergrupoprodutos {
      width: 100%;
    }
  }
`;



export const BoxCardinternoPromotion = styled.div`

  display: flex;
  height: 100%;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;
  font-weight: 600;
  flex-wrap: wrap;


  .prices{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    gap: 12px;
    width: 100%;
    height: 24px;

    .valuepromotion {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #C51F2A;
    }

    .valuenotpromotion{
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-decoration-line: line-through;
      color: #999999;
    }
  }

  .nomeproduto,
  .infoitem {
    font-size: 14px;
    line-height: 16px;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .infoitem {
    color: rgb(113, 113, 113);
    font-weight: 300;
  }

  .nomeproduto {
    font-size: 15px;
    line-height: 24px;
  }

`;
export const BoxCardinternoItemsPromotion = styled.div`

  display: flex;
  height: 100%;
  width: 80%;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  
  .valuespromotion{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 0px;
    gap: 12px;
    flex-wrap: wrap;
  }

  .iconpromotion{
    display: flex;
    justify-content: space-between;
    flex-direction: row;  
  }

  .valuepromotion {
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #C51F2A;
  }

  .valuenotpromotion{
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-decoration-line: line-through;
    color: #999999;
  }

  .nomeproduto,
  .infoitem {
    font-size: 14px;
    line-height: 16px;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .infoitem {
    color: rgb(113, 113, 113);
    font-weight: 300;
  }

`;

export const BoxCardinterno = styled.div`
  display: flex;
  height: 100%;
  width: 80%;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;


  .nomeproduto {
    font-size: 14px;
    line-height: 24px;
  }

  .valoritem {
    padding: 0;
    margin: 0;
    font-size: 14px;
  }

  .nomeproduto,
  .infoitem {
    font-size: 14px;
    line-height: 16px;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .infoitem {
    color: rgb(113, 113, 113);
    font-weight: 300;
  }

`;

export const BoxContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
`;

export const Boxcategory = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 15px;
  padding: 1em 0 1em 0;
  

  h2 {
    padding: 0;
    margin: 0;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
  }
  
`;

export  const  Boxwrapperpromotion = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 60px;
`;

export const BoxContainerpromotion = styled.div`
    display: flex;
    width: 100%;
    overflow-x: scroll;
    position: relative;
    /* gap: 15px; */
    scroll-behavior: smooth;
    scrollbar-width: none;
 
    ::-webkit-scrollbar {
      display: none;
   
    }

`;

export const BoxButtonLeft = styled.img`
  width: 2.5em; 
  height: 2.5em;
  z-index: 10;
  display:flex;
  position: absolute;
  top:40%;
  left: -1em;
  cursor: pointer;
  border-radius: 50%;
  justify-content: center;
  background-color: #E6E3E3;
  transform: rotate(0);

  @media (max-width:750px) {
    width: 0;
  }
`;

export const BoxButtonRight = styled.img`
  width: 2.5em; 
  height: 2.5em;
  z-index: 10;
  display:flex;
  top:40%;
  right: -1em;
  position: absolute;
  cursor: pointer;
  border-radius: 50%;
  background-color: #E6E3E3;
  transform: rotate(180deg);
  
  @media (max-width:750px) {
    width: 0;
  }

`;

export const IconPromotion = styled.img`
  width: 24px;
  height: 24px; 
  
`;