import React from 'react';
import {
    BoxModal,
    BoxContainer,
    BoxHeader,
    BoxIconeVoltar, 
    BoxFormaPagamento,
    BoxQtdProduto,
    BoxBotaototal,
    BoxEntrega,
    BoxPagueEntrega,
    BoxCartao,
    BoxDinheiro,
    BoxContainerTroco,
    BoxComTroco,
    BoxSemTroco,
    BoxIconCoin,
    BoxNotIconCoin,
    BoxIconCard,
    BoxIconMoney,
    BoxTroco,
    WrapperBoxTotais,
    BoxTotais
    
} from './style'

import IconeVoltar from '../../assets/Commons/ArrowIcon.svg';
import IconCoin from '../../assets/FormaPagamento/iconCoin.svg'
import IconNotCoin from '../../assets/FormaPagamento/iconNotCoin.svg'
import IconCardActive from '../../assets/FormaPagamento/iconCard.svg'
import iconCardNotActive from '../../assets/FormaPagamento/iconCardNotActive.svg'
import iconMoney from '../../assets/FormaPagamento/iconMoney.svg'
import iconMoneyNotActive from '../../assets/FormaPagamento/iconMoneyNotActive.svg'
import iconNoChange from '../../assets/FormaPagamento/iconNoChange.svg'
import iconCoinNotActive from '../../assets/FormaPagamento/iconCoinNotActive.svg'
import { entrar, getToken } from '../../service/auth';

class FormaPagamento extends React.Component{
    constructor(props){
        super(props);
        
        this.state = {
            dinheiro:false,
            cartao: true,
            comTroco:false,
            semTroco:true,
            troco:0.00

        }
    }

    componentDidMount () {
        this.carregandoValorTroco();
  
    }

    carregandoValorTroco = () => {
        let valorTroco = getToken('VALORTROCO'); 
        let verificarForma = getToken('FORMAPAGAMENTO'); 
        getToken('NOMEFORMAPAGAMENTO'); 

        if (verificarForma === '1') {
            this.setState({dinheiro: true , troco: valorTroco, comTroco : parseFloat(valorTroco) > 0 ? true : false , cartao: false, semTroco:parseFloat(valorTroco) > 0 ? false : true})
        }
        else {
        
            this.setState({dinheiro: false , troco: 0, comTroco :false , cartao: true, semTroco:true})   
        }
    }
    
    fecharModal = (event) => {
        if (event.target.id === this.props.idd) {
            this.fecharModalFormaPagamento();
        }
    }


    proibirCaracteres = e => {
        let valor = e.target.value;

        valor = valor + '';
        valor = parseInt(valor.replace(/[\D]+/g, ''));
        valor = valor + '';
        valor = valor.replace(/([0-9]{2})$/g, ",$1");

        if (valor.length > 6) {
            valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, ".$1,$2");
        }

        if (valor < 1 && valor !== '0') {
            valor = 0 + valor 
        }
        
        e.target.value = valor;
        if(valor === 'NaN' )  e.target.value= '0,00';
    }

    fecharModalFormaPagamento = () => {
        this.props.fecharModalPagamento();
        this.props.carregarProdutosSacola();
    }

    onChangeTroco = (e) => {
        this.proibirCaracteres(e);
        this.setState({troco: e.target.value})
    }

    GravarTroco = () => {
        const {comTroco, semTroco, troco, dinheiro} = this.state;


        if (comTroco && dinheiro) {
            let trocoFormatado;

            trocoFormatado = troco.replace('.','');
            trocoFormatado = trocoFormatado.replace(',', '.')

            if (parseFloat(troco) < 0 ){
                trocoFormatado = troco;
            }

     
      

            if (parseFloat(trocoFormatado) >  (this.props.entrega ? parseFloat(this.props.totalGeral + this.props.parametro).toFixed(2) : parseFloat(this.props.totalGeral).toFixed(2)) ){
                entrar('VALORTROCO', troco);
                entrar('FORMAPAGAMENTO', 1); 
                entrar('NOMEFORMAPAGAMENTO', 'Dinheiro');

                this.props.alterarEstadoNomeFormaPagamento();
                this.fecharModalFormaPagamento();  
            }
            else{
                alert('O valor do troco deve ser maior que o total da compra!')
            }
    

        }
        else if (semTroco && dinheiro){
            entrar('VALORTROCO', 0.00);
            entrar('FORMAPAGAMENTO', 1); 
            entrar('NOMEFORMAPAGAMENTO', 'Dinheiro');

            this.props.alterarEstadoNomeFormaPagamento();
            this.fecharModalFormaPagamento();
        }
        else{
            entrar('VALORTROCO', '0,00');   
            entrar('FORMAPAGAMENTO', 2); 
            entrar('NOMEFORMAPAGAMENTO', 'Cartão de Crédito/Débito');  

            this.props.alterarEstadoNomeFormaPagamento();
            this.fecharModalFormaPagamento(); 
        }
    }
      
    validandoCartaoTroco = () => {
        const  {cartao} = this.state;
        if (cartao === false) {
            this.setState({comTroco: true, semTroco:false})
        }
    }

    render(){
        const {dinheiro, cartao, troco, comTroco, semTroco} = this.state;
        return(
            <>
                <BoxModal id={this.props.idd} onClick={(e) => this.fecharModal(e) }>
                    <BoxContainer>
                        <BoxHeader>
      
                            <BoxIconeVoltar src={IconeVoltar} onClick={() => this.fecharModalFormaPagamento()}/>

                            <h1>Forma de Pagamento</h1>
                        </BoxHeader>

                        <BoxEntrega>
                            <div id='pagamento-entrega'>
                                <p>Pagamento na Entrega</p>
                            </div>
                        </BoxEntrega>

                        <BoxPagueEntrega>
                            <p>Pague no momento da entrega!</p>
                        </BoxPagueEntrega>

                        <BoxFormaPagamento>
                            <BoxCartao cartao={cartao} onClick={(e) =>  this.setState({cartao: true, dinheiro:false, comTroco:false, semTroco:true, troco:'0,00'})}>
                                <BoxIconCard src={cartao? IconCardActive: iconCardNotActive}/>
                                <div className='container-check'>
                                    <p className='forma-pagamento'>Cartão de Crédito/Débito</p>
                                    <input type='radio' name='pagamento'
                                        onChange={(e) =>  this.setState({cartao: true, dinheiro:false})}
                                        value={cartao}
                                        checked={cartao}
                                    />
                                </div>
                            </BoxCartao>

                            <div id='linha-meio'/>

                            <BoxDinheiro  dinheiro={dinheiro} onClick={(e) =>  this.setState({cartao: false, dinheiro:true})}>
                                <BoxIconMoney src={dinheiro? iconMoney: iconMoneyNotActive}/>
                                <div className='container-check'>
                                    <p className='forma-pagamento' >Dinheiro</p>
                                    <input type='radio' name='pagamento'
                                        onChange={(e) =>  this.setState({cartao: false, dinheiro:true})}
                                        value={dinheiro}
                                        checked={dinheiro}
                                    />
                                </div>
                            </BoxDinheiro>
            
                            <BoxContainerTroco>
                                <BoxComTroco onClick={()=> this.validandoCartaoTroco()} comTroco={comTroco}>
                                    <BoxIconCoin src={comTroco ? IconCoin : iconCoinNotActive }/>
                                    <p>Com Troco</p>
                                </BoxComTroco>

                                <BoxSemTroco  onClick={() => this.setState({comTroco: false, semTroco:true})} semTroco={semTroco}>
                                    <BoxNotIconCoin src={semTroco? iconNoChange : IconNotCoin }/>
                                    <p>Sem Troco</p>
                                </BoxSemTroco>
                            </BoxContainerTroco>


                            <BoxTroco comTroco={comTroco} >
                                <p>Troco para:</p>    
                                <input 
                                    onChange={(e) => this.onChangeTroco(e)} 
                                    value={troco}
                                    maxLength={10}
                                    inputMode="numeric"
                                    readOnly={cartao || semTroco ? true : false} 
                                />
                            </BoxTroco>

                            
                            <WrapperBoxTotais>
                                <BoxTotais>
                                    {
                                        <>
                                            <div id='subtotal'>
                                                <p>
                                                    Sub Total 
                                                </p>

                                                <p>
                                                    {parseFloat(this.props.totalGeral).toLocaleString('pt-br', {style:'currency', currency:'BRL'})}
                                                </p>
                                            </div>

                                            <div id='frete'>
                                                <p>
                                                    Taxa de entrega:
                                                </p>

                                                <p className="total-delivery">

                                                {
                                                    this.props.entrega ?
                                                        parseFloat(this.props.parametro).toLocaleString('pt-br', {style:'currency', currency:'BRL'})
                                                    :
                                                        'Grátis'
                                                } 

                                                </p>
                                            </div>

                                            <div id='total-text'>
                                                <p>
                                                    Total:
                                                </p>

                                                <p>
                                                {
                                                    this.props.entrega ? 
                                                        parseFloat(this.props.parametro +  this.props.totalGeral ).toLocaleString('pt-br', {style:'currency', currency:'BRL'})
                                                    :
                                                        parseFloat( this.props.totalGeral).toLocaleString('pt-br', {style:'currency', currency:'BRL'})
                                                }  
                                                </p>
                                            </div>

                                            </>
                                        }
                                </BoxTotais>
                            </WrapperBoxTotais>
                        </BoxFormaPagamento>

                        <BoxQtdProduto>
                            <BoxBotaototal>
                                <button onClick={() => this.GravarTroco()}>
                                    Salvar
                                </button>
                            </BoxBotaototal>
                        </BoxQtdProduto>
                    </BoxContainer>
                </BoxModal>              
            </>
        )
    }
}
export default FormaPagamento;