import baseApi from './api'
import baseURL from '../configs/baseURL'

// criando a Classe generica para chamar os endpoints

class ServiceCardapio {
    constructor(){
        this.api = baseApi(baseURL.SITE);
    }

    async getAllCategorias(modalCategorias) {
        const result = await this.api.post('grupoCardapio', modalCategorias);
        
        return result.data;
    }


}



export default ServiceCardapio;