import styled from "styled-components";

export const BoxContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  flex-direction: column;
  width: 520px;

  background: ${props=> props.theme.background.Primary};

  @media (min-width: 280px) and (max-width: 520px) {
    width: 100%;
  }
`;

export const BoxHeader = styled.header`
  width: 100%;
  text-align: center;
  padding: 1em;
  background-color: ${props=> props.theme.background.Primary};
  height: 52px;
  border-bottom: solid 1px ${props=>props.theme.colors.Grey_2};

  h1 {
    margin: 0;
    padding: 0;
    text-align: center;
    /* justify-content: center; */
    /* align-items: center; */
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${props=> props.theme.text.Black_1};
  }
`;

export const FooterLogin = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 42px;
  align-items: center;

  .footer-text {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: ${props=> props.theme.text.Primary};
    margin-right: 12px;
  }
`;

export const AboutTextWrapper = styled.div`
  width: 100%;
  border-radius: 10px;
  padding: 10px 8px;
  margin-top: 22px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: ${props=> props.theme.text.Primary};
`;

export const BoxImageHeader = styled.div`
  width: 100%;
  height: 198px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`;

export const BoxModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2020;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const BoxLogin = styled.div`
  padding: 0px 32px 32px;
  display: flex;
  overflow-x: auto;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 520px;
  flex-direction: column;
  padding-bottom: 1em;
  justify-content: start;

  .login-text-wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: initial;
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    margin-top: 26px;
    border-radius: 10px;
    color: ${props=> props.theme.text.Primary};
    padding: 6px;
    
    h3 {
      font-size: 14px;
      line-height: 17px;
      font-weight: 700;
      color: ${props=> props.theme.text.Black_1};
    }

    /* p {
       font-size: 14px;
      line-height: 17px;
      font-weight: 400; 
    } */
  }

  .info-wrapper {
    text-align: center;
    color: ${props=> props.theme.text.Primary};
  }

  @media (min-width: 320px) and (max-width: 520px) {
    width: 100%;
  }
`;

export const BoxIconeVoltar = styled.img`
  display: flex;
  position: absolute;
  top: 12px;
  border: none;
  cursor: pointer;
`;
