import * as S from './style'
import IconeVoltar from '../../assets/Commons/ArrowIcon.svg';
import IconAdvance from '../../assets/iconAdvanceSectionUser.svg';
import IconProfile from '../../assets/ProfileDefaultIcon/iconProfile.svg';
import IconAdress from '../../assets/InfoLoggedUser/iconAdressSectionUser.svg';
import IconUser from '../../assets/InfoLoggedUser/iconUserSectionUser.svg';
import IconExit from '../../assets/InfoLoggedUser/iconExitSectionUser.svg';
import { getToken, logout } from '../../service/auth';
import ServiceProduto from '../../service/produtosApiNode'
import { createDb } from '../../db';

export const UserLoggedOptionsScreen=(props)=>{
const { closeUserLoggedModal,
        location,
        alterandoQtdSacola,
        alterandoTotalSacola,
        mudandoParaRota,
        mudandoParaRota2,
        alteraNaoEntregamosAi,
        banco_cardapio
      } = props



  const fecharModal = (event) => {
        if ( event.target.id === "UserLogged") {  
            closeUserLoggedModal()
            location.history.push('/')
        }
    }

  const  fecharModalAdd = () => {
    closeUserLoggedModal()
    location.history.push('/')
    }
  const  redirectingRoutes = (teste) => {
    location.history.push(teste)
    mudandoParaRota2()
    }

const Logout = async() =>{
    try {  
            const service = new ServiceProduto();
    
            const result = await service.deleteAllProduto({banco: banco_cardapio});
            if(result.status === 200){
                alterandoQtdSacola();
                alterandoTotalSacola();
                alteraNaoEntregamosAi(false, '');
                await createDb();
                clearLocalStorage();
                fecharModalAdd();
        }
        } catch (error) {
            alteraNaoEntregamosAi(false, '');
            await createDb();
            alterandoQtdSacola();
            alterandoTotalSacola();

            clearLocalStorage();
            fecharModalAdd();
        console.log(error)
    }
}

const clearLocalStorage =() =>{    
    logout("CPF_CNPJ")
    logout("FORMAPAGAMENTO")
    logout("CLIENTE")
    logout("VALORTROCO")
    logout("NOMEFORMAPAGAMENTO")
    logout("NUMERO_WEB")
    logout("IDUSUARIO")
    logout("TOKEN")
  }  
  
    return(
        <S.BoxModal id="UserLogged" onClick={(e)=>fecharModal(e)} >
            <S.BoxContainer>
                <S.BoxHeader>
                    <S.BoxIconeVoltar src={IconeVoltar} onClick={() => fecharModalAdd()}/>
                <h1>Usuario</h1>
                </S.BoxHeader>

                <div className='profile-container'>
                    <S.ProfileWrapper>
                        <S.ProfilePicture>
                            <img src={IconProfile} alt="imagem no topo do mascote do sistefood ou a imagem do usuario"/>
                        </S.ProfilePicture>
                        <div className='Profile-name-wrapper'>
                            <p className='profile-name'>{getToken('CLIENTE')}</p>
                        </div>
                    </S.ProfileWrapper>
                </div>

                <S.SectionsUserOptions onClick={(()=> (redirectingRoutes('/UsuarioLogado/MeusDados')), () => mudandoParaRota()) }>
                    <div className='icon-text-wrapper'>
                        <div className="icon-Sections-User">
                            <img src={IconUser} alt="imagem de um icone de uma pessoa simbolizando dados do usuario"/>
                        </div>
                        <p>Usuário</p>
                    </div>
                        <S.BoxIconAdvance src={IconAdvance}/>
                </S.SectionsUserOptions>

                <S.SectionsUserOptions onClick={()=> redirectingRoutes('/UsuarioLogado/ListarEnderecos')}>
                    <div className='icon-text-wrapper'>
                        <div className="icon-Sections-User">
                            <img src={IconAdress} alt="imagem de um icone de um capacete simbolizando a entrega"/>
                        </div>
                        <p>Endereços</p>
                    </div>
                        <S.BoxIconAdvance src={IconAdvance}/>
                        
                </S.SectionsUserOptions>

                <S.SectionsUserOptions onClick={()=> Logout()}>
                    <div className='icon-text-wrapper'>
                        <div className="icon-Sections-User">
                            <img src={IconExit} alt="imagem de um icone de sair"/>
                        </div>
                        <p>Sair</p>
                    </div>
                        <S.BoxIconAdvance src={IconAdvance}/>
                </S.SectionsUserOptions>
            </S.BoxContainer>
        </S.BoxModal>
    )
}