import React from "react";

import {
    BoxModal,
    BoxContainer,
    BoxHeader,
    BoxIconeVoltar,
    BoxContainerStatus,
    BoxWrapperPedido,
    BoxStatusPedido,
    BoxContainerItems,
    BoxItems,
    BoxItemsText,
    BoxItemsTextTotal,
    BoxQtd,
    BoxContainerDeliveryFee,
    BoxContainerTextDeliveryFee,
    BoxContainerTotalDeliveryFee,
    BoxContainerMostdetails,
    BoxContainerFooterTotal,
    BoxContainerFooterTotalValue,
    BoxContainerFooterTotalText,
    BoxWrapperMostDetails,
    BoxWrapperDeliveryAddress,
    BoxHeaderDeliveryAddress,
    BoxWrapperAddress,
    BoxWrapperFormOfPayment,
    BoxWrapperFormOfPaymentHeader,
    BoxWrapperFormOfPaymentText,
    TextWrapper

} from './style'

import IconeVoltar from '../../assets/Commons/ArrowIcon.svg';

class DetalhesPedido extends React.Component{
    constructor(props){
        super(props)
        
        this.state = {
            DetalhePedido:[],
            parametros:'',
            color: {
                1: ["Aguardando o Estabelecimento Aceitar","#2F2F2F"],
                2: ["Pedido Recebido pelo Estabelecimento","#092EBB"],
                3: ["Pedido em separação","#F19049"],
                4: ["Pedido saiu para a entrega","#F5E7A3"],
                5: ["Pedido Finalizado","#51D981"],
                6: ["Pedido Cancelado","#C51F2a"],
                7: ["Pedido Pronto para Retirada","#086B30"],
                8: ["Pedido Rejeitado","#490408"],
            }
        }
    }
    
    async componentDidMount(){
        await this.setState({DetalhePedido: this.props.arrayMeusPedidos, parametros: this.props.parametros[0].site_frete})
    }

    fecharModal = (event) => {
        if  (event.target.id === this.props.idd) this.props.fecharEstadoMostrarMeusPedidos()
    }

    render() {
        const {DetalhePedido,color} = this.state;
        return(
            <>
                <BoxModal id={this.props.idd} onClick={(event) => this.fecharModal(event)}>
                    <BoxContainer>
                        <BoxHeader>

                            <BoxIconeVoltar src={IconeVoltar} onClick={() => this.props.fecharEstadoMostrarMeusPedidos()}/>

                            <h1>
                                Detalhes do pedido
                            </h1>
            
                                </BoxHeader>
                        <div id='wrapper-container'>
                
                            <BoxContainerStatus>
                                <BoxWrapperPedido >
                                    <div id='status-pedido-text'>
                                        <p id='status-pedido'>
                                            Status do pedido
                                        </p>
                                        
                                        <p id='numero-pedido'>
                                            {
                                                DetalhePedido.map(item => 
                                                    `N° ${item.id}`
                                                )
                                            }
                                        </p>
        
                                    </div>
        
                                    {
                                        <BoxStatusPedido statusOrder={DetalhePedido.map(item=> Object.entries(color).map((element)=> {if(element[0] == item.istatus)return element[1][1]}) )}>
                                             { DetalhePedido.map(item=> Object.entries(color).map((element) =>{
                                                                            if(element[0] == item.istatus){
                                                                                  return (<TextWrapper statusColorText={element[1][1]} key={item.id}>{element[1][0]}</TextWrapper>)
                                                                                } 
                                                                            })
                                                                        )} 
                                        </BoxStatusPedido>


                                    }

                                    <BoxContainerItems>

                                        {
                                            DetalhePedido.length > 0 ? (

                                                DetalhePedido.map(item => 
                                                    
                                                    item.pedido_produto.map(item => 
                                                        <BoxItems key={item.id}>
                                                            <BoxItemsText>
                                                                <BoxQtd>
                                                                    {item.qtd}
                                                                </BoxQtd>
                                                                
                                                                <p>{item.nome_web}</p>
                                                            </BoxItemsText>

                                                            <BoxItemsTextTotal>
                                                                <p>
                                                                    {`${parseFloat(item.total).toLocaleString('pt-br', {style:'currency', currency:'BRL'})}`}
                                                                </p>
                                                            </BoxItemsTextTotal>
                                                        </BoxItems>   

                                                    )
                                                    
                                                )

                                            )
                                            :(
                                                null
                                            )

                                        }
     
                                        <BoxContainerDeliveryFee>
                                            <BoxContainerTextDeliveryFee>
                                                <p>Taxa de entrega*</p>
                                            </BoxContainerTextDeliveryFee>

                                            <BoxContainerTotalDeliveryFee>
                                                <p>
                                                    {
                                                        
                                                        DetalhePedido.map(item => 
                                                            parseFloat(item.tx_entrega).toLocaleString('pt-br', {style:'currency', currency:'BRL'})        
                                                        )
                
                                                        
                                                    }
                                                       
                                                </p>
                                            </BoxContainerTotalDeliveryFee>
                                        </BoxContainerDeliveryFee>
                                    </BoxContainerItems>          

                                    <BoxContainerFooterTotal>
                                        <BoxContainerFooterTotalValue>
                                            <p>Total</p>
                                        </BoxContainerFooterTotalValue>

                                        <BoxContainerFooterTotalText>
                                        <p>
                                            {
                                                
                                                DetalhePedido.map(item => 
                                                    `${parseFloat(item.total).toLocaleString('pt-br', {style:'currency', currency:'BRL'})}`
                                                                 
                                                )
                                            }
                                            
                                        </p>  
                                    
                                 
                                        </BoxContainerFooterTotalText>
                                    </BoxContainerFooterTotal>            

                                </BoxWrapperPedido>

                            </BoxContainerStatus>

                            <BoxContainerMostdetails>
                                <BoxWrapperMostDetails>
                                    <h1>Mais Informações</h1>

                                    <div id='container-wrapper-info-delivery'>
                                        <div id='wrapper-numeric-text'>
                                            <div id='number-request'>
                                                <p>Número do pedido:</p>
                                            </div>
                                        
                                            <div id='number-text'>
                                                <p>
                                                    {
                                                        DetalhePedido.map(item => 

                                                            item.id
                                                        )
                                                    }
                                                </p>
                                            </div>
                                            
                                        </div>

                                        <div id='wrapper-date-text'>
                                            <div id='date-request'>
                                                <p>Data do pedido:</p>
                                            </div>
                                        
                                            <div id='date-text'>
                                                <p>
                                                    {
                                                        DetalhePedido.map(item => 

                                                            item.data
                                                        )
                                                    }
                                                </p>
                                            </div>
                                            
                                        </div>

                                        <div id='wrapper-hour-text'>
                                            <div id='hour-request'>
                                                <p>Horário do pedido:</p>
                                            </div>
                                        
                                            <div id='hour-text'>
                                                <p>
                                                    {
                                                        DetalhePedido.map(item => 

                                                            item.hora
                                                        )
                                                    }
                                                </p>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <BoxWrapperDeliveryAddress>
                                        <BoxHeaderDeliveryAddress>
                                            <h2>Endereço de Entrega</h2>
                                        </BoxHeaderDeliveryAddress>
                                        <BoxWrapperAddress>
                                          
                                                {
                                                    DetalhePedido.map(item => 
                                                        <p key={0}>
                                                            {`${item.logradouro}, ${item.numero_endereco} - ${item.bairro}, ${item.cidade}`}
                                                        </p>
                                                    )
                                                }    
                                        </BoxWrapperAddress>
                                    </BoxWrapperDeliveryAddress>

                                    <BoxWrapperFormOfPayment>
                                        <BoxWrapperFormOfPaymentHeader>
                                            <p>Forma de pagamento</p>
                                        </BoxWrapperFormOfPaymentHeader>

                                     
                                        {
                                            DetalhePedido.length > 0 ? (

                                                DetalhePedido.map(item => 
                                                    <BoxWrapperFormOfPaymentText key={item.id}>
                                                        <p>{item.formapag === '1' ? 'Dinheiro' : 'Cartão de Crédito/Débito'}</p>
                                                        <p>troco para: {`${parseFloat(item.troco_para).toLocaleString('pt-br', {style:'currency', currency:'BRL'})}`}</p>
                                                    </BoxWrapperFormOfPaymentText>
                                                    
                                                )
                                            )
                                            :(
                                                null
                                            )
                                        }
                            
                                    </BoxWrapperFormOfPayment>
                                </BoxWrapperMostDetails>
                            </BoxContainerMostdetails>
                        </div>
                    </BoxContainer>   
                </BoxModal>
            </>
        ) 
    }
}
export default DetalhesPedido;