import * as S from "./styles";
import { StateStablishment } from "../StateStablishment";
import { LogoClient } from "../LogoClient";

import IconTimer from '../../assets/time.svg'

export const Header = ({ parametro, MostrarModalSobre, banner }) => {
  return (
    <S.Container>
      <LogoClient parametro={parametro} banner={banner} />
      <div className="wrapper-items" onClick={() => MostrarModalSobre()}>
        <div className="wrapper-1">

          <p className="stablishment-name">
            {parametro.map((item) => item.site_nome_empresa)}
          </p>

          </div>
          <div className="wrapper-2">
          <StateStablishment status={parametro} /> 
          </div>

          <div className="wrapper-2">
          <p className="welcome-quote">
            {parametro.map((item) => item.site_msg_boas_vindas)}
          </p>
          </div>

          <S.MoreInformation>
            <p>
              Mais Informações
            </p>
          </S.MoreInformation>


          {
            parametro[0].tempo_medio_entrega > 0 ? (
              <S.ContainerPreparationTimeAvarage>

                <div className="text-preparation">
                  <div id='preparation-time-avarage'>
                    <p>Tempo médio de preparo</p>
                  </div>
    
                  <div id='time'>
                    <img src={IconTimer}/>
                    <p>{parametro.map(item => `${item.tempo_medio_entrega} min` )}</p>
                  </div>
                </div>

              </S.ContainerPreparationTimeAvarage>
            )
            :(
              null
            )
            
          }
      

      </div>
     
    </S.Container>
  );
};
