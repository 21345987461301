import React from "react";
import {
    BoxContainer,
    BoxModal,
    BoxHeader,
    BoxIconeVoltar,
    BoxTitle,
    BoxInfoPedidos,
    BoxContainerButton,
    DeliveryOptionsButton,
    BoxContainerStatus,
    BoxWrapperPedido,
    BoxStatusPedido,
    BoxWrapperTotal,
    BoxWrapperTimerHour,
    BoxWrapperTotalPedido,
    BoxWrapperInfoDelivery,
    BoxMostDetails,
    BoxContainerHelp,
    BoxWrapperHelp,
    BoxContainerWrapperHelp,
    BoxIconeNext,
    BoxContainerHistory,
    BoxHistoryInfo,
    BoxDeliveryStatusContainer,
    BoxDeliveryStatusWrapper,
    BoxContainerMostDateils,
    TextWrapper

} from './style'

import { Spinner} from 'react-bootstrap'

import IconeVoltar from '../../assets/Commons/ArrowIcon.svg';
import IconAdvance from '../../assets/iconAdvanceSectionUser.svg';
import { getToken } from "../../service/auth";
import ProdutoService from '../../service/produto'
import DetalhesPedido from "../OrderDetails";
import SupportScreen from "../Support";

class MeusPedido extends React.Component{
    constructor(props){
        super(props)

        this.state={
            emAndamento: true,
            todosPedidos:false,
            retornoAndamento:[],
            retornoTodosPedidos:[],
            array: [],
            color: {
                1: ["Aguardando o Estabelecimento Aceitar","#2F2F2F"],
                2: ["Pedido Recebido pelo Estabelecimento","#092EBB"],
                3: ["Pedido em separação","#F19049"],
                4: ["Pedido saiu para a entrega","#F5E7A3"],
                5: ["Pedido Finalizado","#51D981"],
                6: ["Pedido Cancelado","#C51F2a"],
                7: ["Pedido Pronto para Retirada","#086B30"],
                8: ["Pedido Rejeitado","#490408"],
            },
            isLoadingAndamento:true,
            isLoadingTodosPedidos:true,

          
        }

    }

    

    async componentDidMount(){
        await this.solicitandoHistoricoPedido();
        await this.timerGetAllHistoricoPedido();
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    timerGetAllHistoricoPedido = async () => {
        this.timer = setInterval(() => this.solicitandoHistoricoPedido(), 30000)
    }


    solicitandoHistoricoPedido = async () => {
        try {
            const cnpj     = this.props.cnpj;
            const celular  = getToken('NUMERO_WEB');
            const finalizado = 'N';
            const service  = new ProdutoService();
            const result   = await service.historicoPedido({
                cnpj,
                celular,
                id:"0",
                finalizado,
                cardapio :  "S"
            });
            if (result.success === true) {
                this.setState({retornoAndamento: result.data, isLoadingAndamento: true});

                if (result.data.length === 0)  this.setState({isLoadingAndamento: false});
            }
        } catch (error) {
            
        }   
    }

    solicitandoHistoricoPedidoFinalidados = async () => {
        try {
            const cnpj     = this.props.cnpj;
            const celular  = getToken('NUMERO_WEB');
            const finalizado = 'S';
            const service  = new ProdutoService();
            const result   = await service.historicoPedido({
                cnpj,
                celular,
                id:"0",
                finalizado,
                cardapio :  "S"
            });
  
            if (result.success === true) {
        
                this.setState({retornoTodosPedidos: result.data, isLoadingTodosPedidos: true});

                if (result.data.length === 0)  this.setState({isLoadingTodosPedidos: false});
                
            }
        } catch (error) {

        }      
    }

    fecharModal = (event) => {
        if (event.target.id === this.props.idd){
            this.props.fecharModalPedidos();
        }
    }

    mostrarModalMeusPedidos = () => {
        const {array} = this.state;
        return(
            <DetalhesPedido
                fecharEstadoMostrarMeusPedidos = {() => this.fecharEstadoMostrarMeusPedidos()}
                location={this.props.location}
                idd={'MeusPedidos'}
                array={[array]}
                cnpj={this.props.cnpj}
                parametros={this.props.parametros}           
            />
        )
    }



    render() {
        const {emAndamento, todosPedidos, retornoAndamento, retornoTodosPedidos,color, isLoadingAndamento, isLoadingTodosPedidos} = this.state; 
        return(
            <BoxModal id={this.props.idd} onClick={(e) => this.fecharModal(e)} >
                <BoxContainer >
                    <BoxHeader>
                        <BoxIconeVoltar src={IconeVoltar} onClick={() => this.props.fecharModalPedidos()}/>
                        <h1>
                            Pedidos
                        </h1>
                    </BoxHeader>

                    <div id='wrapper-container'>
                        <BoxInfoPedidos>
                            <BoxTitle>
                                <div id='meus-pedidos'>
                                    <h1>Meus Pedidos</h1>
                                </div>

                                <div id='info'>
                                    <p>Confira todos os detalhes e status dos seus pedidos!</p>
                                </div>
                            </BoxTitle>
    
                        </BoxInfoPedidos>  

                        <BoxContainerButton >
                            <DeliveryOptionsButton emAndamento={emAndamento}  onClick={() => this.setState({emAndamento:true, todosPedidos:false})} >
                                <div className="text-delivery-wrapper" >
                                    <p className="text-delivery">Em andamento</p>
                                </div>
                            </DeliveryOptionsButton>
                            
                            <div id='separar'/>
                            <DeliveryOptionsButton  todosPedidos={todosPedidos} onClick={() => (this.setState({todosPedidos:true,emAndamento:false}),         this.solicitandoHistoricoPedidoFinalidados())}>
                                <div className="text-delivery-wrapper" >
                                    <p className="text-delivery">Todos os pedidos</p>
                                </div>
                            </DeliveryOptionsButton>
                        </BoxContainerButton>

                        {
                            
                            emAndamento ?  (
                                isLoadingAndamento === true && retornoAndamento.length > 0? (
                                    retornoAndamento.map((item) => {
                                        return(
                                            <BoxContainerStatus key={item.id}>
                                                <BoxWrapperPedido >
                                                    <div id='status-pedido-text'>
                                                        <p id='status-pedido'>
                                                            Status do pedido
                                                        </p>
                        
                                                        <p id='numero-pedido'>
                                                            N° {item.id}
                                                        </p>
                        
                                                    </div>
                                           
                                                    <BoxStatusPedido statusOrder={Object.entries(color).map((element)=>{if(element[0] == item.istatus) return element[1][1] } ) } >
                                                         {Object.entries(color).map((element) =>{
                                                                if(element[0] == item.istatus){
                                                                        return (<TextWrapper statusColorText={element[1][1]} key={item.id}>{element[1][0]}</TextWrapper>)
                                                                    } 
                                                                })
                                                            }
                                                        
                                                    </BoxStatusPedido>                            
                                                </BoxWrapperPedido>
                        
                                                <BoxWrapperTotal>
                                                    <BoxWrapperTimerHour>
                                                        <div className='date-hour'>
                                                            <p className="text-date">Data/Horário</p>
                        
                                                            <p className="text-date-hour">{`${item.data} - ${item.hora}`}</p>
                                                        </div>
                                                    </BoxWrapperTimerHour>
                        
                                                    <BoxWrapperTotalPedido>
                                                        <div className='total-request'>
                                                            <p className="text-total">Total</p>
                        
                                                            <p className="text-total-request">
                                                                {`${parseFloat(item.total).toLocaleString('pt-br', {style:'currency', currency:'BRL'})}`}
                                                            </p>
                                                        </div>
                                                    </BoxWrapperTotalPedido>
                                                </BoxWrapperTotal>
                        
                                                <BoxWrapperInfoDelivery>
                                                    <div className="deliverin">
                                                        <p className="text-deliverin">Entrega em</p>
                                                    </div>
                        
                                                    <div className="text-deliverin-container">
                                                        <div className="text-deliverin-wrapper">
                                                            <p>{item.logradouro}</p>
                                                        </div>
                                                    </div>
                                                </BoxWrapperInfoDelivery>
                        
                                                <BoxMostDetails>
                                                    <p onClick={() => this.props.alterandoEstadoMostrarMeusPedidos(item)}>
                                                        Mais detalhes
                                                    </p>
                                                </BoxMostDetails>
                                            </BoxContainerStatus>
                                        )
                        
                                    })
                                ) 
                                :(
                                    isLoadingAndamento === false?(
                                        <div id='nodelivery' >
                                            <p>Sem pedido!</p>
                                        </div>

                                        
                                    )
                                    :(
                                        <div id='spinner'>
                                            <Spinner animation="border" variant="primary" />
                                        </div>

                                    )
                                )
                            ) 
                            :(
                                <>
                                    {
                                        isLoadingTodosPedidos === true && retornoTodosPedidos.length > 0 ? (

                                            <BoxContainerHistory>
                                                <div id='text-history'>
                                                    <p>Histórico</p>
                                                </div>

                                                {
                                                    retornoTodosPedidos.map(item => 
                                                        <BoxHistoryInfo key={item.id}>
                                                            <div className="wrapper-info-history">
                                                                <div className="info-header-history">
                                                                    <div className="text-info-datahour">
                                                                        <div className="datahour-wrapper">
                                                                            <p>{item.data} - {item.hora}</p>
            
                                                                            <div className="wrapper-total-text">
                                                                                <p>
                                                                                    {`${parseFloat(item.total).toLocaleString('pt-br', {style:'currency', currency:'BRL'})}`}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                        
                                                                <BoxDeliveryStatusContainer>
                                                                    <BoxDeliveryStatusWrapper>
                                                                        <div className="info-text">
                                                                            <p>{item.status}</p>
                                                                        </div>
                                                                        <div className="text-number-delivery">
                                                                            <p>N° {item.id}</p>
                                                                        </div>
                                                                    </BoxDeliveryStatusWrapper>
                                                                </BoxDeliveryStatusContainer>
                                                            </div>
                                                                <BoxContainerMostDateils>
                                                                    <p onClick={() => this.props.alterandoEstadoMostrarMeusPedidos(item)}>Mais detalhes</p>
                                                                </BoxContainerMostDateils>
                                                        </BoxHistoryInfo>           
                                                    )
                                                }
                                            </BoxContainerHistory>   
                                        )
                                        :(
                                            null
                                        )
                                    }                          
                                </>
                            )
                        }

                        {
                            emAndamento ?(
                                <BoxContainerHelp>
                                    <p id='text-help'>
                                        Como podemos ajudar?
                                    </p>
        
                                    <BoxWrapperHelp>
                                        <BoxContainerWrapperHelp>
                                            <div id='container-cancel-delivery' onClick={()=> this.props.mostrarCancelarPedido() }>
                                                <div id='wrapper-cancel-delivery'>
                                                    <div id='text-cancel-delivery'>
                                                        <p>Quero Cancelar meu pedido!</p>    
                                                    </div>
            
                                                    <div id='icon-cancel-delivery'>
                                                        <BoxIconeNext  src={IconAdvance}/>
                                                    </div>
                                                </div>
                                            </div>
            
                                            <div id='container-cancel-delivery' onClick={()=> this.props.mostrarSuporte()}>
                                                <div id='wrapper-cancel-delivery'>
                                                    <div id='text-cancel-delivery'>
                                                        <p>Problemas com o pedido!</p>    
                                                    </div>
            
                                                    <div id='icon-cancel-delivery'>
                                                        <BoxIconeNext  src={IconAdvance}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </BoxContainerWrapperHelp>
                                    </BoxWrapperHelp>
                            </BoxContainerHelp>
                            )
                            :(
                                null
                            )
                        }


                    </div>
                </BoxContainer>
            </BoxModal>
        )
    }
}
export default MeusPedido;