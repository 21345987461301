import * as S from "./style";
import iconItemBag from "../../assets/BagButton/iconItemBag.svg";
export const ItemsBagButton = (props) => {
  const { totalItemsBag } = props;
  const RedirectToBag = () => {
    if (totalItemsBag > 0) {
      props.carregarProdutosSacola();
    }
  };

  return (
    <S.Container 
        BackgroundColor={totalItemsBag > 0 ? props=>props.theme.colors.Red : '#8F8585' } 
        shadow={totalItemsBag>0?"rgba(47, 47, 47, 0.25)": props=>props.theme.colors.Grey_6} 
        onClick={() => RedirectToBag()}
    >
        <img src={iconItemBag} alt="icone Ver itens da sacola" />
    </S.Container>
  );
};
