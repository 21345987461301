
import React from 'react'

import 
{
    BoxSemLogin, 
    BoxHeader, 
    BoxContainer, 
    BoxIconeVoltar, 
    BoxModal,
    SignUpQuoteBox,
    BoxContainerButton,
    BoxWrapperButton
} from './style' 

import { Form, Row, Alert } from 'react-bootstrap'
import IconeVoltar from '../../assets/Commons/ArrowIcon.svg';
import UsuarioService from '../../service/cadastrarUsuario';
import { cpf } from 'cpf-cnpj-validator';
import { entrar, getToken } from '../../service/auth';

class UserLoggedInfoScreen  extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            nome: "",
            cpf_cnpj:"",
            email:"",
            telefone:"",
            senha:"",
            error:"",
            backSpace:"vazio"
        }
    }
    async componentDidMount() {
        await this.buscarUsuario()
    }

    buscarUsuario = async() => {
        try {
            let usuarioId = getToken('IDUSUARIO')
            const service = new UsuarioService();
            const result = await service.getOne({
                usuarioId,
                banco: this.props.banco_cardapio
            })

            if (result) {
                this.setState({
                    nome: result.nome,
                    cpf_cnpj:result.cpf_cnpj,
                    email:result.email,
                    banco: this.props.banco_cardapio
                })

                this.mascaraTelefone(result.telefone);
            }
            
        } catch (error) {

            if (error.response.status === 401) {
                this.props.fechandoEstadoCadastrarInfo();
                this.props.mostrarLogin();
                this.props.closeUserLoggedModal();
            }
        }
    }

    HandleCadastrar = async(event) => {
        event.preventDefault();
        this.Cadastra();
    }

    removendoMascaraTelefone = () => {
        const {telefone} = this.state;
        let retirarMascara = telefone;
        retirarMascara = retirarMascara.replace('(', '')
        retirarMascara = retirarMascara.replace(')', '')
        retirarMascara = retirarMascara.replace('-', '')
        retirarMascara = retirarMascara.replace(' ', '')
        return retirarMascara;
    }

    Cadastra = async() => {
        try {
            const {cpf_cnpj, nome, email, telefone} = this.state;
            if ( !nome ) {
                this.setState({error:"Os campos: email, nome e telefone são obrigatórios"})
            }
            else {
 
                let IDUSUARIO =  getToken('IDUSUARIO');

                let ValidarCPF      =  this.ValidarCPF(cpf_cnpj);
                if (ValidarCPF !== '' && !ValidarCPF )      return this.setState({error:'CPF Inválido'});

                const service = new UsuarioService();
                const result = await service.updateOne({
                    nome,
                    cpf_cnpj,
                    telefone: this.removendoMascaraTelefone(),
                    email,
                    banco: this.props.banco_cardapio
                }, IDUSUARIO)

                if (result.status === 200) {
                    entrar('CPF_CNPJ', result.data.cpf_cnpj);
                    entrar('CLIENTE', result.data.nome);
                    this.VoltarTelaAnterior();
                } 
            }
        } catch (error) {
            if (error.response.status === 401) {
                this.props.MudandoEstadoSemLogin();
                this.props.closeUserLoggedModal();
            }

            this.setState({error:error.response.data.message})
        }
    }

    VoltarTelaAnterior = () => {
        this.props.fechandoEstadoCadastrarInfo();
        this.props.location.history.goBack()
    }

    FecharModal = (event) => {
        if (event.target.id === this.props.idd) {
            this.VoltarTelaAnterior();
        }
    }

    Messagem = () => {
        const {error} = this.state;
        return(
            <Alert variant='warning'>
                {error}
            </Alert>
        )
    }

    ValidarEmail = (e) => {
        const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
        return validEmail.test(e)
    }

    ValidarTelefone = (e) => {
        const validTelefone = new RegExp('^\\(((1[1-9])|([2-9][0-9]))\\)((3[0-9]{3}-[0-9]{4})|(9[0-9]{3}-[0-9]{5}))$');
        return validTelefone.test(e)
    }

    onChangeEmail = (e) => {
        const result = this.ValidarEmail(e.target.value);
        if (result === false) {
            this.setState({error: 'email inválido', email:e.target.value});
        }
        else{
            this.setState({error: ''})
            this.setState({email: e.target.value});
        }
    }

    ValidarCPF = (valor) => {
        if (valor !== ''){
            let validar = valor;
            return cpf.isValid(validar)
        }
        return ''
    }

    onChangeCPF = (e) => {
        let result = '';

        if(parseInt(e.target.value)){
            if (e.target.value !== '') {
                result = this.ValidarCPF((e.target.value).trim())
            }
            if (result === false) {
                this.setState({cpf_cnpj: (e.target.value).trim(),  error: 'CPF inválido'})
            }
            else{   
                this.setState({error: ''})
                this.setState({cpf_cnpj: (e.target.value).trim()})
            }


        }
        else{
            this.setState({error: ''})
            this.setState({cpf_cnpj: ''});
        }
    }   

    mascaraTelefone = (e) => {
        let valor = e;
        if (valor) {
            valor = valor
            .replace(/\D/g, "")
            .replace(/(\d{2})(\d)/, "($1) $2")
            .replace(/(\d{4})(\d)/, "$1-$2")
            .replace(/(-\d{5})(\d+?)$/, "$1");
            valor = valor.replace(' ', '');
            let resultTelefone = this.ValidarTelefone(valor)
            if (resultTelefone) this.setState({telefone: valor, error:''});  
            if (resultTelefone === false) this.setState({telefone: valor, error:'Telefone inválido'});  
        }
    }

    onChangeTelefone = (e) => {
        let valor = e.target.value
        valor = parseInt(valor.replace(/[\D]+/g, ''));
        if( isNaN(valor) ) {
            e.target.value= ''
            this.setState({telefone:  e.target.value}) ;   
        }
        else {
            e.target.value= valor
            this.setState({telefone:  e.target.value}) ;
        }
        this.setState({telefone: valor})
        if (isNaN(valor)) this.setState({telefone: ''})
        if (valor.toString().length === 11)  this.mascaraTelefone(valor.toString())
    } 

    render(){
        const {error} = this.state;
        return(
            <>
                <BoxModal id={this.props.idd} onClick={(e) => this.FecharModal(e)}>
                    <BoxContainer>
                            <BoxHeader>
                                <BoxIconeVoltar src={IconeVoltar} onClick={() => this.VoltarTelaAnterior()}/>
                                <h1>Meus Dados</h1>
                            </BoxHeader>
                            <BoxSemLogin>
                                <SignUpQuoteBox>
                                    <div>
                                        <h3>Informação Pessoais</h3>
                                    </div>
                                </SignUpQuoteBox>
                                <Row>
                
                                    <Form onSubmit={this.HandleCadastrar}>
                                    <p className="input-text-nome">Nome</p>
                                    <input
                                        placeholder="Nome"
                                        type="text"
                                        maxLength={50}
                                        onChange={(e) => this.setState({nome: e.target.value})}
                                        value={this.state.nome}
                                    />

                                    <p className="input-text-nome">CPF</p>
                                    <input
                                        placeholder="CPF"
                                        type="text"
                                        inputmode="numeric"
                                        maxLength={11}
                                        onChange={(e) => this.onChangeCPF(e)}
                                        onKeyUp={(e)=> this.setState({backSpace: e.keyCode})}
                                        value={this.state.cpf_cnpj}
                                        />

                                    <div className='Dados-contatos'>
                                         <h4>Dados de Contato</h4>
                                    </div>

                                    <p className="input-text-nome">Telefone para Contato</p>
                                    <input
                                        readOnly={true}
                                        placeholder="Telefone"
                                        type="text"
                                        inputmode="numeric"
                                        maxLength={14}
                                        onChange={(e) => this.onChangeTelefone(e)}
                                        onBlur={(e)=> this.mascaraTelefone( e.target.value)}
                                        value={this.state.telefone}
                                    />    

                                    <p className="input-text-email">E-mail</p>
                                    <input
                                        placeholder="E-mail"
                                        maxLength={150}
                                        readOnly={true}
                                        onChange={(e) => this.onChangeEmail(e)}
                                        value={this.state.email}
                                    />

                                    {
                                        error !== '' ? (
                                            <Row id='error'>
                                                {<this.Messagem error={error} />}
                                            </Row>    
                                        )
                                        :(
                                            null
                                        )
                                    }
                                    <BoxContainerButton>
                                        <BoxWrapperButton>
                                            <button type='submit'>
                                                Salvar
                                            </button>
                                        </BoxWrapperButton>
                                    </BoxContainerButton>
                                    
                                    </Form>
                                </Row>
                            </BoxSemLogin>
                    </BoxContainer>
                </BoxModal>
            </>
        )
    }
}
export default UserLoggedInfoScreen 