import baseURL from "../configs/baseURL";
import baseApi from "./api";

class ServiceProduto {
    constructor(){
        this.api = baseApi(baseURL.API_PRODUTOS);
    }

    async getAllProdutos(modelProduto){
        const result = await this.api.get(`pedidos/${modelProduto.banco}`);
        
        return result;
    }

    async getProduto(modalProduto){
        const result = await this.api.get(`pedidos/${modalProduto.id}/${modalProduto.banco}`);
        
        return result;
    }

    async getProdutoAcompanhamento(modalProduto){
        const result = await this.api.get(`pedidosAcompanhamento/${modalProduto.id}/${modalProduto.banco}`);
        
        return result;
    }

    async Add(modelProduto){
        const result = await this.api.post('pedidos', modelProduto);
        
        return result;
    }

    async updateProduto(id, modelProduto){
        const result = await this.api.patch(`pedidos/${id}`, modelProduto);
        
        return result;
    }


    async deleteProduto(id, modalProduto){

        const result = await this.api.delete(`pedidos/${id}/${modalProduto.banco}`);
        
        return result;
    }

    
    async deleteAllProduto(modelProduto){
        const result = await this.api.delete(`pedidos/${modelProduto.banco}`);   
        return result;
    }
}


export default ServiceProduto;