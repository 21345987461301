import * as S from "./style"
import IconEstablishment from '../../assets/BubbleStateEstablishment.svg'
import IconEstablishmentClosed from '../../assets/closed.svg'

export const StateStablishment =({status})=>{
    return (
        <S.Container OpenOrClosed ={status.map(item=> item.status_cardapio)}>
            <div className="wrapper-state-name-bubble">
                <div className="state-name-wrapper">
                    <p className="state-name">{status.map(item => item.status_cardapio ==='S'? 'Aberto' : 'Fechado') }
                    </p>
                </div>
                {/* <div className="state-bubble"></div>; */}

                <S.BubbleImg src={status.map(item => item.status_cardapio ==='S'? IconEstablishment : IconEstablishmentClosed)} />
            </div>
   
        </S.Container>
    )
}