import styled from "styled-components";

export const BoxContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  flex-direction: column;
  width: 520px;
  background: ${props=>props.theme.background.Primary};

  @media (min-width: 280px) and (max-width: 520px) {
    max-width: 100%;
  }
`;

export const BoxModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2020;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const BoxIconeVoltar = styled.img`
  display: flex;
  position: absolute;
  top: 12px;
  border: none;
  cursor: pointer;
`;

export const BoxImageHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 18px;
`;

export const SignUpQuoteBox = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 32px;

  h1 {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 0px;
    font-weight: 400;
  }
`;

export const BoxEndereco = styled.div`
  padding: 24px;
  display: flex;
  overflow-x: auto;
  max-width: 520px;
  flex-direction: column;

  .row {
    justify-content: center;

    .forgot-password-text-wrapper {
      display: flex;
      justify-content: initial;
      margin-bottom: 12px;
    }

    .forget-password-text {
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
      color: ${props=>props.theme.text.Primary};
      margin-right: 14px;
    }
  }

  p {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
  }

  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px #fff inset;
  }

  input {
    width: 100%;
    height: 48px;
    border: 1px solid ${props=>props.theme.colors.Grey_3};
    border-radius: 10px;
    outline: none;
    padding: 15px 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 12px;
    color: ${props=>props.theme.text.black_1};
    background-color: ${props=>props.theme.background.White_1};
    margin-bottom: 16px;
    margin-top: 8px;
    :focus {
      border: 2px solid ${props=>props.theme.colors.Grey_5};
    }
  }

  .input-text-below {
    margin-bottom: 8px;
  }

  #numero {
    margin: 0;
    padding: 0;
    input {
      padding: 0.375rem 0.75rem;
    }
  }

  #complemento {
    margin: 0;
    padding-right: 0;
  }

  #referencia{
    margin: 0;
    padding-left: 0;
  }

  #uf {
    margin: 0;
    padding-right: 0;
  }

  @media (min-width: 320px) and (max-width: 374px) {
    width: 100%;
    .row {
      .forget-password-text {
        font-size: 10px;
        line-height: 12px;
      }
    }
  }

  @media (min-width: 375px) and (max-width: 520px) {
    width: 100%;
    
    input{
        font-size: 16px;
    } 
  }
`;

export const BoxHeader = styled.header`
  width: 100%;
  text-align: center;
  padding: 1em;
  background-color: ${props=>props.theme.background.Primary};
  height: 52px;
  border-bottom: solid 1px ${props=>props.theme.colors.Grey_2};

  p {
    margin: 0;
    padding: 0;
    text-align: center;
    /* justify-content: center; */
    /* align-items: center; */
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${props=>props.theme.text.Black_1};
  }
`;

export const BoxBotaototal = styled.div`
    display: flex;
    flex-wrap: nowrap; 
    width: 100%;
    height: 55px;
        
    button{
        width: 100%;
        border-radius: 10px;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        box-shadow: 0 0 0 0;
        background: ${props=>props.theme.colors.Grey_5};
        color: ${props=>props.theme.text.White_1};
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;

        .spinner-border{
            margin-left: 10px;
            background-color: transparent;
            color: #FFFFFF !important;
        }
    }
`;

export const BoxQtdProduto = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 18px;
  
    @media (min-width: 320px) and (max-width: 520px) {
      width: 100%;
    }
`;
