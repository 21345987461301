import React from "react";

import 
{
    BoxAddProduto, 
    BoxHeader, 
    BoxContainer, 
    BoxIconeVoltar, 
    BoxQtdProduto,
    BoxBotaototal,
    BoxModal,
    BoxSacolaProdutos,
    BoxQtdProdutosSacola,
    BoxFoto,
    BoxInfoProdutos,
    BoxAcoes,
    BoxTotais,
    BoxEndereco,
    WrapperBoxTotais,
    BoxFormaPagamento,
    DeliveryOptionsWrapper,
    DeliveryOptionsButton,
    DeliveryOptionsHeader,
    BoxIcone,
    BoxMessage,
    DeliveryAskForMore
} from './style' 

import { Spinner} from 'react-bootstrap'
import UsuarioProdutoApiNode from "../../service/produtosApiNode";
import IconeVoltar from '../../assets/Commons/ArrowIcon.svg';
import Exluir from '../../assets/Commons/LIXEIRA.svg';
import Editar from '../../assets/Commons/EDITAR.svg';
import { getToken } from "../../service/auth";
import { logout } from "../../service/auth";
import iconButtonDelivery from '../../assets/ProductsBag/iconButtonDelivery.svg'
import iconButtonDeliveryActive from '../../assets/ProductsBag/iconButtonDeliveryActive.svg'
import iconButtonWithdrawActive from '../../assets/ProductsBag/iconButtonWithdrawActive.svg'
import iconButtonWithdraw from '../../assets/ProductsBag/iconButtonWithdraw.svg'
import salvarPedido from '../../service/produto'

class Sacola extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            buttonDelivery:true,
            buttonWithdraw:false,
            tx_entrega:0.00,
            isLoadingPedido: false
        
        }
    }

    async componentDidMount() {
        this.setState({tx_entrega: this.props.tx_entrega});
        await this.verificandoUsuarioLogado();
        this.props.alterarEstadoNomeFormaPagamento();

    }  



    deleteAllProdutos = async () => {
        try {
            const service = new UsuarioProdutoApiNode();
            const result = await service.deleteAllProduto({banco: this.props.banco_cardapio});

            if (result.status === 200) await this.props.pegandoQtdSacolaBanco();
        } catch (error) {
            console.log(`deleteAllProdutos ${error}`);
        }
    } 

    verificandoUsuarioLogado = async() => {
        const usuarioLogado = getToken('IDUSUARIO');
        const token =  getToken('TOKEN');
        if (usuarioLogado !== null && usuarioLogado !== '' ) {
         
            await this.props.getAllEndereco();

            if(token !== null){
                if (this.props.revalidadorNaoEntregamosAi) this.props.alteraNaoEntregamosAi(true, 'Infelizmente não entregamos para sua localidade!')
            }
        }
        else{
            this.props.alterarEstadoEnderecos();
            this.props.mudandoEstadoEnderecoEscolhido([])
        }
    }

    deleteProduto = async (id) => {
        try {
            const service = new UsuarioProdutoApiNode();

            const result  = await service.deleteProduto(
                id,{banco: this.props.banco_cardapio}
                );
            
            if (result.status === 200) {
                await this.props.pegandoQtdSacolaBanco()
        
                if (this.props.produtosSacola.length === 0 ) {
                    this.fecharModalAdd()
                    this.props.alterandoTotalSacola();
                    this.props.alterandoQtdSacola()
                } 
            }
        } catch (error) {
            console.log(`deleteProduto ${error}`);
        }
    }

    deleteBancoOuLocal =  (id) => {

        const usuarioLogado = getToken('IDUSUARIO');

        if (usuarioLogado !== '' && usuarioLogado !== null){
            this.deleteProduto(id);
        } 
        else{
            this.removendoProdutosIndexDB(id);
        }
    }

    fecharModal = (event) => {

        if ( event.target.id === this.props.idd) {  
            this.props.FecharModal()
            this.props.location.history.push('/')   
        }
    }

    fecharModalAdd = () => {
        this.props.location.history.push('/')
        this.props.FecharModal();
    }

    mostrarModalEndereco = async() => {
        try {
            const usuarioLogado = getToken('IDUSUARIO');
        // refatorar colocando rotas adequadas
            if (usuarioLogado !== '' && usuarioLogado !== null){
                const enderecos = await this.props.getAllEndereco();
                if (enderecos === 0) {
                    this.props.mudandoEstadoEndereco();
                    this.props.location.history.push('/Endereco');
                }else{
                    this.props.alterandoEstadoEndereco();
                    this.props.mostrarModalEnd();
                }
            }
            else{
                this.props.RotaLogin();
            } 
            
        } catch (error) {
 
        }

        
    }

    mostrarAddProdutos = (nome, id) => {
        this.props.MudandoEstadoModalAdd()
        this.props.location.history.push(`/Sacola/AdicionarProduto/${id}/${nome}`)
    }

    removendoProdutosIndexDB = (id) =>{
        let db ;
        const request = indexedDB.open('cardapio', 2 );  

        request.onsuccess = () => {
            db = request.result;

            const transaction = db.transaction(["Produtos"], "readwrite")
            const objectStore = transaction.objectStore("Produtos")
            const result = objectStore.getKey(parseInt(id))
            // caso a transação der Ok eu faço algo
            result.onsuccess = () => {
                if (result.result > 0){
                    objectStore.delete(result.result) 
                    this.props.carregarProdutosSacola()
                    const verificandoProdutos = objectStore.getAll()

                    verificandoProdutos.onsuccess = () => {
                       
                        if (verificandoProdutos.result.length === 0) {
                            this.fecharModalAdd()
                            this.props.alterandoTotalSacola();
                            this.props.alterandoQtdSacola()
                            this.props.verificandoQtdSacola()
                        }
                        else{
                            this.props.verificandoQtdSacola()
                        }
     
                    }
                }
            }
        }
    }


    refatorandoArray = (value) => {
        const array = [];
        let tipo

        if (value.produtoAcompanhamento[0] !== undefined){
            value.produtoAcompanhamento.map(item => {
   
                tipo = item.tipo
                delete item.id
                delete item.nome
                delete item.qtd
                delete item.qtd2
                delete item.stipo
                delete item.cor
                delete item.tipo
    
       
                item.itens.map(item => {
    
                    delete  item.id
                    delete  item.fixo
                    delete  item.marcado
                    delete  item.id
                    // delete  item.id_produto_acompanhamento
                    delete  item.id_produto_grupo_acompanhamento
                    delete  item.nome
                    delete  item.descricao
                    delete  item.pedidoId
                    delete  item.updatedAt
                    delete  item.createdAt
                    delete  item.usuarioId  
    
                    
                    if (item.qtd_escolhida > 0 &&  (tipo === 'OB' || tipo === 'OP') ){
                        array.push(item)
                        
                    } 
                })
    
            })
            
            return array
        }
        else{
            return array 
        }
        
    }

    formatandoProdutosParaEnviarPedido = () => {
        const pedido_produto = [];
        this.props.produtosSacola.map((item) => {
            let valor = item.precoUnitario;
            let total = item.total;
            total = total.toString().replace('.', ',');
            valor = valor.toString().replace('.', ',');

            let array = this.refatorandoArray(item);    
                pedido_produto.push({
                id_produto: item.produtoId,
                qtd: item.qtd,
                valor,
                obs: item.obs,
                nome_web: item.nome,
                descricao_web: item.descricaoProduto,
                total,
                itens:array
            })


        })
        return pedido_produto
    }


    
    finalizarPedido = async() => {
        try {
            const {buttonWithdraw, buttonDelivery} = this.state;
            const cnpj = this.props.cnpj;
            const hoje = new Date();
            const dia = hoje.getDate().toString().padStart(2,'0');
            const mes = String(hoje.getMonth() + 1).padStart(2,'0');
            const ano = hoje.getFullYear();
            const data = `${dia}/${mes}/${ano}`;    
            const hora = hoje.toLocaleTimeString();


            const token = getToken('TOKEN');
            if ( (this.props.enderecoEscolhido.length > 0 && buttonDelivery && this.props.NomeFormaPagamento !=='' && this.props.NomeFormaPagamento !== null) ||
                (buttonWithdraw && this.props.NomeFormaPagamento !=='' && this.props.NomeFormaPagamento !== null)
                )
              
            {

                if (this.props.totalGeral < this.props.parametros[0].pedido_minimo ) return alert(`o pedido mínimo é de : ${parseFloat(this.props.parametros[0].pedido_minimo).toLocaleString('pt-br', {style:'currency', currency:'BRL'})}`)

 
                if (token !== null) {
                    this.setState({isLoadingPedido:true});
                    const pedido_produto = this.formatandoProdutosParaEnviarPedido();
                    let troco_para = getToken('VALORTROCO');
                    const formapag = getToken('FORMAPAGAMENTO');
                    const cliente = getToken('CLIENTE');
                    const numero_web = getToken('NUMERO_WEB');
                    const CPF_CNPJ = getToken('CPF_CNPJ');
    
                    troco_para = troco_para.replace('.','');
                
                    if (pedido_produto.length === 0) {
                        this.setState({isLoadingPedido:false});
                        return alert('Sacola sem produto!')
                    }
    
                    const service = new salvarPedido();
                    const result  = await service.salvarPedido({
                        cnpj,
                        data,
                        tx_entrega :buttonWithdraw === false ? this.props.tx_entrega : 0.00,
                        numero_web,
                        CPF_CNPJ,
                        subtotal :this.props.totalGeral.toString().replace('.', ','),
                        total: buttonWithdraw === false? (this.props.totalGeral + this.props.tx_entrega).toString().replace('.', ',') : this.props.totalGeral.toString().replace('.', ','),
                        logradouro:   buttonWithdraw ? this.props.parametros[0].site_logradouro :this.props.enderecoEscolhido[0].rua,
                        cep: buttonWithdraw ? this.props.parametros[0].site_cep : this.props.enderecoEscolhido[0].cep,
                        referencia: buttonWithdraw ? this.props.parametros[0].site_referencia  : this.props.enderecoEscolhido[0].ponto_referencia,
                        numero_endereco: buttonWithdraw ? this.props.parametros[0].site_numero  : this.props.enderecoEscolhido[0].numero,
                        complemento:  buttonWithdraw ? this.props.parametros[0].site_complemento : this.props.enderecoEscolhido[0].complemento,
                        bairro: buttonWithdraw ? this.props.parametros[0].site_bairro : this.props.enderecoEscolhido[0].bairro,
                        cidade: buttonWithdraw ? this.props.parametros[0].site_cidade :  this.props.enderecoEscolhido[0].cidade,
                        hora:hora,
                        cliente,
                        formapag,
                        status:"Aguardando o Estabelecimento Aceitar",
                        troco_para: (troco_para),
                        RETIRAR_LOJA: buttonWithdraw ? 'S' :'N',
                        pedido_produto:
                            pedido_produto
                    });
    
                    if (result.success === true) {
                        // limpando a forma de pagamento
                        logout('FORMAPAGAMENTO');
                        logout('VALORTROCO');
                        logout('NOMEFORMAPAGAMENTO');
                        await this.deleteAllProdutos();
                        this.setState({isLoadingPedido:false})
                        this.fecharModalAdd();
                        // chamando a tela de pedidos
                        this.props.carregarPedidos();
    
    
                    }
                }
                else{
                    this.props.RotaLogin();
                }

            }else{
                if ( this.props.enderecoEscolhido.length <= 0 ) return this.props.RotaLogin();

                if ( this.props.NomeFormaPagamento === '' || this.props.NomeFormaPagamento === null) return this.props.mostrarModalFormaPagamento();
 
            }
        } catch (error) {
            console.log(error)
            // if (error.response.status === 401) {
            //     alert('Sua seção expirou. Logue novamente!')
            // }
            // else{
            //     alert('Ocorreu um erro na finalização do pedido. Entre em contato com o delivery!');
            // }

            this.setState({isLoadingPedido: false});
        }
    }

    buttonDelivery = () => {
        this.setState({buttonDelivery:true,buttonWithdraw:false});
        this.props.alterarEntrega(true);
        let token = getToken('TOKEN');
        
        if(token !== null){
            if (this.props.revalidadorNaoEntregamosAi) this.props.alteraNaoEntregamosAi(true, 'Infelizmente não entregamos para sua localidade!')
        }
        
    }


    buttonWithdraw = () => {
        this.setState({buttonWithdraw:true,buttonDelivery:false});
        this.props.alterarEntrega(false);
        
        this.props.alteraNaoEntregamosAi(false, '')
    }

    render(){
        const  {
                    buttonDelivery,
                    buttonWithdraw, 
                    isLoadingPedido

                } = this.state;

        return(
            <>
                <BoxModal id={this.props.idd} onClick={(event) => this.fecharModal(event)}>
                    <BoxContainer  >
                        <BoxHeader >     
                            <BoxIconeVoltar src={IconeVoltar} onClick={() => this.fecharModalAdd()} /> 
                            <h1>Sacola</h1>
                        </BoxHeader>

                        <BoxAddProduto >
                            {
                                this.props.produtosSacola.length > 0 ? (
                                    this.props.produtosSacola.map((item) => { 
                                        
                                        return(
                                            <BoxSacolaProdutos  key={item.id}>
                                                <div className="fotoProduto">
                                                    <BoxQtdProdutosSacola>
                                                        {item.qtd}
                                                    </BoxQtdProdutosSacola>
                                                    <BoxFoto  src={`${this.props.foto}/${item.foto}`}  />
                                                </div>

                                                <BoxInfoProdutos>
                                                    <div>
                                                        {item.nome}
                                                    </div>

                                                    <div className="total">
                                                        {parseFloat(item.total).toLocaleString('pt-br', {style:'currency', currency:'BRL'})}
                                                    </div>
                                                </BoxInfoProdutos>

                                                <BoxAcoes>
                                                    <BoxIcone src={Editar}   onClick={() => this.mostrarAddProdutos(item.link_web, item.id)} />
                                                    <BoxIcone src={Exluir} onClick={() => this.deleteBancoOuLocal(item.id)}/>
                                                    
                                                </BoxAcoes>
                                            </BoxSacolaProdutos>
                                        )    
                                    })
                                        )
                                        :(
                                            null
                                        )
                                    }

                            <DeliveryOptionsWrapper>

                                    <DeliveryAskForMore>
                                        <div className="ask-for-more-text-wrapper" onClick={() => this.fecharModalAdd()}>
                                           <p>+ Adicionar mais itens</p> 
                                        </div>
                                    </DeliveryAskForMore>


                                    <DeliveryOptionsHeader>
                                        <div className="end-order-text-wrapper">
                                           <p>Finalize seu pedido!</p> 
                                        </div>
                                    </DeliveryOptionsHeader>

                                    <div className="buttons-wrapper">
                                        <DeliveryOptionsButton activeButton={buttonDelivery} onClick={()=> this.buttonDelivery()}>
                                            <div className="icon-button">
                                                <img src={buttonDelivery ? iconButtonDeliveryActive : iconButtonDelivery } alt="imagem de um capacete simbolizando a entrega"/>
                                            </div>
                                            <div className="text-delivery-wrapper">
                                                <p className="text-delivery">Entrega</p>
                                            </div>
                                        </DeliveryOptionsButton>
                                        
                                        <DeliveryOptionsButton activeButton={buttonWithdraw} onClick={()=> this.buttonWithdraw()}>
                                            <div className="icon-button">
                                                <img src={buttonWithdraw ? iconButtonWithdrawActive: iconButtonWithdraw} alt="imagem de uma lojinha simbolizando a retirada na loja"/>
                                            </div>
                                            <div className="text-delivery-wrapper">
                                                <p className="text-withdraw">Retirada</p>
                                            </div>
                                        </DeliveryOptionsButton>
                                    </div>
                            </DeliveryOptionsWrapper> 
                            <BoxEndereco>
                                {
                                    buttonWithdraw ?(
                                    <>
                                        <div className="em-construçao">
                                            {this.props.parametros.map(item => `${item.site_logradouro}, ${item.site_numero} - ${item.site_bairro}, ${item.site_cidade}`)}
                                        </div>
                                    </>
                                    )
                                    :(
                                        this.props.enderecoEscolhido.length > 0 ? (
                                            this.props.enderecoEscolhido.map(item => 
                                                <div key={item.id} id='address' onClick={() => this.mostrarModalEndereco()}>
                                                    
                                                    <div className="texto-endereço-wrapper"  >
                                                        <div id='textoendereco'>
                                                            {`${item.rua}, ${item.numero} - ${item.bairro}, ${item.cidade} `}
                                                        </div>
                                                    </div> 
                                                    
                                                    <div id='linkendereco'>
                                                        <p id='informar' >
                                                            {'>'}
                                                            
                                                        </p>
                                                    </div>  
                                                </div>
                                            )
                                        )
                                        :(
                                            <div  id='address' onClick={() => this.mostrarModalEndereco()}>
                                                <div className="texto-endereço-wrapper">
                                                    <div id='textoendereco'> 
                                                        <p>Endereço de entrega</p>
                                                    </div>
                                                    <div id='texto-observação-wrapper'> 
                                                        <p className="texto-observação">*Obrigatorio</p>
                                                    </div>
                                                </div>
    
                                                <div id='linkendereco'>
                                                
                                                    <p id='informar' >
                                                        informar
                                                    </p>
                                                </div>
                                            </div>
                                        )
                                    )
                                }
                            </BoxEndereco>
                            <WrapperBoxTotais>
                                <BoxTotais activeWithdraw={buttonWithdraw}>
                                    {
                                        <>
                                            <div id='subtotal'>
                                                <p>
                                                    Sub Total
                                                </p>

                                                <p>
                                                    {parseFloat(this.props.totalGeral).toLocaleString('pt-br', {style:'currency', currency:'BRL'})}
                                                </p>
                                            </div>

                                            <div id='frete'>
                                                <p>
                                                    Taxa de entrega:
                                                </p>

                                                <p className="total-delivery">
                                                   
                                                    {buttonWithdraw ? "*Grátis" :  parseFloat(this.props.tx_entrega).toLocaleString('pt-br', {style:'currency', currency:'BRL'})}
                                                </p>
                                            </div>

                                            <div id='total-text'>
                                                <p>
                                                    Total:
                                                </p>

                                                <p>
                                                    {parseFloat(`${buttonWithdraw === false ? this.props.tx_entrega + this.props.totalGeral  : this.props.totalGeral } `).toLocaleString('pt-br', {style:'currency', currency:'BRL'})}
                                                </p>
                                            </div>
                                        </>
                                    }
                                </BoxTotais>
                            </WrapperBoxTotais>
                            <BoxFormaPagamento>
                                {
                                    this.props.NomeFormaPagamento !== '' && this.props.NomeFormaPagamento !== null ?(
                                        <>

                                            <div className="texto-pagamento-wrapper" onClick={() => this.props.mostrarModalFormaPagamento()}>
                                                <div id='textopagamento'> 
                                                    <p>{this.props.NomeFormaPagamento}</p>
                                                </div>
                                            
                                            </div>
            
                                            <div id='linkpagamento' onClick={() => this.props.mostrarModalFormaPagamento()}>
                                                <p id='informar' >
                                                    {'>'}
                                                </p>
                                            </div>
                                        </>
                                    ) 
                                    :(
                                        <>
                                            <div className="texto-pagamento-wrapper" onClick={() => this.props.mostrarModalFormaPagamento()}>
                                                <div id='textopagamento'> 
                                                    <p>Forma de pagamento</p>
                                                </div>

                                                <div id='texto-pagamento-wrapper'> 
                                                    <p className="texto-obrigatorio">*Obrigatorio</p>
                                                </div>
                                            </div>
            
                                            <div id='linkpagamento'>
                                                <p id='informar' onClick={() => this.props.mostrarModalFormaPagamento()}>
                                                    informar
                                                </p>
                                            </div>
                                        </>
                                    )
                                }
                         
                            </BoxFormaPagamento>
                                
                            {
                                this.props.message !== '' ? (
                                    <BoxMessage>
                                        <this.props.Messagem message={this.props.message}/>
                                    </BoxMessage>
                                )
                                :(
                                    null
                                )
                            }
     
                            <BoxQtdProduto>

                                <BoxBotaototal naoEntregamosAi={this.props.naoEntregamosAi}>
                                    <button disabled={this.props.naoEntregamosAi || isLoadingPedido}  onClick={() => this.finalizarPedido()}>
                                        Finalizar Pedido

                                        {
                                            isLoadingPedido? (  
                                                <Spinner animation="border"/>
                                            )
                                            :(
                                                null
                                            )
                                       }
                                    </button>

                               
                                </BoxBotaototal>
                            </BoxQtdProduto>
                        </BoxAddProduto>
                    </BoxContainer>
                </BoxModal>
            </>
        )
    }
}
export default Sacola
