import React from 'react';
import 
{
    BoxEnderecos, 
    BoxHeader, 
    BoxContainer, 
    BoxIconeVoltar, 
    BoxModal,
    BoxIconeExcluir,
    BoxAcoes,
    BoxIconeEditar,
    BoxImageHeader,
    Address,
    Address2

} from './style' 

import ServiceEndereco from '../../service/enderecoApiNode';
import IconeVoltar from '../../assets/Commons/ArrowIcon.svg';
import Exluir from '../../assets/Commons/iconTrash.svg';
import Editar from '../../assets/Commons/iconEdit.svg';
import imageAddressList from '../../assets/ImageBackGroundLocation/imageAddressList.svg';
import iconAddress from '../../assets/Commons/iconAddressList.svg';
import iconAddressAdd from '../../assets/Commons/iconAddAddress.svg';

class ListarEnderecos extends React.Component{

    constructor(props) {
        super(props)

        this.state = {
           selected: false
        }
    }
   
    async componentDidMount() {
        this.props.getAllEndereco();
    }

    deletarEndereco = async (id) => {
        try {
            const service = new ServiceEndereco();
            const result = await service.deleteEndereco(id, this.props.banco_cardapio)

            if (result.status === 200) {
                const result = await this.props.getAllEndereco();

                if (result === 0)  {
                    this.fecharModalEnderecos();
                }   
            }

        } catch (error) {
            console.log(error)
        }
    } 

    fecharModalEnderecos = () => {
        this.props.fecharModalListagemEnderecos();
    }

    fecharModal = (event) => {
        if (event.target.id === this.props.idd ) this.fecharModalEnderecos()
    }

    mudandoEstadoEndereco = (id) => {
        this.props.mudandoEstadoEndereco()
        this.props.location.history.push(`/${id}/Endereco`)
    }

    mostrarModalEndereco = () => {
        this.props.mudandoEstadoEndereco()
        this.props.location.history.push(`/Endereco`)
    }

    selecionadoEndereco = async(item) => {

        const result = await this.alterarEndereco(item);
        this.props.mudandoEstadoEnderecoEscolhido([result]);
        await this.props.taxaEntregaPorCep();
        // this.props.getAllEndereco();
        this.fecharModalEnderecos();

    }

    alterarEndereco = async (array) => {
        try {
    
            let cep              = array.cep
            let rua              = array.rua
            let bairro           = array.bairro
            let cidade           = array.cidade
            let numero           = array.numero
            let uf               = array.uf
            let complemento      = array.complemento
            let ponto_referencia = array.ponto_referencias
            let selecionado      = 'S'

            const service = new ServiceEndereco();
            const result  = await service.updateEnderecos(array.id,{
                cep,             
                rua,            
                bairro,          
                cidade,          
                numero,          
                uf,              
                complemento,    
                ponto_referencia,
                selecionado,
                banco: this.props.banco_cardapio
            });

            if(result.status === 200) {
                // this.props.getAllEndereco();
                return result.data
            }
            else{
                this.setState({error: result.statusText});
            }

        } catch (errors) {
            this.setState({error: errors.response});
            console.log(errors)
            // if (errors.response.status === 401) {
                this.setState({error: 'Seção expirada. Faça o login novamente!'}) ;
            // } 
        }
    }

    verifyUrlTextChange = ()=>{
        if(this.props.location.location.pathname ==="/UsuarioLogado/ListarEnderecos"){
            return true;
        }

        return false;

    }

    render(){
        const{selected}=this.state

        return(
            <>
                <BoxModal id={this.props.idd} onClick={(event) => this.fecharModal(event)}>
                    <BoxContainer>
                        <BoxHeader>
                     
                            <BoxIconeVoltar src={IconeVoltar} onClick={() => this.fecharModalEnderecos()}/>
                   
                            <h1>Endereços</h1>
                        </BoxHeader>

                        <BoxEnderecos >
                            <BoxImageHeader>
                                <img src={imageAddressList} alt = "imagem do mascote da sistefood"/>
                            </BoxImageHeader>
                            <div className='header-text'>
                                <h4>{this.verifyUrlTextChange()? "Aqui estão seus endereços de entrega!": "Onde deseja receber o seu pedido?"}</h4>
                                {
                                    this.verifyUrlTextChange() ? " " :<p>Escolha um endereço ou cadastre um novo! </p>     
                                }
                                
                            </div>

                            <Address2 className='add-new' onClick={()=> this.mostrarModalEndereco()}>
                                <div className='icon-wrapper'>
                                    <img src={iconAddressAdd} alt="imagem de um sinal de + para a adição dos novos endereços"/>

                                </div>
                                <div className='rua'>
                                    <p className='add-new-address'>Cadastrar Novo Endereço</p>
                                </div>                    
                            </Address2>
                           
                            {
                                this.props.enderecos.length > 0 ? (
                                    this.props.enderecos.map(item =>
                                        <Address tabIndex={1} teste={ selected ? props=>props.theme.colors.Red : props=>props.theme.colors.Grey_3 } onClick={()=>this.setState({selected:true})} key={item.id}>
                                                <div className='icon-wrapper'>
                                                    <img src={iconAddress} alt="icone de um capacete simbolizando a entrega "/>

                                                </div>
                                               <div className='rua' onClick={() => this.selecionadoEndereco(item)}>
                                                    <p>{`${item.rua},${item.numero}`}</p>
                                                    <p className='text-2'>{`${item.bairro}-${item.cidade}/${item.uf}`}</p>
                                                </div>
                                            
                                            <BoxAcoes>
                                                <BoxIconeEditar src={Editar}   onClick={() => this.mudandoEstadoEndereco(item.id)} />
                                                <BoxIconeExcluir src={Exluir} onClick={() => this.deletarEndereco(item.id)}/>
                                            </BoxAcoes>
                                           
                                        </Address>                            
                                    )
                                )
                                :(
                                    null
                                )
                            }                          
                        </BoxEnderecos>
                    </BoxContainer>
                </BoxModal>
            </>
        )
    }
}
export default ListarEnderecos;