import React from 'react'
import {Form, Col, Row, Alert} from 'react-bootstrap'
import {
    BoxEndereco, 
    BoxHeader, 
    BoxContainer, 
    BoxIconeVoltar, 
    BoxQtdProduto,
    BoxBotaototal,
    BoxModal,
    BoxImageHeader,
    SignUpQuoteBox
} from './style'

import { Spinner} from 'react-bootstrap'
import ServiceCep from '../../service/cep';
import ServiceEndereco from '../../service/enderecoApiNode';
import IconeVoltar from '../../assets/Commons/ArrowIcon.svg';
import { getToken } from '../../service/auth';
import imageAddressList from '../../assets/ImageBackGroundLocation/imageAddressList.svg';
import {SearchGoogleMaps} from '../../service/googleMaps'

class Endereco extends React.Component{
    constructor(props){
        super(props)

        this.state ={
            usuarioId:0,
            cep:'',
            rua:'',
            bairro:'',
            cidade:'',
            numero:'',
            uf:'',
            complemento:'',
            ponto_referencia:'',
            arrayCep:[],
            error:'',
            isLoadingEndereco: false
        }
    }

    async componentDidMount() {
        // aqui eu verifico se a localização esta ativa.
        // if ("geolocation" in navigator) {
        //     console.log("Available");
        //   } else {
        //     console.log("Not Available");
        //   }

        // navigator.geolocation.getCurrentPosition( (position) => {
        //     console.log(position);
        //     const service  = new SearchGoogleMaps();
        //     service.getOneAddress(position.coords)

        // })

        
        this.carregarParaEdicao()
    }

    carregarParaEdicao = async() => {
        try {
            const {params: {id}} = this.props.location.match;
            if (id) {
                const service = new ServiceEndereco();
                const result  = await service.getEndereco({id, banco : this.props.banco_cardapio});

                if (result.status === 200) {
                    this.setState({
                        usuarioId: result.data.usuarioId,
                        cep: result.data.cep,
                        rua: result.data.rua,
                        bairro: result.data.bairro,
                        cidade: result.data.cidade,
                        numero: result.data.numero,
                        uf: result.data.uf,
                        complemento: result.data.complemento,
                        ponto_referencia: result.data.ponto_referencia,
                    });
                }
            }
        } catch (error) {
            
        }
    }

    FecharModal = (event) => {
        if (event.target.id === this.props.idd){

            this.FecharModalEndereco();

        }
    }

    FecharModalEndereco = () => {
        this.props.FecharModal()
            
        this.props.alterandoEstadoEndereco();

        // if (this.props.userLogged){
        //     this.props.location.history.push('/UsuarioLogado/ListarEnderecos') ;  
        // }
        // else{
        //     this.props.location.history.push('/ListarEnderecos') ;   
        // }
 

    }

    carregarCep = async(value) => {
        try {
            const service = new ServiceCep();
            const result = await service.getOneCep(value)
            if (result.statusText ==="OK") this.jogandoResultaCep(result.data)
        } catch (error) {
        
        }
    }

    BuscarCEP = (event) => {
        if (event.keyCode === 13 || event.type === 'blur') this.carregarCep(event.target.value)
    }

    onChangeCEP = (e) => {
        let valor = (e.target.value).trim()

        if( isNaN(valor) ) {
            e.target.value= ''
            this.setState({cep:  e.target.value}) ;   
        }
        else {
            e.target.value = valor
            this.setState({cep:  valor}) ;
        }
        this.setState({cep: valor})
        if(valor.length === 8) {
            this.carregarCep(e.target.value);
        }
        if (isNaN(valor)) this.setState({cep: ''})
    }

    jogandoResultaCep = (result) =>{
        const array = [];
        array.push(result)
        this.setState({arrayCep : array})
        const {arrayCep} = this.state

        if (result.erro !== true) {
            arrayCep.map(item => 
                this.setState({
                    rua: item.logradouro,
                    bairro: item.bairro,
                    complemento : item.complemento,
                    cidade: item.localidade,
                    uf: item.uf,
                })
            )

        }
    } 

    HandleEndereco =  (event) => {
        event.preventDefault();
        const {cep, rua, bairro, cidade, numero, uf } = this.state;
        const {params:{id}} = this.props.location.match;

        if (cep.length === 8){  
            console.log(uf)
            if((uf).length < 2) return       this.setState({error: 'O campo UF deve conter duas letras. Exemplo: PE'})
            if (!rua || !bairro || !cidade || !numero || !uf ){
                this.setState({error: 'Os campos: rua, bairro, cidade, numero e uf são obrigatórios'})
            }   
            else if (id > 0) {
                this.alterarEndereco();
            } 
            else {
                this.addEndereco();
            }       
        }
        else{
            this.setState({error: 'Informe o campo CEP'}) 
        }      
    }

    alterarEndereco = async () => {
        try {
            const { usuarioId, cep, rua, bairro, cidade, numero, uf, complemento, ponto_referencia } = this.state;
            const {params:{id}} = this.props.location.match;
            
            this.setState({isLoadingEndereco : true});

            const service = new ServiceEndereco();
            const result  = await service.updateEnderecos(id,{
                usuarioId,
                cep,
                rua,
                bairro,
                cidade,
                numero,
                uf,
                complemento,
                ponto_referencia,
                banco : this.props.banco_cardapio
                
            });

            if(result.status === 200) {
                this.setState({isLoadingEndereco : false});
                this.props.getAllEndereco();
                this.FecharModalEndereco();   
            }
            else{
                this.setState({error: result.statusText, isLoadingEndereco:false});
            }

        } catch (errors) {
            this.setState({error: errors.response.statusText, isLoadingEndereco:false});
            if (errors.response.status === 401) {
                this.setState({error: 'Seção expirada. Faça o login novamente!'}) ;
            } 

        }
    }

    addEndereco = async () => {
        try {
            const {cep, rua, bairro, cidade, numero, uf, complemento, ponto_referencia } = this.state;
            this.setState({isLoadingEndereco : true});

            const service = new ServiceEndereco();
            const result  = await service.getEnderecoViaCep({
                usuarioId: getToken('IDUSUARIO'),
                cep,
                rua,
                bairro,
                cidade,
                numero,
                uf,
                complemento,
                ponto_referencia,
                banco: this.props.banco_cardapio
            });

            if(result.status === 201) {
                this.props.getAllEndereco();
                this.props.alterandoEstadoEndereco();
                this.setState({isLoadingEndereco : false});

                // this.props.location.history.push('/ListarEnderecos');
                this.props.FecharModal();
            }
            else{
                this.setState({error: result.statusText, isLoadingEndereco:false});
            }

        } catch (errors) {
            this.setState({error: errors.response.statusText, isLoadingEndereco:false});
            
            if (errors.response.status === 401) {
                this.setState({error: 'Seção expirada. Faça o login novamente!'}) ;
            } 
        }
    }

    Mensagem = () => {
        const {error} = this.state;
        return(
            <Alert variant='warning'>
                {error}
            </Alert>
        )
    }


    ValidarSomenteLetras = (e) => {
        const valid = new RegExp("[a-zA-Z\d]");
        return valid.test(e)
        
    }


    ApenasLetras = (e) => {


        let result = this.ValidarSomenteLetras(e);
        
        if (result){
            this.setState({uf: e})
        } 
        else{
            this.setState({uf: ''}) 
        }
    } 

    render(){
        const {error, isLoadingEndereco} = this.state;
        return(
            <>
                <BoxModal id={this.props.idd} onClick={(e) => this.FecharModal(e)}>
                    <BoxContainer >
                        <BoxHeader>
                            <BoxIconeVoltar src={IconeVoltar} onClick={() => this.FecharModalEndereco()}/>
                            <p>Endereço de Entrega</p>
                        </BoxHeader>

                        <BoxEndereco>
                            <BoxImageHeader>
                                <img src={imageAddressList} alt="imagem do mascote do sistefood"/>
                            </BoxImageHeader>

                            <SignUpQuoteBox>
                                <h1>Onde deseja receber o pedido ? </h1>
                            </SignUpQuoteBox>

                            <Form onSubmit={this.HandleEndereco}>
                                <p className="input-text-nome">CEP*</p>
                                <input
                                    type="text"
                                    inputmode="numeric"
                                    maxLength={8}
                                    // onBlur={(e) => this.BuscarCEP(e) }
                                    // onKeyUp={(e) => this.BuscarCEP(e) }
                                    onChange={(e) => this.onChangeCEP(e) }
                                    value={this.state.cep}
                                />

                                <p className="input-text-nome">Cidade*</p>
                                <input
                                    type="text"
                                    maxLength={50}
                                    onChange={(e) => this.setState({cidade: e.target.value})}
                                    value={this.state.cidade}
                                />

                                <p className="input-text-nome">Endereço*</p>
                                    <input
                                        type="text"
                                        maxLength={50}
                                        onChange={(e) => this.setState({rua: e.target.value})}
                                        value={this.state.rua}
                                />

                                <p className="input-text-nome">Bairro*</p>
                                    <input
                                        type="text"
                                        maxLength={50}
                                        onChange={(e) => this.setState({bairro: e.target.value})}
                                        value={this.state.bairro}
                                />


                                    
            
                                <Row>
                                    <Col id="numero">
                                        <p className="input-text-nome">Número*</p>
                                            <input
                                                type="text"
                                                maxLength={10}
                                                // inputmode="numeric"
                                                onChange={(e) => this.setState({numero: e.target.value})}
                                                value={this.state.numero}
                                        />
                                    </Col>

                                    <Col id="complemento">
                                        <p className="input-text-nome">Complemento</p>
                                        <input className='input-text-below'
                                            type="text"
                                            maxLength={100}
                                            onChange={(e) => this.setState({complemento: e.target.value})}
                                            value={this.state.complemento}
                                        />
                                        <div className="forgot-password-text-wrapper">
                                            <p className="forget-password-text">Ex.:Casa,apartamento, etc.</p>
                                        </div>
                                    </Col>
                                </Row>

                                
                                <Row>
                                    <Col id="referencia">
                                        <p className="input-text-nome">Ponto de Referência</p>
                                            <input
                                                type="text"
                                                maxLength={50}
                                                onChange={(e) => this.setState({ponto_referencia: e.target.value})}
                                                value={this.state.ponto_referencia}
                                            />
                                    </Col>

                                    <Col id="uf">
                                        <p className="input-text-nome">UF</p>
                                            <input
                                                type="text"
                                                maxLength={2}
                                                onChange={(e) => this.ApenasLetras(e.target.value)}
                                                value={this.state.uf}
                                            />
                                    </Col>

                                </Row>
                                <Row>
                                    {
                                        error !== '' ? (
                                            <this.Mensagem />
                                        )
                                        :(
                                            null
                                        )
                                    }
                                </Row>
                                <BoxQtdProduto>
                                    <BoxBotaototal>
                                        <button type='submit'>
                                            Salvar

                                            {
                                                isLoadingEndereco ?(
                                                    <Spinner animation="border"/>
                                                ) 
                                                :(
                                                    null
                                                )
                                  
                                            }
                                        </button>
                                    </BoxBotaototal>
                                </BoxQtdProduto> 
                            </Form>          
                        </BoxEndereco>               
                    </BoxContainer>
                </BoxModal>
            </>
        )
    }   
}

export default Endereco