import React from "react"
import {Route, Redirect} from "react-router-dom"


const RouteWrapper = ({component: Component, ...rest}) => (
    <Route
        {...rest}
        // renderizar
        render = { props => 
            <Component {...props}/>
        }
    
    />

)

export default RouteWrapper