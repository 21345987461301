import md5 from 'md5'
 

export const incript = (cnpj, endpoint) => {

    let dataAtual = new Date();
    let year      = dataAtual.getFullYear();
    let dia       = dataAtual.getDate().toString();
    let mes       = (dataAtual.getMonth() + 1).toString();
    
    if (mes.length === 1){
        mes= 0+mes;
    }


    if (dia.length === 1){
        dia= '0'+dia;
        // console.log(dia)
    }

    let ano = year.toString()
    
    let result =  ano.substring(2)
    
    const resuladoDatas = `${dia}${mes}${result}`
    // console.log(resuladoDatas)
    // console.log(endpoint)
    let guardar = `${cnpj}${endpoint}${resuladoDatas}_sisteinfo`
    

    const hash = md5(guardar);
    // console.log(hash)

    return hash;

}



