
import React from 'react'

import 
{
    BoxSemLogin, 
    BoxHeader, 
    BoxContainer, 
    BoxIconeVoltar, 
    BoxBotaoFotter,
    BoxModal,
    SignUpQuoteBox,
} from './style' 
import { Form, Row, Alert } from 'react-bootstrap'
import IconeVoltar from '../../assets/Commons/ArrowIcon.svg';
import UsuarioService from '../../service/cadastrarUsuario';
import { cpf } from 'cpf-cnpj-validator';
import {VisiblePassword} from '../../components/VisiblePassword'

class SemLogin extends React.Component{
    constructor(props){
        super(props)

        this.state = {
            nome: "",
            cpf_cnpj:"",
            email:"",
            telefone:"",
            senha:"",
            repetirSenha:"",
            error:"",
            backSpace:"vazio",
            mudandoCorBotao :false,
            repeatPassword:false,
            password:false,

        }
    }
    
    async componentDidMount() {
        // this.props.RotaLogin();
    }

    visiblePasswordRepeatPassword = () => {
        this.setState({repeatPassword: !this.state.repeatPassword})
    }

    visiblePassword= () => {
        this.setState({password: !this.state.password})
    }


    HandleCadastrar = async(event) => {
        event.preventDefault();
        this.Cadastra();
    }


    mudandoCorBotao = () => {
        const {telefone} = this.state;
    
        if (this.state.email === '' || this.state.senha === '' || this.state.nome === '' || this.state.telefone ===''){
            this.setState({mudandoCorBotao: false});
        }
        else{
            this.setState({mudandoCorBotao: true})
        }
        
    }

    removendoMascaraTelefone = () => {
        const {telefone} = this.state;
        let retirarMascara = telefone;

        retirarMascara = retirarMascara.replace('(', '')
        retirarMascara = retirarMascara.replace(')', '')
        retirarMascara = retirarMascara.replace('-', '')
        retirarMascara = retirarMascara.replace(' ', '')
        return retirarMascara;
    }

    Cadastra = async() => {
        try {
            const {email, senha, cpf_cnpj, nome, telefone, repetirSenha} = this.state;

            if (!email || !senha || !repetirSenha || !nome || !telefone) {
                this.setState({error:"Os campos: email, senha, Repita a senha, nome e telefone são obrigatórios"})
            }
            else {
                let validarTelefone =  this.ValidarTelefone(telefone);
                let validarEmail    =  this.ValidarEmail(email);
                let ValidarCPF      =  this.ValidarCPF(cpf_cnpj);
                if (!validarTelefone) return this.setState({error:'Telefone Inválidos'});
                if (!validarEmail)    return this.setState({error:'E-mail Inválido'});
                if (ValidarCPF !== '' && !ValidarCPF )      return this.setState({error:'CPF Inválido'});
                if (senha.length < 6 && repetirSenha) return this.setState({error: 'Senha menor que seis caracteres'})
                if (senha !== repetirSenha) return this.setState({error: 'As senhas não conferem'});

                const service = new UsuarioService();
                const result = await service.add({
                    email,
                    senha,
                    nome,
                    telefone: this.removendoMascaraTelefone(),
                    cpf_cnpj,
                    banco:this.props.banco_cardapio
                })

                if (result.status === 201) {
                    alert('Usuário criado com Sucesso');
                    this.VoltarTelaAnterior();

                }
                else{
                    alert(result.data.message)
                }
                
            }
        } catch (error) {
            this.setState({error:error.response.data.message})
        
        }
    }

    VoltarTelaAnterior = () => {
        this.props.FecharModalCadastrar();
        this.props.location.history.goBack();
    }

    FecharModal = (event) => {
        if (event.target.id === this.props.idd) {
            this.VoltarTelaAnterior();
        }
    }

    Messagem = () => {
        const {error} = this.state;
        return(
            <Alert variant='warning'>
                {error}
            </Alert>
        )
    }

    ValidarEmail = (e) => {
        const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
        return validEmail.test(e)
    }

    ValidarTelefone = (e) => {
        console.log(e)
        const validTelefone = new RegExp('^\\(((1[1-9])|([2-9][0-9]))\\)((3[0-9]{3}-[0-9]{4})|(9[0-9]{3}-[0-9]{5}))$');
        return validTelefone.test(e)
    }

    onChangeEmail = (e) => {
        const result = this.ValidarEmail(e.target.value);
        if (result === false) {
            console.log(result);
            this.setState({error: 'email inválido', email:(e.target.value).trim()});
        }
        else{
            this.setState({error: ''})
            this.setState({email: (e.target.value).trim()});
        }
        this.mudandoCorBotao();

    }

 
    ValidarCPF = (valor) => {
        if (valor !== ''){
            let validar = valor;
            return cpf.isValid(validar)
        }
        return ''
    }

    onChangeCPF = (e) => {
        this.setState({backSpace: e.keyCode})

        if (parseInt(e.target.value)) {
            let result = '';
            if (e.target.value !== '') {
                result = this.ValidarCPF(e.target.value)
            }
            if (result === false) {
                this.setState({cpf_cnpj: e.target.value,  error: 'CPF inválido'})
            }
            else{   
                this.setState({error: ''})
                this.setState({cpf_cnpj: e.target.value})
            }
    
            
        }else{
            this.setState({error: ''})
            this.setState({cpf_cnpj: ''})
        }
        
        this.mudandoCorBotao();
    }   

    mascaraTelefone = (e) => {
        let valor = e;
        if (valor) {
            valor = valor
            .replace(/\D/g, "")
            .replace(/(\d{2})(\d)/, "($1) $2")
            .replace(/(\d{4})(\d)/, "$1-$2")
            .replace(/(-\d{5})(\d+?)$/, "$1");
            valor = valor.replace(' ', '');
            let resultTelefone = this.ValidarTelefone(valor)
            if (resultTelefone) this.setState({telefone: valor, error:''});  
            if (resultTelefone === false) this.setState({telefone: valor, error:'Telefone inválido'});  
        
        }  

        this.mudandoCorBotao();
    }

    onChangeTelefone = (e) => {
        let valor = e.target.value
        valor = parseInt(valor.replace(/[\D]+/g, ''));
        if( isNaN(valor) ) {
            e.target.value= ''
            this.setState({telefone:  e.target.value}) ;   
        }
        else {
            e.target.value= valor
            this.setState({telefone:  e.target.value}) ;
        }
        this.setState({telefone: valor})
        if (isNaN(valor)) this.setState({telefone: ''})


        if (valor.toString().length === 11)  this.mascaraTelefone(valor.toString())

        this.mudandoCorBotao();
    } 


    changeNome = (value) => {
        this.setState({nome: value})
        this.mudandoCorBotao();
    }

    changeCrieSenha = (value) => {
        this.setState({senha: (value).trim()})
        this.mudandoCorBotao();
    }

    render(){
        const {error, mudandoCorBotao, repeatPassword, password} = this.state;
        return(
            <>
                <BoxModal id={this.props.idd} onClick={(e) => this.FecharModal(e)}>
                    <BoxContainer>
                            <BoxHeader>
                                <BoxIconeVoltar src={IconeVoltar} onClick={() => this.VoltarTelaAnterior()}  />
                                <h1>Cadastrar-se</h1>
                            </BoxHeader>
                            <BoxSemLogin>
                                <SignUpQuoteBox>
                                    <div>
                                        <h1>Novo por aqui?</h1>
                                    </div>
                                    <div>
                                        <p>faça seu cadastro para continuar com seu pedido ; {')'}</p>
                                    </div>
                                </SignUpQuoteBox>
                                <Row>
                                    <Form onSubmit={this.HandleCadastrar}>
                                    
                                    <p className="input-text-nome">Nome Completo*</p>
                                    <input
                                        type="text"
                                        maxLength={50}
                                        onChange={(e) => this.changeNome(e.target.value) }
                                        value={this.state.nome}
                                    />

                                    <p className="input-text-nome">CPF</p>
                                    <input
                                        type="text"
                                        maxLength={11}
                                        inputmode="numeric"
                                        onKeyUp={(e) => this.onChangeCPF(e)}
                                        onChange={(e) => this.onChangeCPF(e)}
                                        value={this.state.cpf_cnpj}
                                    />

                                    <p className="input-text-nome">Número de telefone*</p>
                                    <input
                                        type="text"
                                        inputmode="numeric"
                                        maxLength={14}
                                        onChange={(e) => this.onChangeTelefone(e)}
                                        onBlur={(e)=> this.mascaraTelefone( e.target.value)}
                                        value={this.state.telefone}
                                    />    
                                
                                    <p className="input-text-email">Digite seu E-mail*</p>
                                    <input
                                        maxLength={150}
                                        onChange={(e) => this.onChangeEmail(e)}
                                        autoCapitalize="none"
                                        value={this.state.email}
                                    />

                            
                                    <p className="input-text-password">Crie uma senha*</p>
                                    <div className="text-wrapper">
                                        <p className="password-text">Máximo de seis dígitos</p>
                                    </div>
                                    
                                    <div className='wrapper-passaword'>
                                        <input className="senha"
                                            type={password? 'text' : 'password'}
                                            maxLength={6}
                                            onChange={(e) =>  this.changeCrieSenha(e.target.value)}
                                            value={this.state.senha}
                                        />

                                        {
                                            this.props.visiblePassword  && this.state.senha !== '' ? (
                                                <VisiblePassword
                                                    genericFunction={this.visiblePassword}
                                        
                                                />
                                            )
                                            :(
                                                null
                                            )
                                        }
                                  
                                    </div>

                                    
                                    <p className="input-text-password">Repita a senha*</p>
                                    <div className="text-wrapper">
                                        <p className="password-text">Máximo de seis dígitos</p>
                                    </div>

                                    <div className='wrapper-passaword'>
                                        <input className="senha"
                                            type={repeatPassword? 'text' : 'password'}
                                            maxLength={6}
                                            onChange={(e) =>  this.setState({repetirSenha: (e.target.value).trim()})}
                                            value={this.state.repetirSenha}
                                        />

                                        {
                                            this.props.visiblePassword && this.state.repetirSenha !== ''? (
                                                <VisiblePassword id={'repeat-password'}
                                                    genericFunction={this.visiblePasswordRepeatPassword}
                                                />
                                            )
                                            :(
                                                null
                                            )
                                        }
                                    </div>


                                    {
                                        error !== '' ? (
                                            <Row id='error'>
                                                {<this.Messagem error={error} />}
                                            </Row>    
                                        )
                                        :(
                                            null
                                        )
                                    }
                                    
                                    <BoxBotaoFotter mudandoCorBotao={mudandoCorBotao}>
                                        <button type="submit">
                                            Cadastrar
                                        </button>
                                    </BoxBotaoFotter>
                                    </Form>
                                </Row>
                            </BoxSemLogin>
                    </BoxContainer>
                </BoxModal>
            </>
        )
    }
}
export default SemLogin