import styled from "styled-components";

export const BoxContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  flex-direction: column;
  background: ${props=>props.theme.background.Primary};

  @media (min-width: 280px) and (max-width: 520px) {
    width: 100%;
  }
`;

export const BoxModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2030;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const BoxQtdGeral = styled.div`
  display: flex;
  justify-content: center;
  height: 55px;
  text-align: center;
  align-items: center;
  border: 1px solid ${props=> props.theme.colors.Grey_3};
  border-radius: 10px;
  margin-right: 10px;
  color: ${props=> props.theme.text.Black_1};
`;

export const BoxInput = styled.div`
  margin: 0;
  padding: 0;
  input {
    text-align: center;
    background: transparent;
    width: 50px;
    margin: 0;
    outline: none;
    border: none;
    box-shadow: 0 0 0 0;
    padding: 0;
    color: ${props=> props.theme.text.Black_1};
  }

  input:active {
    background: transparent;
    outline: none;
    box-shadow: 0 0 0 0;
    border: none;
    padding: 0;
  }

  input:read-only {
    background: transparent;
    outline: none;
    box-shadow: 0 0 0 0;
    border: none;
    padding: 0;
  }

  input:focus {
    background: transparent;
    outline: none;
    box-shadow: 0 0 0 0;
    border: none;
    padding: 0;
  }
`;

export const BoxIconeVoltar = styled.img`
  display: flex;
  position: absolute;
  top: 12px;
  border: none;
  cursor: pointer;
`;

export const BoxAddProduto = styled.div`
  padding: 24px 12px 12px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 520px;
  overflow-y: auto;
  flex-wrap: nowrap;
  height: 100%;

  scroll-behavior: smooth;

    #containerNome{
        width: 100%;
        /* min-height: 66px; */
    }

    .image-wrapper{
        display: flex;
        width: 100%;
        height: 300px;
        box-sizing: content-box;
        margin-bottom: 32px;
        justify-content: center;
        align-items: center;

        #container-image{
            width: 300px;
            height: 300px;
        }
    }

  input {
    width: 11px;
    color: red;
  }

  #obs {
    display: flex;
    width: 100%;
    justify-content: left;
    margin-top: 2em;
    margin-bottom: 0.5em;
    
    p {
      margin: 0;
      padding: 0;
    }
  }

  #nome {
    margin: 0;
    padding: 0;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    color: ${props=> props.theme.text.Black_1};
  }

  #infoproduto {
    margin: 0;
    padding: 0;
    margin-top: 2em;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${props=> props.theme.text.Primary};
  }

  @media (min-width: 280px) and (max-width: 520px) {
    width: 100%;
  }
`;

export const BoxHeader = styled.header`
  width: 100%;
  text-align: center;
  padding: 1em;
  background: ${props=>props.theme.background.Primary};
  height: 52px;
  border: 1px solid ${props=> props.theme.colors.Grey_2};

  h1 {
    margin: 0;
    padding: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${props=> props.theme.text.Black_1};
  }
`;

export const BoxFoto = styled.img`
  display: flex;
  width: 100%;
  border-radius: 5px;
  min-height: 250px;
  max-height: 294px;

  @media (width: 320px) {
    min-height: 128.24px;
  }
`;

export const BoxObs = styled.textarea`
  display: flex;
  width: 100%;
  min-height: 139px;
  border: 1px solid ${props=> props.theme.colors.Grey_3};
  border-radius: 10px;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  padding: 1em;
  color: ${props=> props.theme.text.Primary};
  outline: none;
  resize: none;
  margin-bottom: 73px;
`;

export const BoxAddSacola = styled.div`
  display: flex;
  height: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  gap:8px;
  outline: none;
  height: 55px;
  width: 100%;

  font-size: 14px;
  line-height: 18px;
  font-weight: 400;

  
  span{
    display: flex;
    width: 100%;
    justify-content: center;
  }

  p{
    padding-left: 10px;
    min-width: 98px;
  }

  @media (min-width: 320px) and (max-width: 520px) {
    width: 100%;
  }

  @media (width: 280px) {
    width: 100%;
    max-width: 138px;

    span{
      flex-wrap: wrap;
    }
  }
  
`;

export const BoxBotaototal = styled.div`
  display: flex;
  flex-wrap: nowrap;
  width: 100%;

  button {
    width: 100%;
    font-weight: 500;
    padding: 0 0.2em 0 0.2em;
    background-color:${props=> props.theme.colors.Red};
    border: none;
    outline: none;
    border-radius: 15px;
    color: ${props=> props.theme.text.White_1};
  }

  
  @media (min-width: 280px) and (max-width: 520px) {
    width: 100%;
  }
`;

export const BoxQtdProduto = styled.div`
  display: flex;
  position: fixed;
  align-items: center;
  bottom: 0;
  width: 520px;
  padding: 1em;
  justify-content: space-between;
  height: 73px;
  background: ${props=>props.theme.background.Grey_1};

  @media (min-width: 280px) and (max-width: 520px) {
    right: 0;
    left: 0;
    width: 100%;
  }
`;

export const BoxQtdMenos = styled.div`
  display: flex;
  min-width: 30px;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 10px;
  cursor: pointer;
`;

export const BoxQtdMais = styled.div`
  display: flex;
  min-width: 30px;
  height: 100%;
  align-items: center;
  border-radius: 10px;
  justify-content: center;
  cursor: pointer;
`;
