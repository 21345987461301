export default {
    // SITE: 'http://192.168.18.9:9000/site/',   
    // API_CNPJ_CARDAPIO: 'http://192.168.18.9:9000/app/',
    // CARDAPIO:'http://192.168.18.9:9000/cardapio/',
    // API_USUARIO: 'http://192.168.18.9:4001/',  
    // API_PRODUTOS: 'http://192.168.18.9:4003/', 
    // API_ENDERECOS: 'http://192.168.18.9:4002/', 
    VIACEP: 'https://viacep.com.br/ws/',


    // omar casa
    // SITE: 'http://192.168.101.6:9000/site/',   
    // CARDAPIO:'http://192.168.101.6:9000/cardapio/',
    // API_CNPJ_CARDAPIO: 'http://192.168.101.6:9000/app/',
    // API_USUARIO: 'http://192.168.101.6:4001/',  
    // API_PRODUTOS: 'http://192.168.101.6:4003/', 
    // API_ENDERECOS: 'http://192.168.101.6:4002/', 

    // SITE: 'http://localhost:9000/site/',   
    // CARDAPIO:'http://localhost:9000/cardapio/',
    // API_CNPJ_CARDAPIO: 'http://localhost:9000/app/',
    // API_USUARIO: 'http://localhost:4001/',  
    // API_PRODUTOS: 'http://localhost:4003/', 
    // API_ENDERECOS: 'http://localhost:4002/', 
    // API_GOOGLE_MAPS:'https://maps.googleapis.com/maps/api/geocode/',

    // // @@ Gabriel
    // SITE: 'http://192.168.56.1:9000/site/',
    // API_USUARIO: 'http://192.168.56.1:4001/',  
    // API_PRODUTOS: 'http://192.168.56.1:4003/', 
    // API_ENDERECOS: 'http://192.168.56.1:4002/',
    
    ///@@ site
    SITE: 'http://54.165.171.216:9000/site/',
    CARDAPIO:'http://54.165.171.216:9000/cardapio/',   
    API_CNPJ_CARDAPIO: 'https://54.165.171.216:9001/app/',
    API_USUARIO: 'http://54.165.171.216:4001/',  
    API_PRODUTOS: 'http://54.165.171.216:4003/', 
    API_ENDERECOS: 'http://54.165.171.216:4002/'

    // SITE: 'http://25.53.124.126:9000/site/',   
    // CARDAPIO:'http://25.53.124.126:9000/cardapio/',
    // API_CNPJ_CARDAPIO: 'http://25.53.124.126:9000/app/',
    // API_USUARIO: 'http://25.53.124.126:4001/',  
    // API_PRODUTOS: 'http://25.53.124.126:4003/', 
    // API_ENDERECOS: 'http://25.53.124.126:4002/'

}   
