import styled from "styled-components";

export const BubbleImg = styled.img`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  /* background: #50A773; */
  border-radius: 23.871px;
  flex: none;
  order: 1;
  margin-left:8px;
  flex-grow: 0;
`;

export const Container = styled.div`
  width: 85px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;

  .state-bubble {
    width: 5.5px;
    height: 5.5px;
    background-color: ${(props) =>
      props.OpenOrClosed.toString() === "S" ? `${props.theme.colors.Green}` : `${props.theme.colors.Red}`};
    border-radius: 50%;
  }

  .state-name-wrapper {
    width: 100%;
  }

  .state-name {
    /* display: flex;
    font-size: 10px;
    line-height: 14px;
    font-weight: 400;
    justify-content: right; */

    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color:${(props) =>
      props.OpenOrClosed.toString() === "S" ? `${props.theme.colors.Green_2}` : `${props.theme.colors.Red}`}
    /* color: #50A773; */

  }

  .wrapper-state-name-bubble{
    display: flex;
    align-items: center;
    padding: 0px;
    gap: 8px;
    flex: none;
    order: 0;
    flex-grow: 0;
  }
`;
