import baseApi from './api'
import baseUrls from '../configs/baseURL'


class FreteService {
    constructor (){
        this.api = baseApi(baseUrls.SITE)
    }

    async getOne(modelFrete) {
        const result = await this.api.post('frete',modelFrete);

        return result.data
    }
}

export default FreteService