import styled from "styled-components";

export const BoxContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  flex-direction: column;
  background: ${props=>props.theme.background.Primary};
  width: 100%;
  max-width: 520px;

  @media (min-width: 280px) and (max-width: 520px) {
    width: 100%;
    max-width: 520px;
  }
`;

export const BoxContainerButton = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-top: 18px;

  @media (min-width: 280px) and (max-width: 520px) {
    width: 100%;
    max-width: 520px;
  }
`;

export const BoxWrapperButton= styled.div`
    display: flex;
    flex-wrap: nowrap; 
    width: 100%;
    height: 55px;
        
    button{
        width: 100%;
        border-radius: 10px;
        outline: none;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        box-shadow: 0 0 0 0;
        background: ${props=>props.theme.background.Black_1};
        color: ${props=>props.theme.text.White_1};
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;

        .spinner-border{
            margin-left: 10px;
            background-color: transparent;
            color: ${props=>props.theme.text.White_1} !important;
        }
    }
`;

export const BoxHeader = styled.header`
  width: 100%;
  text-align: center;
  padding: 1em;
  background: ${props=>props.theme.background.Primary};
  height: 52px;
  border-bottom: solid 1px ${props=>props.theme.colors.Grey_2};

  h1 {
    margin: 0;
    padding: 0;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${props=>props.theme.text.Black_1}
  }
`;

export const BoxModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2020;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const BoxIconeVoltar = styled.img`
  display: flex;
  position: absolute;
  top: 12px;
  border: none;
  cursor: pointer;
`;

export const SignUpQuoteBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 32px;

  h3 {
    font-size: 16px;
    line-height: 20px;
    font-weight: 700;
  }

  p {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    text-align: center;
  }
`;

export const BoxSemLogin = styled.div`
  padding: 0px 32px;
  display: flex;
  overflow-x: auto;
  display: flex;
  width: 520px;
  flex-direction: column;
  padding-bottom: 1em;
  height: 100%;

  #error {
    justify-content: center;
    text-align: center;
  }

  .email {
    background: ${props=>props.theme.background.White_2};
  }

  .row {
    margin: 0;
    padding: 0;
    width: 100%;

    .input-text-password,
    .input-text-email,
    .input-text-nome {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: ${props=>props.theme.text.Black_1}
    }

    .Dados-contatos {
      margin-top: 32px;
    }

    h4 {
      font-size: 16px;
      line-height: 20px;
      font-weight: 700;
    }

    form {
      width: 100%;
    }

    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    }

    input {
      color: ${props=>props.theme.text.Black_1};
      height: 48px;
      width: 100%;
      border-radius: 10px;
      border: solid 1px ${props=>props.theme.colors.Grey_3};
      font-size: 14px;
      line-height: 12px;
      font-weight: 400;
      background: ${props=>props.theme.background.White_2};
      outline: none;
      padding: 15px 12px;
      margin-bottom: 16px;
      margin-top: 8px;
      :focus {
        border: 2px solid black;
      }
    }

    input:read-only{
      background-color: transparent;
      border: 1px solid ${props=>props.theme.colors.Grey_3};
    }

  }

  @media (min-width: 280px) and (max-width: 520px) {
    width: 100%;

    .row{
      input{
          font-size: 16px;
      } 

    }

  }
`;
