import axios from 'axios';
import { incript } from './gerandoMD5';
import { getToken } from './auth';
import { get } from 'react-scroll/modules/mixins/scroller';

const baseApi = (baseURL) => {
    const api = axios.create({
        baseURL: baseURL
    })


    api.interceptors.request.use(async (config)=>{

        let token;
        let cnpj = getToken('CNPJ') === null || getToken('CNPJ') === '' ? '20798514000141' : getToken('CNPJ')

        token = `Basic ${incript(cnpj,config.url)}`;
        config.headers['Authorization'] = token;
        
        if  (config.url.endsWith('/json') == false) {
            let xAccessToken = await getToken('TOKEN');
            config.headers['x-access-token'] = xAccessToken;

        }


        return config;
    })

    return api
} 

export default baseApi