import React from "react";
import {Form, Row, Alert} from 'react-bootstrap';
import { Link } from "react-router-dom";
import 
{
    BoxLogin, 
    BoxHeader, 
    BoxContainer, 
    BoxIconeVoltar, 
    BoxBotaoFotter,
    BoxModal,
    BoxImageHeader,
    FooterLogin,
} from './style' 

import UsuarioService from "../../service/cadastrarUsuario";
import ServiceProdutoApiNode from '../../service/produtosApiNode'
import IconeVoltar from '../../assets/Commons/ArrowIcon.svg';
import imgFooterLogin from '../../assets/Login/imgFooterLogin.svg';
import imageLoginHeader from '../../assets/Login/ImageLoginHeader.svg'
import {entrar, getToken} from '../../service/auth'

import { Spinner} from 'react-bootstrap'
import {VisiblePassword} from '../../components/VisiblePassword'

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email:"",
            senha:"",
            error:"",
            newPassword:"",
            isNotLoadingLogin:true,
            disabled : false,
            password:false
        }
    }

    redirecionandoParaTelaEndereco = () => {

        if(this.props.enderecos.length === 0){
            this.props.carregarProdutosSacola();
            this.props.mudandoEstadoEndereco();
            this.props.location.history.push('/Endereco')
            this.props.FecharModalLogin();
        }
        else{
            this.VoltarTelaAnterior();
        }
    }


    // ForgotPassword = async () =>{
    //     const {senha, newPassword} = this.state;


    //     const service = new UsuarioService();
    //     const result = await service.login({
    //         // email: email.trim(),
    //         senha,
    //         newPassword
    //     });



    // }

    Logar = async () => {
        try {
            
            const {email, senha} = this.state;

            if (senha.length < 6){
                this.setState({error:"o campo senha deve ter 6 caracteres"})
            }  
            else if (!email || !senha) {
                this.setState({error:"Preencha os campos email e senha para logar!"})  
            }
            else{
                this.setState({isNotLoadingLogin: false, disabled: true});
                const service = new UsuarioService();
                const result = await service.login({
                    email: email.trim(),
                    senha,
                    banco:this.props.banco_cardapio
                });
    
                if ( result.status === 200 ) {
         
                    this.setState({error:""});
                    entrar('IDUSUARIO', result.data.idUsuario);
                    entrar('TOKEN', result.data.token);
                    entrar('CLIENTE', result.data.nome);
                    entrar('NUMERO_WEB', result.data.telefone);
                    entrar('CPF_CNPJ', result.data.cpf_cnpj);
                    await this.props.getAllEndereco();
                    await this.props.taxaEntrega();
                    await this.getProduto();
                    this.setState({isNotLoadingLogin: true});
                    this.redirecionandoParaTelaEndereco();
          
                }
                else if (result.success === false) {
                    this.setState({isNotLoadingLogin: true,  disabled: false});
                    this.setState({error: result.message})
               
                }


    
            }

        } catch (error) {
            if (error.response.status === 401 || error.response.status === 422) {
                this.setState({error:"E-mail ou senha esta incorreto(s)"});
            }
            else if (error.response.status === 404 &&  error.response.data.success === false ){
                this.setState({error: error.response.data.message})
            }

            this.setState({isNotLoadingLogin: true, disabled: false});

        }
    }

    Messagem = () => {
        const {error} = this.state;
        return(
            <Alert variant='warning'>
                {error}
            </Alert>
        )
    }

    getProduto = async () => {
        let db ; 
        const request = indexedDB.open('cardapio', 2 );  
          request.onsuccess = () => {
            db = request.result;
            const transaction = db.transaction(["Produtos"], "readonly")
            const objectStore = transaction.objectStore("Produtos")
            const result = objectStore.getAll();
        
            result.onsuccess = async () => {
                let resultadoArray = result.result

                db.close();

                //    removendo o id
                resultadoArray.map(item => delete item.id);
                //    removendo o id
                resultadoArray.map(item => item.produtoAcompanhamento.map(item => delete item.id ))

                resultadoArray.map(item => item.produtoAcompanhamento.map(item =>  item.itens.map(item => delete item.id ) ) )
                
                await this.VerificandoSacolaProdutosBanco();
                try {
                    for (let index = 0; index < resultadoArray.length; index++) {
                        await this.addProduto(resultadoArray[index]);
                    }     
                    await this.props.pegandoProdutosBanco();
                    this.removendoProdutosIndexDB(); 

                } catch (error) {
                    console.log(`addProdutos lote: ${error}`)
                }

           
            }

        } 
       
    }

    VerificandoSacolaProdutosBanco = async () => {
        try {
            const service = new ServiceProdutoApiNode();
            const result = await service.getAllProdutos({banco:this.props.banco_cardapio});
            
            if (result.data.success === true){    
                await this.removendoProdutosBancoDados()
            }           
        } catch (error) {
            console.log(`erro ao trazer produtos apiNode ${error}`);

        }
    }

    removendoProdutosBancoDados = async() => { 
        try {
            const service = new ServiceProdutoApiNode();
                await service.deleteAllProduto({banco: this.props.banco_cardapio});
              
        } catch (error) {
            console.log(error)
        }
    }

    removendoProdutosIndexDB = () => {

        let db ; 
        const request = indexedDB.open('cardapio', 2 );  

            request.onsuccess = () => {
                db = request.result;
                const transaction = db.transaction(["Produtos"], 'readwrite')
                const objectStore = transaction.objectStore("Produtos")
                const result = objectStore.getAll();
            
                result.onsuccess = () => {

                    for (let index = 0; index < result.result.length; index++) {
                        objectStore.delete(result.result[index].id)

                    }

                }
            }
        

    }


    addProduto = async(array) => {
        try {
            const usuarioId = getToken('IDUSUARIO');
            const produtoId = array.produtoId;
            const grupoId = array.grupoId;
            const nome = array.nome;
            const foto = array.foto;
            const codigo = array.codigo;
            const obs = array.obs;
            const precoUnitario = array.precoUnitario;
            const total = array.total;
            const descricaoProduto = array.descricaoProduto;
            const qtd = array.qtd;
            const link_web = array.link_web
            const totalComplemento = array.totalComplemento;
            const produtoAcompanhamento = array.produtoAcompanhamento


            const service = new ServiceProdutoApiNode();
            const result = await service.Add({
                usuarioId,
                produtoId,
                grupoId,
                nome,
                link_web,
                foto,
                codigo,
                obs,
                precoUnitario,
                total,
                descricaoProduto,
                qtd,
                totalComplemento,
                acompanhamento:produtoAcompanhamento.length > 0 ? true: false,
                produtoAcompanhamento:produtoAcompanhamento,
                banco:this.props.banco_cardapio
            });

            if (result.status === 201) {
                
                // this.props.EncapsulandoRequisicoes();
            }

        } catch (error) {
        }
    }

    HandleLogin = async(event) => {
        event.preventDefault();
        this.Logar()
    }

    FecharModalLogin = (event) => {
        if (event.target.id === this.props.idd) {
            this.VoltarTelaAnterior();
        }
    }

    VoltarTelaAnterior = () => {
        this.props.FecharModalLogin();

        // refatorar colocando rotas adequadas
        if (this.props.stateSacola === false && this.props.enderecoEscolhido.length === 0 ) {
            this.props.location.history.push('/Sacola');   

        }
        else if(this.props.stateSacola === false){

            this.props.location.history.push('/Sacola');
        }
        else{
            this.props.location.history.push('/');   
        }
 
    }

    motrarModalSemLogin = () => {
        this.props.MudandoEstadoSemLogin();   
    }

    mostrarModalEsqueciSenha = () => {
        this.props.MudandoEstadoEsqueciSenha();   
    }

    render() {
        const {error, isNotLoadingLogin, disabled, password} = this.state;
        return(
            <>
                <BoxModal id={this.props.idd} onClick={(e) => this.FecharModalLogin(e)}>
                    <BoxContainer>
                        <BoxHeader>
                            <BoxIconeVoltar src={IconeVoltar} onClick={() => this.VoltarTelaAnterior()} />
                            <h1>Login</h1>
                        </BoxHeader> 

                        <BoxLogin>
                            <BoxImageHeader>
                                <img src={imageLoginHeader} alt="imagem no topo com a logo do sistefood"/>
                            </BoxImageHeader>

                            <div className="login-text-wrapper">
                                <p>Faça seu login !</p>
                            </div>

                            <Row>
                                <Form onSubmit={this.HandleLogin}>
                                    <p className="input-text-email">E-mail</p>
                                    <input 
                                        onChange={(e) => this.setState({email: (e.target.value).trim()})}
                                        value={this.state.email}
                                        autoCapitalize='none'
                                    />

                                    <p className="input-text-password">Senha</p>
                                    <div className='wrapper-passaword'>
                                        <input className="senha"
                                            type={password? 'text' : 'password'}
                                            maxLength={6}
                                            onChange={(e) => this.setState({senha: (e.target.value).trim()})}
                                            value={this.state.senha}
                                        />
                                        {
                                            this.props.visiblePassword && this.state.senha !=='' ? (
                                                <VisiblePassword genericFunction={() => this.setState({password:!password})}/>
                                            )
                                            :(
                                                null
                                            )
                                        }

                                    </div>
                                    <div className="forgot-password-text-wrapper">
                                        <Link onClick={()=> this.mostrarModalEsqueciSenha()} to={'login/EsqueciSenha'}>
                                            <p className="forget-password-text">Esqueci minha senha</p>
                                        </Link>
                                    </div>

                                    {
                                        error !== '' ? (
                                            <this.Messagem/>
                                        )
                                        :(
                                            null
                                        )   
                                    }

                                    <BoxBotaoFotter>
                                        
                                        <button type="submit" disabled={disabled}>
                                            Entrar
                                            {
                                             
                                                isNotLoadingLogin === false ? (
                                                  
                                                    <Spinner animation="border" variant="primary"   id='spinner'/>
                                                )
                                                :(
                                                    null
                                                )
                                            }
                                        </button>
                                    </BoxBotaoFotter>
                                    <div className="signup-wrapper">
                                         <p>Não tem uma conta?</p>
                                        <Link  onClick={() => this.motrarModalSemLogin()} to={'Login/Cadastrar'}>
                                            <p className="signup-link-text">Cadastre-se</p>
                                        </Link>
                                    </div>

                                    <FooterLogin>
                                        <p className="footer-text">Ambiente seguro</p>
                                        <img src={imgFooterLogin} alt="imagem de rodape sisteinfo"/>
                                    </FooterLogin>
                                </Form>
                            </Row>
                        </BoxLogin>            
                    </BoxContainer>
                </BoxModal>
            </>
        )
    }
}
export default Login;