import baseURL from "../configs/baseURL";
import baseApi from "./api";

class ServiceEndereco {
    constructor(){
        this.api = baseApi(baseURL.API_ENDERECOS);
    }

    async getAllEnderecos(modelEndereco){
        const result = await this.api.get(`enderecos/${modelEndereco.banco}`)
        
        return result;
    }

    async getEnderecoSelecionado(modelEndereco){
        const result = await this.api.get(`enderecosSelecionado/${modelEndereco.banco}`)
        
        return result;
    }

    async getEndereco(modelEndereco){
        const result = await this.api.get(`enderecos/${modelEndereco.id}/${modelEndereco.banco}`)
        
        return result;
    }

    async Add(modelEndereco){
        const result = await this.api.post('enderecos', modelEndereco)
        
        return result;
    }

    async updateEnderecos(id, modelEndereco){
        const result = await this.api.patch(`enderecos/${id}`, modelEndereco)
        
        return result;
    }
    
    async deleteEndereco(id, banco){
        const result = await this.api.delete(`enderecos/${id}/${banco}`)
        
        return result;
    }


    async getEnderecoViaCep(modelEndereco){
        const result = await this.api.post('enderecos', modelEndereco)
        
        return result;
    }
}



export default ServiceEndereco;