import React from "react";
import 
    {
        BoxInicial, 
        BoxCard,
        BoxFoto, 
        BoxSecaoProduto, 
        BoxCardinterno,
        BoxContainer,
        BoxInfoPedidos,
        BoxIconNavBar,
        BoxContainerpromotion,
        Boxcategory,
        BoxCardPromotion,
        BoxButtonLeft,
        BoxButtonRight,
        Boxwrapperpromotion,
        IconPromotion,
        BoxCardinternoPromotion,
        BoxCardinternoItemsPromotion
    } from './style'


import { Row, Spinner, Alert} from 'react-bootstrap'
import ServiceCardapio from '../../service/cardapio'
import ServiceProdutos from '../../service/produto'
import { Link} from "react-router-dom";
import {Link as Links } from "react-scroll";
import {createDb} from '../../db/index'
import AddProdutos from "../AddProduct";
import SacolaProdutos from "../Bag";
import Cadastrar from "../Unlogged";
import Endereco from "../Address";
import ListarEnderecos from '../AddressList'
import ServiceProduto from '../../service/produto'
import Sobre from '../../assets/NavBar/iconAbout.svg'
import Promotion from '../../assets/NavBar/iconPromotion.svg'
import Pesquisar from '../../assets/NavBar/iconSearch.svg'
import Pedido from '../../assets/NavBar/iconOrder.svg'
import Login from '../Login'
import Usuario from '../../assets/NavBar/iconUser.svg'
import {Header} from "../../components/Header"
import { entrar, getToken, logout } from "../../service/auth";
import ServiceProdutoApiNode from "../../service/produtosApiNode";
import { ItemsBagButton } from "../../components/ItemsBagButton";
import { InfoBagBox } from "../../components/InfoBagBox";
import UsuarioService from "../../service/cadastrarUsuario";
import UsuarioProdutoApiNode from '../../service/produtosApiNode'
import {UserLoggedOptionsScreen}  from "../UserLoggedOptions";
import MeusPedido from "../Order";
import ServiceEndereco from "../../service/enderecoApiNode";
import UserLoggedInfoScreen  from "../UserInfo";
import FormaPagamento from "../PaymentMethod";
import ParametroServices from "../../service/parametro";
import AboutScreen from "../About";

import * as S from "../../components/SearchBox/style";
import IconSearch from "../../assets/SearchBox/searchBoxIcon.svg";
import IconClearSearch from "../../assets/SearchBox/iconClearSearch.svg";
import DetalhesPedido from "../OrderDetails";
import CancelOrderScreen from "../CancelOrder";
import SupportScreen from "../Support";
import FreteService from "../../service/frete";
import ForgotPassword from "../ForgotPassword";
import ServiceCnpjCardapio from "../../service/cnpjCardapio";
import IconeVoltar from '../../assets/Commons/ArrowIcon.svg';
import iconPromotion from '../../assets/iconPromotion.svg';
import { LazyLoadImage } from "react-lazy-load-image-component";


let clearTimerScroll = 0;
class Headers extends React.Component {
    
    constructor(props){
        super(props)
        
        this.state = {
            cnpj: '',
            categoria: [],
            produtos:[],
            produtosSacola:[],
            produtosAdd:[],
            id:0,  
            qtdItemSacola:0,
            totalSacola:0,
            urlImg:'',
            mostrarModalAdd: true,
            mostrarModalSacola: true,
            mostrarModalLogin: true,
            mostrarModalCadastrar: true,
            mostrarModalEsqueciSenha: false,
            ProdutoNome:[],
            nome:'',
            foto:'',
            searchActive:false,
            userLogged: false,
            mostrarModalPedido: true,
            isLoadingEndereco: true,
            enderecos:[],
            enderecoEscolhido:[],
            mostrarModalEndereco:true,
            userLoggedInfo: false,
            mostrarModalPagamento:true,
            NomeFormaPagamento:'',
            parametros:[],
            entrega:true,
            mostrarModalSobre: false,
            mostrarModalDetalhesPedido: true,
            arrayMeusPedidos: [],
            mostrarModalCancelarPedido: false,
            mostrarModalSuporte:false,
            tx_entrega:0.00,
            naoEntregamosAi: false,
            revalidadorNaoEntregamosAi:false,
            message:'',
            banco_cardapio:'',
            complementProduct:[],
            promotion: false,
            productCategory: [],
            lastProductCategory:0,
            countButton:1,
            arrowLeft: false,
            arrowRight: true,
            positionProductCategory:[],
            banner:[],
            visiblePassword:true

        }
        this.inputRef = React.createRef();
    }

    async componentDidMount () {
        createDb();
      
        await this.EncapsulandoRequisicoes();
        this.verifyScrollPromotion();
        this.updateVisibleArrows();

        window.addEventListener('scroll', this.scroll);

        this.removeScroll =   () => {
            clearInterval(clearTimerScroll);
            window.removeEventListener('scroll',this.scroll);
        };

        this.verifyBrowser()
    }

    verifyBrowser = () => {
        let sUsrAg = navigator.userAgent;
        // if(sUsrAg.indexOf("Chrome") > -1) {
        //     sBrowser = "Google Chrome";
        // } else if (sUsrAg.indexOf("Safari") > -1) {
        //     sBrowser = "Apple Safari";
        // } else if (sUsrAg.indexOf("Opera") > -1) {
        //     sBrowser = "Opera";
        // } else if (sUsrAg.indexOf("Firefox") > -1) {
        //     sBrowser = "Mozilla Firefox";
        // } 
        
        if (sUsrAg.indexOf('Edg')> -1) {
            this.setState({visiblePassword:false})    
        }

    }

    getBanner = async() => {
        try{
            const {cnpj} = this.state;

            const service = new ParametroServices();
            const result  = await service.getBanner({cnpj});
            
            if (result.success === true) {
                this.setState({banner: result.data})
            }



        }catch(error){
            console.log(error)
        }
    }

    timer = () => {
        clearTimerScroll = setTimeout(()=> {
            window.addEventListener('scroll', this.scroll);
        },1000)
    }


    scroll = () => {

        let idElment = 0;
    
        let lista  = document.querySelectorAll('.categoriaitens');

        for (let index = 0; index < lista.length; index++) {
            let elTopo = lista[index].offsetTop; // distância relativa do elemento ao topo

            let scrollTopo = window.pageYOffset; // scroll da janela
            let altJanela = window.innerHeight ; // altura da janela
            let distancia = ((elTopo - scrollTopo)  + (altJanela - 150) ) ; //distância absoluta do elemento ao topo

            if (distancia < altJanela  ){// verifico se o elemento apareceu na janela
                idElment = lista[index].id.substring(8);
                this.scrollCategory(idElment);
    
               
            }
            
        }    
    }

    verifyScrollPromotion = () => {
        if (this.state.productCategory.length  > 0) {            
            const BoxPromotion = document.getElementById(`boxcontainerpromotion`);
            if (BoxPromotion.scrollWidth >  BoxPromotion.offsetWidth) {
                this.setState({arrowRight:  true});
            }
            else if (BoxPromotion.scrollWidth ===  BoxPromotion.offsetWidth ){
                this.setState({arrowRight:   false});
    
            }
        }

    }

    updateVisibleArrows = () => {
        window.onresize  =  e => {
            
            if (this.state.productCategory.length > 0) {
                const BoxPromotion = document.getElementById(`boxcontainerpromotion`);
                
                if (BoxPromotion.scrollWidth >  BoxPromotion.offsetWidth ) {
                    this.setState({arrowRight:  true});
                }
                else if (BoxPromotion.scrollWidth === BoxPromotion.offsetWidth){
                    this.setState({arrowRight:   false});
    
                }

            }
    
    
          
        }
    }

    alterarEntrega = (value) => {
        this.setState({entrega:value})
    }

    updateComplementProductColor = (value, index) => {
        const {complementProduct} = this.state;  
        complementProduct[index].cor = value
        this.setState({complementProduct : complementProduct})
    }

    updateComplementProductItemsRadio = (value, index, itemIndex, el) => {

        const {complementProduct} = this.state;

        for (let index = 0; index < complementProduct[itemIndex].itens.length; index++) {
     
            complementProduct[itemIndex].itens[index].marcado =  'N';
            this.setState({complementProduct: complementProduct});
            
        }

        complementProduct[itemIndex].qtd2 = value === 'S' ?  +1 : 0;
        complementProduct[itemIndex].itens[index].marcado = value ;  
        complementProduct[itemIndex].itens[index].qtd_escolhida = complementProduct[itemIndex].qtd2
        
        
        this.setState({complementProduct: complementProduct})

      
        this.updateComplementProductColor(false ,itemIndex)   
    }


    updateComplementProductItemsQtsMore = (value, index, itemIndex) => {
        const {complementProduct} = this.state;

        if (complementProduct[itemIndex].qtd > parseInt(complementProduct[itemIndex].qtd2)) {

            complementProduct[itemIndex].itens[index].qtd_escolhida = value + 1;
            complementProduct[itemIndex].qtd2 = parseInt(complementProduct[itemIndex].qtd2)  + 1;   
            this.updateComplementProductColor(false ,itemIndex)   
            

            this.setState({complementProduct: complementProduct})

        }
    }

    updateComplementProductItemsQtsAnyLess = (value, index, itemIndex) => {
        const {complementProduct} = this.state;


        if (complementProduct[itemIndex].itens[index].qtd_escolhida > 0) {
            complementProduct[itemIndex].itens[index].qtd_escolhida = value - 1;
            complementProduct[itemIndex].qtd2 = complementProduct[itemIndex].qtd2 > 0 ? complementProduct[itemIndex].qtd2 - 1 : 0;
            this.setState({complementProduct: complementProduct})
        }
    }

    updateComplemento = (value) => {
        this.setState({complementProduct: value});
    }

    updateComplementProduct = async(value) => {
        try {
            const service = new ServiceProdutoApiNode();
            const {banco_cardapio} = this.state
            const result = await  service.getProdutoAcompanhamento({id:value, banco:banco_cardapio});
    
            this.setState({complementProduct: result.data.data});
            
        } catch (error) {
            console.log(error)
        }
    }


    pegandoCnpjCliente = async () => {
        try {
            let url = window.location.hostname;
            logout('CNPJ')
            if (url === 'localhost') {
                url = 'localhost'
            }
    
            const service = new ServiceCnpjCardapio();
            const result= await service.getCnpj({
                cnpj: '20798514000141',
                url
            })

            if (result.success === true) {
                this.setState({cnpj: result.data[0].cnpj, banco_cardapio:result.data[0].banco_cardapio})
                entrar('CNPJ', result.data[0].cnpj)
            }
                    
        } catch (error) {
            
        }
    }


    produtoAcompanhamento = async (idProduct) => {
        try {
            this.setState({complementProduct: []});
            const service = new ServiceProduto();
            const result  =  await service.produtoAcompanhamento({
                cnpj:this.state.cnpj,
                id_produto:idProduct
            });

            if (result.success === true) {
                this.setState({complementProduct: result.data})
            }
        
        } catch (error) {
            console.log(error) 
        }
    }

    Messagem = () => {
        const {message} = this.state;
        return(
            <Alert variant='warning'>
                {message}
            </Alert>
        )
    }

    taxaEntregaPorCep = async () => {
        try {
            const token = getToken('TOKEN');
            if (token !== null) {
                const service = new FreteService();
                const result = await service.getOne({
                    cnpj: this.state.cnpj,
                    cep: this.state.enderecoEscolhido[0].cep,
                    bairro: this.state.enderecoEscolhido[0].bairro,
                    cidade: this.state.enderecoEscolhido[0].cidade
                })
    

                if (result.data.valor <= 0) {
                    this.setState({naoEntregamosAi: true, message:'Infelizmente não entregamos para sua localidade!', revalidadorNaoEntregamosAi: true})
                }
                else{
                    this.setState({naoEntregamosAi: false, message: '', revalidadorNaoEntregamosAi: false})
                }
    
                this.setState({tx_entrega: result.data.valor})
                
            }

    
        } catch (error) {
            console.log(error)
        }
    }

    alteraNaoEntregamosAi = (entrega, message) => {
        this.setState({ naoEntregamosAi: entrega, message: message})
    }


    taxaEntrega = async() => {
        try {
            const {cnpj} = this.state;
            const service = new ParametroServices();
            const result = await service.getAll({cnpj});
            if (result.success === true) {
                this.setState({parametros: result.data, urlImg: result.data[0].site_url_img})
                
                if(result.data[0].site_frete <= 0) {
                    await this.taxaEntregaPorCep();
                }
                else{
                    this.setState({tx_entrega: result.data[0].site_frete, naoEntregamosAi: false, message: '', revalidadorNaoEntregamosAi: false})
                }

            }
        } catch (error) {
            console.log(error)
        }

    }

    carregarProduto = async() => {
        try {
            const {params: {id}} = this.props.match
            const {cnpj} = this.state;

            const service  = new ServiceProduto();
            const result = await service.getAllProdutos({
                cnpj,
                id,
                sisteinfo: 'S'
            })
            if (result.success === true && result.data.length > 0) {   
                this.alterandoEstadoProdutos(result.data[0])
            }

        } catch (error) {

        }
    }

    alterarEstadoProdutoNome = (array) => {
        this.setState({
            ProdutoNome: array,
        })
    }

    verificandoUrl = () => {
        try {
            if ( this.props.history.location.pathname !==  '/') {
                if (this.props.match.path === '/AdicionarProduto/:id/:nome' || this.props.match.path === '/Sacola/AdicionarProduto/:id/:nome') {     
                    this.setState({mostrarModalAdd: false});
                    this.MostrarModalAdd();
                }
                else if(this.props.match.path === '/Sacola'){
                    this.setState({mostrarModalSacola: false});
                    this.mostrarModalSacola();
                }
                else if (this.props.match.path === '/FormaPagamento') {
                    this.mostrarModalFormaPagamento();
                }
                else if (this.props.match.path === '/Login'){
                    this.setState({mostrarModalLogin: false});                                                                            
                }else if (this.props.match.path === '/Login/Cadastrar') {
                    this.setState({mostrarModalCadastrar: false, mostrarModalLogin: false});   
                }
                else if (this.props.match.path === '/UsuarioLogado/MeusDados') {
                    this.setState({userLoggedInfo: true, userLogged: true});
                }
                else if(this.props.match.path === '/UsuarioLogado') {
                    this.setState({userLogged: true});   
                }
                else if (this.props.match.path === '/Pedidos' || this.props.match.path === '/DetalhesPedido'  || this.props.match.path === '/Pedidos/CancelarPedido/Suporte' || this.props.match.path === '/Pedidos/CancelarPedido') {
                    this.setState({mostrarModalPedido: false});   
                    this.props.history.push('/Pedidos');                    
                }
                else if (this.props.match.path === '/ListarEnderecos' || this.props.match.path  ==='/UsuarioLogado/ListarEnderecos') {
                    this.setState({isLoadingEndereco: false});   
                }
                else if (this.props.match.path === '/Endereco' ||  this.props.match.path ==='/:id/Endereco') {
                    this.setState({mostrarModalEndereco: false});   
                }
                else if (this.props.match.path === '/Sobre') {
                    this.setState({mostrarModalSobre: true});   
                }
                else if (this.props.match.path === '/Login/EsqueciSenha') {
                    this.setState({mostrarModalEsqueciSenha:true, mostrarModalLogin: false})
                }
                
            }
        } catch (error) {
            
        }

    }
 
    ActiveSearchs = (a)=>{    
        this.setState({searchActive:  a})
    }

    EncapsulandoRequisicoes = async() => {
        await this.pegandoCnpjCliente();
        await this.getBanner();
        await this.verificandoUsuarioLogado();
        await this.getEnderecoSelecionado()
        await this.taxaEntrega();
        this.verificandoUrl();
        await this.buscarCategorias();
        await this.buscarProdutos();
    }

    verificandoUsuarioLogado =  async () => {
        const usuarioLogado = getToken('IDUSUARIO');

        if (usuarioLogado !== '' && usuarioLogado !== null){
            this.pegandoProdutosBanco();
        } 
        else{
            this.selecionandoProdutosAdd();
        }
    }

    pegandoProdutosBanco = async () => {
        try {
            const service = new ServiceProdutoApiNode();
            const result = await service.getAllProdutos({banco:this.state.banco_cardapio});    

        
            if (result.data.success === true){  
                const total = this.calcularTotalSacola(result.data.data);
                this.setState({qtdItemSacola : result.data.data.length, produtosSacola: result.data.data, totalSacola: total});
                this.alterandoEstadoProdutos(result.data.data);
            }
            
        } catch (error) {
            // if (error.response.data.success===false) 
            // this.clearLocalStorage();
        }
    }

    clearLocalStorage = () => {
        logout('TOKEN');
        logout('IDUSUARIO');
        logout('CPF_CNPJ');
        logout('CLIENTE');
        logout('NUMERO_WEB');
        logout('VALORTROCO');
        logout('FORMAPAGAMENTO');
        logout('NOMEFORMAPAGAMENTO');
    }

    selecionandoProdutosAdd =  () => {
        let db;
        const request = indexedDB.open('cardapio', 2 );  

        request.onsuccess =  () => {

            db = request.result;   
            const transaction = db.transaction(["Produtos"], "readwrite");
            const objectStore =  transaction.objectStore("Produtos");
            const result =  objectStore.getAll();

            result.onsuccess = () => {
                let resultado  =  result;
                this.alterandoEstadoProdutos(resultado.result);
                this.verificandoQtdSacola();
            }
                db.close();
        }
    }
    
    montandoArrayprodutosAdd = (array) => {
        const ArrayGerado = [];
            
      
        array.map((item) => {

            let precoUnitario;
            if (item.preco_venda > 0 && item.oferta_web ==='S') {
                precoUnitario = item.preco_comparativo_web;
            } 
            else{
                precoUnitario =  item.preco_venda ?? item.precoUnitario;
            }

            let nome             = item.nome;
            let codigo           =  item.codigo;
            let grupoId          =  item.id_grupo ?? item.grupoId;
            let usuarioId        = 0;
            let id               = item.id;
            let foto             = item.foto_descricao ?? item.foto ;

            let descricaoProduto = item.descricao_web ?? item.descricaoProduto;
            return ArrayGerado.push({
                nome, 
                codigo:codigo, 
                grupoId:parseInt(grupoId), 
                usuarioId:parseInt(usuarioId), 
                id:parseInt(id), 
                foto, 
                precoUnitario, 
                descricaoProduto : descricaoProduto,
                totalComplemento:0
            })
        })

        return ArrayGerado
    }

    alterandoEstadoProdutos = (array) => {

        if ( array.length > 0 ) {    
            const arrayGerado = this.montandoArrayprodutosAdd(array)
            this.setState({  produtosAdd:  arrayGerado,foto: array[0].foto_descricao})      
        }
        else{
            this.setState({produtosAdd: array, totalSacola: 0.00})
        }  

    }
    
    buscarProdutos = async () => {
        try {
            const {cnpj} = this.state;
            const service = new ServiceProdutos();
        
            const result = await service.getAllProdutosCardapio({
                cnpj,
                ordem:'0'
            })

            if ( result.data.length > 0 ) {

                this.setState({
                    produtos: result.data
                })

                let length = 0;
                Object.entries(result.data).map((el) => 
            
                    el[1].itens.map((item, index) => {

                        if(item.oferta_web === 'S') {
                            length = length + 1;

                    
                            this.setState({promotion: true})
                            this.state.productCategory.push(item)
                
                        }

                    })
                )
                this.setState({productCategory: this.state.productCategory, lastProductCategory: length -1 });
            }   

        } catch (error) {
            
        }
    }

    buscarCategorias = async () =>{
        try{
            const {cnpj} = this.state;
            const service = new ServiceCardapio();

            const result = await service.getAllCategorias({
                cnpj,
                ordem:'0'
            })
            
            if (result.success === true) {
                this.setState({
                    categoria: result.data
                })
            }

        }catch(error){

        }
    }

    scrollCategory = (id) => {
        this.addClass(id);
        let  a  = document.getElementById(`buttons${id}`);
        let coord = a.getBoundingClientRect();
        this.focusScroll(id, coord);     
    }

    getCoordenate = (id) =>{
        this.removeScroll();
        this.addClass(id);
        this.scrollCategory(id)
        this.timer();
    }


    removeClassButton = (id) => {
        const {categoria} = this.state;
        let  element;

        categoria.map((item) => {
      
            if (item[0].id !== id && id > 0) {
       
                element = document.getElementById(`buttons${item[0].id}`);
               
                element.classList.remove('focusbutton');
        
                element.classList.add('button');  
            }
            else if(id === 0){
    
                element = document.getElementById(`buttons${item[0].id}`);
            
                element.classList.remove('focusbutton');
        
                element.classList.add('button');  
            
            }
        })
    }

    addClass = (id) => {
        let  element = document.getElementById(`buttons${id}`);
        this.removeClassButton(id);

        element.classList.remove('button');
        element.classList.add('focusbutton');

    }

    focusScroll = (itemId, coord) =>{        
        let b = document.getElementById('categoria')
        let indexCategoria = this.pegandoIndexCategoria(itemId)

        if (indexCategoria === 0 ) return  b.scrollBy(0,0);
        
        return b.scrollBy((coord.x) - 100 ,0);
    
    }

    pegandoIndexCategoria = (id) => {
        const  {categoria} = this.state;
        let indexItemCategoria =  categoria.map((item, index) => item.filter((item) => {if (item.id === id) return index}))
        return indexItemCategoria
    }

    
    FecharModalAdd = () => {
        this.props.history.goBack();     
        this.setState({mostrarModalAdd: true});
    }

    FecharModalCadastrar = () => {
        this.setState({mostrarModalCadastrar: true});
    }

    FecharModalSacola = () => {
        this.setState({mostrarModalSacola: true});
    }

    FecharModalSobre = () => {                
        this.setState({mostrarModalSobre: false});
    }

    MostrarModalSobre = () => {                
        this.setState({mostrarModalSobre: true});
        this.props.history.push('/Sobre');
    }


    RotaInicial = ()=> {
        this.props.history.push('/');
    }

    addIndexDbComplementProduct = (array) => {
        this.setState({complementProduct: array})
    }

    MostrarModalAdd = () => {
        const {params:{id}}= this.props.match;
        const {produtosAdd, urlImg, complementProduct} = this.state;
       
        return(
            <AddProdutos 
                updateComplemento = {this.updateComplemento} 
                produtoAcompanhamento= {this.produtoAcompanhamento}
                updateComplementProductOne = {this.updateComplementProduct}
                updateComplementProduct={this.updateComplementProductItemsQtsMore}
                updateComplementProductItemsQtsAnyLess = {this.updateComplementProductItemsQtsAnyLess}
                updateComplementProductItemsRadio={this.updateComplementProductItemsRadio}
                updateComplementProductColor={this.updateComplementProductColor}
                complementProduct={complementProduct}
                resetComplementProduct={() => this.setState({complementProduct:[]})}
                addIndexDbComplementProduct={this.addIndexDbComplementProduct}
                FecharModal={() => this.FecharModalAdd()} 
                banco_cardapio = {this.state.banco_cardapio}
                idd={'AdicionarProduto'}
                id={id} 
                totalSacola = {() => this.verificandoUsuarioLogado()}
                location = {this.props}
                carregarProduto = {() => this.carregarProduto()}
                produtosAdd = {produtosAdd} 
                foto={urlImg}
                parametro={this.state.parametros}
            />   
        )
    }

    MostrarModalLogin = () => {
        return(
            <Login 
                FecharModalLogin={() => this.FecharModalLogin()} 
                taxaEntrega = {() => this.taxaEntrega()}
                complementProduct={this.state.complementProduct}
                mostrarModalEndereco={this.state.mostrarModalEndereco}
                banco_cardapio={this.state.banco_cardapio}
                FecharModalEsqueciSenha={() => this.FecharModalEsqueciSenha()}
                MudandoEstadoEsqueciSenha= {() => this.MudandoEstadoEsqueciSenha()}
                idd = {'Login'} 
                pegandoProdutosBanco={this.pegandoProdutosBanco}
                enderecoEscolhido={this.state.enderecoEscolhido}
                location = {this.props}
                MudandoEstadoSemLogin = {() => this.MudandoEstadoSemLogin()}
                getAllEndereco = { () => this.getAllEndereco() }
                EncapsulandoRequisicoes = {() => this.EncapsulandoRequisicoes()}
                enderecos={this.state.enderecos}
                mudandoEstadoEndereco = {() => this.mudandoEstadoEndereco()}
                carregarProdutosSacola = {() => this.carregarProdutosSacola()}
                stateSacola = {this.state.mostrarModalSacola}
                visiblePassword={this.state.visiblePassword}
            />   
        )
    }

    MostrarModalEsqueciSenha = () => {
        return(
            <ForgotPassword
            FecharModalEsqueciSenha={() => this.FecharModalEsqueciSenha()}
            banco_cardapio={this.state.banco_cardapio}
            RotaLogin={() => this.RotaLogin()}
            idd={'EsqueciSenha'} 
            location = {this.props}
            visiblePassword={this.state.visiblePassword}
            />   
        )
    }

    FecharModalLogin = () => {
        this.setState({mostrarModalLogin: true});
    }

    MudandoEstadoModalAdd = () => { 
        this.setState({mostrarModalAdd:false});
        if ( this.props.match.pathname === '/Sacola') this.verificandoUsuarioLogado();
    }

    MudandoEstadoCadastrar = () => {
        this.props.history.push('/UsuarioLogado/MeusDados');
        this.setState({userLoggedInfo: true});
    }

    MudandoEstadoSemLogin = () => {
        this.setState({mostrarModalCadastrar: false});
    }

    MudandoEstadoEsqueciSenha = () => {
        this.setState({mostrarModalEsqueciSenha: true});
    }

    FecharModalEsqueciSenha = () => {
        this.setState({mostrarModalEsqueciSenha: false});
    }

    fechandoEstadoCadastrarInfo = () => {
        this.setState({userLoggedInfo: false});
    }

    MudandoEstadoCadastrar2 = () => {
        this.setState({isLoadingEndereco: false});
    }

    mostrarModalCadastrar = () => {
        return(
            <Cadastrar
                FecharModalCadastrar={() => this.FecharModalCadastrar()}
                RotaLogin = {() => this.RotaLogin()}
                banco_cardapio = {this.state.banco_cardapio}
                idd={'Cadastrar'} 
                location = { this.props}
                visiblePassword={this.state.visiblePassword}
            />
        )
    }

    mostrarModalSobre = () => {
        return(
            <AboutScreen
                FecharModalSobre={() => this.FecharModalSobre()}
                idd={'Sobre'} 
                location = { this.props}
                About = {this.state.parametros.map(item => item.site_sobre)}
                Address = {this.state.parametros.map(item => `${item.site_logradouro}, ${item.site_numero} - ${item.site_bairro}, ${item.site_cidade}`)}
                phone = {this.state.parametros.map(item => item.site_telefone)}
            />
        )
    }

    mostrarModalSacola = () => {
        const {urlImg, cnpj, parametros} = this.state
        return(
            <SacolaProdutos 
                Messagem ={ () => this.Messagem()}
                banco_cardapio ={this.state.banco_cardapio}
                alteraNaoEntregamosAi = {this.alteraNaoEntregamosAi}
                message = {this.state.message}
                FecharModal={() => this.FecharModalSacola()} 
                naoEntregamosAi = {this.state.naoEntregamosAi}
                revalidadorNaoEntregamosAi={this.state.revalidadorNaoEntregamosAi}
                idd={'Sacola'} 
                foto={urlImg}
                cnpj={cnpj}
                tx_entrega={this.state.tx_entrega}
                taxaEntregaPorCep={() => this.taxaEntregaPorCep()}
                MostrarModalAdd={() => this.MostrarModalAdd()}
                MudandoEstadoModalAdd={() => this.MudandoEstadoModalAdd()}
                produtosSacola={this.state.produtosSacola}
                totalGeral={this.state.totalSacola}
                verificandoUsuarioLogado={() => this.verificandoUsuarioLogado()}
                carregarProdutosSacola={() => this.carregarProdutosSacola()}
                location = {this.props}
                alterandoQtdSacola= {() => this.alterandoQtdSacola()}
                alterandoTotalSacola= {() => this.alterandoTotalSacola()}
                verificandoQtdSacola = {() => this.verificandoQtdSacola()}
                pegandoQtdSacolaBanco= {this.pegandoQtdSacolaBanco}
                carregarPedidos={() => this.carregarPedidos()}
                getAllEndereco ={() => this.getAllEndereco()}
                getEnderecoSelecionado={() => this.getEnderecoSelecionado()}
                enderecoEscolhido={this.state.enderecoEscolhido}
                enderecos={this.state.enderecos}
                alterandoEstadoEndereco={() => this.alterandoEstadoEndereco()}
                mostrarModalEnd={() => this.mostrarModalEnd()}
                mostrarModalFormaPagamento={() => this.mostrarModalFormaPagamento()}
                NomeFormaPagamento={this.state.NomeFormaPagamento}
                alterarEstadoNomeFormaPagamento = {() => this.alterarEstadoNomeFormaPagamento()}
                fecharModalPagamento = {() => this.fecharModalPagamento()}
                parametros={parametros}
                alterarEntrega= {this.alterarEntrega} 
                mudandoEstadoEndereco = {() => this.mudandoEstadoEndereco()}
                RotaLogin={() => this.RotaLogin()}
                pegandoProdutosBanco ={() => this.pegandoProdutosBanco()}
                alterarEstadoEnderecos={() => this.alterarEstadoEnderecos()}
                mudandoEstadoEnderecoEscolhido ={this.mudandoEstadoEnderecoEscolhido}
                
            />
        )
    }

    alterandoQtdSacola = ()  => {
        this.setState({qtdItemSacola:0});
    }

    alterandoTotalSacola = ()  => {
        this.setState({totalSacola:0});
    }

    carregarPedidos = async() => {
        try {
            let IDUSUARIO = getToken('IDUSUARIO');

            if (IDUSUARIO !== null) {
                const service = new UsuarioService();
                const result  = await service.getOne({   
                    usuarioId: IDUSUARIO,
                    banco: this.state.banco_cardapio
                });
    
                if (result) {
                    this.setState({mostrarModalPedido:false});
                    this.props.history.push('/Pedidos');
                }
            }
            else{
                this.RotaLogin();
            }

        } catch (error) {
            if (error.response.status === 401) {
                this.RotaLogin();
                this.clearLocalStorage();
                this.alterandoQtdSacola();
                this.alterandoTotalSacola();
            }
        }

    }

    fecharModalPedidos = () => {
        this.setState({mostrarModalPedido: true});
        this.props.history.push('/');
    }
  
    abrirModalPedido =  () => {
        const {cnpj} = this.state;
        return(
            <MeusPedido
                fecharModalPedidos={() =>this.fecharModalPedidos()}
                alterandoEstadoMostrarMeusPedidos = {this.alterandoEstadoMostrarMeusPedidos}
                mostrarCancelarPedido = {() => this.mostrarCancelarPedido()}
                mostrarSuporte = {() => this.mostrarSuporte()}
                idd={'Pedidos'}
                cnpj= {cnpj}
                location= {this.props}
                parametros={this.state.parametros}
            />
        )
    }

    carregarProdutosSacola = () => {
        const {produtosAdd} = this.state;

        if (produtosAdd.length > 0) {
            this.setState({mostrarModalSacola:false});
            this.props.history.push('/Sacola');    
        }
        else{
            this.props.history.push('/');  
        }

    }
    
    pegandoQtdSacolaBanco = async() => {
        try {
            const service = new UsuarioProdutoApiNode();
            const result = await service.getAllProdutos({banco: this.state.banco_cardapio});

            if (result.status === 200) {
                const total = this.calcularTotalSacola(result.data.data);
                this.setState({produtosSacola:result.data.data, qtdItemSacola: result.data.data.length, totalSacola:total})
            }

        } catch (error) {

        }
    }

    verificandoQtdSacola =  () => {

        let db ;   
        const request = indexedDB.open('cardapio', 2 ); 
        request.onsuccess =   () => {
            db = request.result;
            const transaction = db.transaction(["Produtos"], "readwrite");
            const objectStore =  transaction.objectStore("Produtos");
            const result =  objectStore.getAll();

            result.onsuccess =  () => {

                let resultado  =  result

                if ( resultado.result.length > 0 ) {
                    const qtdItemSacola = resultado.result.length
                    this.setState({produtosSacola: resultado.result, qtdItemSacola});
                    this.totalSacola();
                }
                else{
                    const Usuariologado = getToken('TOKEN');
                    if (Usuariologado !== undefined && UsuarioService !== null )   this.setState({produtosSacola:[] ,qtdItemSacola:0, totalSacola: 0.00}) 
                
                }

            }
        }
    }

    calcularTotalSacola = (array) => {
        if (array.length > 0 ) {       
            const total = array.map(item => parseFloat(item.total)).reduce((item1, item2) => (item1 + item2)) 
            return total
        }
        return 0.00;
    }

    totalSacola = () => {
        const {produtosSacola} = this.state;
        const total = this.calcularTotalSacola(produtosSacola);
        this.setState({totalSacola: total});
    }

    RotaLogin = () => {
        const token = getToken('TOKEN');

        if (token !== '' && token !== null ) {
            let path = this.props.location.pathname;

            if(path === '/Sacola' ){
                this.props.history.push('/Endereco');
                return this.mudandoEstadoEndereco();
            }
        }
        this.setState({mostrarModalLogin: false});
        this.props.history.push('/Login');

        
    }

    RouteUserLogged=()=>{
        this.props.history.push('/UsuarioLogado');
    }

    closeUserLoggedModal=()=>{
        this.setState({userLogged:false});
    }

    mostrarModalUser =() => {
        
        return(
            <UserLoggedOptionsScreen 
                closeUserLoggedModal={this.closeUserLoggedModal} 
                banco_cardapio={this.state.banco_cardapio}
                location = {this.props}
                alteraNaoEntregamosAi={this.alteraNaoEntregamosAi}
                alterandoQtdSacola = {() => this.alterandoQtdSacola()}
                alterandoTotalSacola = {() => this.alterandoTotalSacola()}
                mudandoParaRota = {()=> this.MudandoEstadoCadastrar()} 
                mudandoParaRota2 ={()=> this.MudandoEstadoCadastrar2()}
                />
        )

    }

    mostrarModalUserInfo =() => {
        
        return(
            <UserLoggedInfoScreen 
                closeUserLoggedModal={this.closeUserLoggedModal} 
                location = {this.props}
                banco_cardapio={this.state.banco_cardapio}
                idd={'MeusDados'}
                alterandoQtdSacola = {() => this.alterandoQtdSacola()}
                alterandoTotalSacola = {() => this.alterandoTotalSacola()}
                mudandoParaRota = {()=> this.MudandoEstadoCadastrar()} 
                mudandoParaRota2 ={()=> this.MudandoEstadoCadastrar2()}
                fechandoEstadoCadastrarInfo = {() => this.fechandoEstadoCadastrarInfo()}
                mostrarLogin = {() => this.RotaLogin()}
                />
        )

    }

    verifyLoginState=()=>{
        // this.verificandoTokenUsuario();
        const token = getToken('TOKEN');

        if (token === null || token === ''){
            this.RotaLogin();
        }
        else{
            this.alterandoRouteUserLogged();
        }

    }

    verificandoTokenUsuario = async() => {
        try {
            let IDUSUARIO = getToken('IDUSUARIO') ;
            const service = new UsuarioService();
            const result  = await service.getOne(
                {   
                    usuarioId: IDUSUARIO,
                    banco: this.state.banco_cardapio
                });

            if(result.success === true){
                this.RotaLogin();
            }
            else{
                this.alterandoRouteUserLogged();
            }

        } catch (error) {
            if (error.response.status === 401 || error.response.status === 404 ) {
                this.clearLocalStorage();
                this.alterandoQtdSacola();
                this.alterandoTotalSacola();
                this.RotaLogin();

            }
        }
    }

    alterandoRouteUserLogged = () => {
        this.setState({userLogged:true});
        this.RouteUserLogged();
    }


    alterarNome = (value) => {
        this.setState({nome: value}) 
    }

    getEnderecoSelecionado = async () => {
        try {

            const token = getToken('TOKEN')
            if(token !== null){
                const service = new ServiceEndereco();
                const result = await service.getEnderecoSelecionado({banco : this.state.banco_cardapio});

                if (result.status === 200) {    
                    if (result.data.length > 0 ) {
                        this.setState({enderecoEscolhido:result.data}); 
                    }
                    else{
                        this.setState({enderecoEscolhido:[]});
                    }
            
                }
            }

        } catch (error) {
            console.log(error)
        }
    }

    getAllEndereco = async () => {
        try {
            const service = new ServiceEndereco();
            const result  = await service.getAllEnderecos({banco : this.state.banco_cardapio});
 
            if (result.status === 200 ){
                await this.getEnderecoSelecionado();
                this.setState({enderecos: result.data});            
            }

            return result.data.length

        } catch (error) {
            this.setState({enderecos: [], enderecoEscolhido:[] });   

            if (error.response.status === 401) {
                this.props.history.push('/Login');
                this.setState({isLoadingEndereco: true, mostrarModalLogin:false});

            }


        }
    }

    fecharModalListagemEnderecos = () => {
        if ( this.props.location.pathname ==='/UsuarioLogado/ListarEnderecos') {
            this.setState({userLogged: true, mostrarModalSacola:true}); 
            this.props.history.push('/UsuarioLogado');
        }
        else{
            // this.props.history.push('/Sacola');
            this.carregarProdutosSacola();
        }

        this.setState({isLoadingEndereco: true});

    }

    alterandoEstadoEndereco = () => {
        if (this.state.userLogged){
            this.props.history.push('/UsuarioLogado/ListarEnderecos') ;  
        }
        else{
            
            if(this.state.isLoadingEndereco === true){
                this.setState({isLoadingEndereco: false});
                this.props.history.push('/ListarEnderecos');
            }
            else if (this.state.enderecos.length > 0 ){
                this.setState({isLoadingEndereco: false});
                this.props.history.push('/ListarEnderecos');
            }
            else{
                this.setState({mostrarModalSacola:false})
                this.props.history.push('/Sacola'); 
            }
            // this.props.history.push('/ListarEnderecos') ;   
        }
 
    }

    mudandoEstadoEndereco = () => {
        this.setState({mostrarModalEndereco: false});    
        this.mostrarModalEnd();
    }

    alterarEstadoEnderecos = () => {
        this.setState({enderecos:[]});
    }

    mudandoEstadoEnderecoEscolhido =  (array) => {
        this.setState({enderecoEscolhido: array});
    }

    carregarProdutoNome = async (value) => {
        try {
            const {cnpj} = this.state;
            this.setState({nome: value});

            if (value !== "") {
                const service = new ServiceProduto();
                const result = await service.getAllProdutos({
                    cnpj,
                    nome: value,
                });
    
            if (result.success === true) {
                if (result.data[1].length > 0)
                    return this.alterarEstadoProdutoNome(result.data[1]);
                else {
                    return this.alterarEstadoProdutoNome([]);  
                }
            }
          }
          else {
            this.alterarEstadoProdutoNome([]);
          }

        } catch (error) {
            this.alterarEstadoProdutoNome([]);
        }
    };

    ListarEnderecos = () =>{
        return(
            <ListarEnderecos
                fecharModalListagemEnderecos={() => this.fecharModalListagemEnderecos()}
                banco_cardapio= {this.state.banco_cardapio}
                taxaEntregaPorCep = {() => this.taxaEntrega()}
                tx_entrega = {this.state.tx_entrega}
                idd={'ListagemEnderecos'}
                mudandoEstadoEndereco = {() => this.mudandoEstadoEndereco()}
                location = {this.props}
                enderecos={this.state.enderecos}
                alterarEstadoEnderecos={() => this.alterarEstadoEnderecos()}
                getAllEndereco={() => this.getAllEndereco()}
                mudandoEstadoEnderecoEscolhido = {this.mudandoEstadoEnderecoEscolhido}
                carregarProdutosSacola = {() => this.carregarProdutosSacola()}

            />
        )
    }

    mostrarModalEnd = () => {
        return(
            <Endereco
                FecharModal={() => this.setState({mostrarModalEndereco:true})} 
                banco_cardapio= {this.state.banco_cardapio}
                idd={'Endereco'}
                location = {this.props}
                getAllEndereco={() => this.getAllEndereco()}
                alterandoEstadoEndereco={() => this.alterandoEstadoEndereco()}
                userLogged={this.state.userLogged}
                FecharModalLogin = {() => this.FecharModalLogin()}
                mostrarModalLogin ={this.state.mostrarModalLogin} 
            />
        )
    }

    handleBackButton = () => {
        if (this.props.match.path === '/') {
            this.setState({
                mostrarModalLogin: true,
                mostrarModalAdd: true, 
                mostrarModalSacola: true,
                mostrarModalPedido: true,
                mostrarModalSobre : false,
                isLoadingEndereco : true,
                mostrarModalCadastrar: true,
                userLogged : false,
                mostrarModalPagamento: true,
                mostrarModalCancelarPedido: false,
                mostrarModalSuporte: false,
                mostrarModalEndereco : true,
                userLoggedInfo: false,
                mostrarModalEsqueciSenha : false

            });
        }
        else if (this.props.match.path === '/AdicionarProduto/:id/:nome' ||this.props.match.path === '/Sacola/AdicionarProduto/:id/:nome' ) {
            this.setState({mostrarModalAdd: false});
            this.MostrarModalAdd();
        }
        else if (this.props.match.path === '/UsuarioLogado') {
            this.setState({userLogged: true, userLoggedInfo: false, isLoadingEndereco: true});
        }
        else if (this.props.match.path ==='/UsuarioLogado/MeusDados') {
            this.setState({userLogged: true, userLoggedInfo: true});
        }
        else if (this.props.match.path ==='/Sacola') {
            this.setState({
                mostrarModalSacola : false, 
                isLoadingEndereco: true, 
                mostrarModalPagamento: true, 
                mostrarModalAdd:true,
                mostrarModalLogin: true
            });
        }
        else if (this.props.match.path ==='/UsuarioLogado/ListarEnderecos') {
            this.setState({isLoadingEndereco: false, mostrarModalEndereco: true});
        }
        else if (this.props.match.path ==='/ListarEnderecos') {
            this.setState({isLoadingEndereco: false, mostrarModalEndereco: true});
        }
        else if (this.props.match.path === '/Endereco' ||  this.props.match.path ==='/:id/Endereco') {
            this.setState({mostrarModalEndereco: false, isLoadingEndereco:false});
        }
        else if (this.props.match.path === '/FormaPagamento') {
            this.setState({mostrarModalPagamento:false });
        }
        else if (this.props.match.path === '/Login'){
            this.setState({mostrarModalLogin: false, mostrarModalCadastrar: true, mostrarModalEsqueciSenha: false});
        }
        else if (this.props.match.path === '/Login/Cadastrar') {
            this.setState({mostrarModalCadastrar: false, mostrarModalLogin: false});   
        }
        else if (this.props.match.path === '/Sobre') {
            this.setState({mostrarModalSobre: true});   
        }
        else if (this.props.match.path === '/Pedidos') {
            this.setState({mostrarModalPedido: false, mostrarModalCancelarPedido: false, mostrarModalSuporte: false, mostrarModalDetalhesPedido: true});   
        }
        else if (this.props.match.path === '/DetalhesPedido'){
            this.setState({mostrarModalDetalhesPedido: false, mostrarModalPedido: true});      
        }
        else if (this.props.match.path === '/Pedidos/CancelarPedido') {
            this.setState({mostrarModalCancelarPedido: true});   
        }
        else if (this.props.match.path === '/Pedidos/CancelarPedido/Suporte') {
            this.setState({mostrarModalSuporte: true});   
            
        }
        else if (this.props.match.path === '/Login/EsqueciSenha') {
            console.log('dasd')
            this.setState({mostrarModalEsqueciSenha:true, mostrarModalLogin: false})
        }
    }
    
    componentDidUpdate (prevProps, prevState, snapshot) {
        window.onpopstate  = e => {
            this.handleBackButton();
        }
    }


    mostrarMeusPedidos = () => {
        this.setState({mostrarModalDetalhesPedido:false});
    }   

    alterandoEstadoMostrarMeusPedidos = (item) => {

        this.setState({mostrarModalDetalhesPedido: false, arrayMeusPedidos:item});
        this.props.history.push('/DetalhesPedido');
    }

    fecharEstadoMostrarMeusPedidos = () => {
        this.setState({mostrarModalDetalhesPedido: true});
        this.props.history.goBack();
    }


    mostrarModalMeusPedidos = () => {
        const {arrayMeusPedidos, parametros} = this.state;
        return(
            <DetalhesPedido
                fecharEstadoMostrarMeusPedidos = {() => this.fecharEstadoMostrarMeusPedidos()}
                location={this.props.location}
                idd={'MeusPedidos'}
                arrayMeusPedidos={[arrayMeusPedidos]}
                cnpj={this.props.cnpj}
                parametros={parametros}           
            />
        )
    }


    fecharEstadoCancelarPedidos = () => {
        this.setState({ mostrarModalCancelarPedido: false});
    }

    mostrarCancelarPedido = () => {
        this.setState({mostrarModalCancelarPedido:true});
        this.props.history.push('/Pedidos/CancelarPedido');
    }
    

    mostrarModalCancelarPedido = () => {
        return(
            <CancelOrderScreen
                fecharEstadoCancelarPedidos = {() => this.fecharEstadoCancelarPedidos()}
                location={this.props.history}
                idd={'CancelarPedido'}
                parametros={this.state.parametros}
            />
        )
    }


    mostrarModalFormaPagamento = () => {
        this.setState({mostrarModalPagamento:false});
        this.props.history.push('/FormaPagamento');
    }

    alterarEstadoNomeFormaPagamento = () => {
        let NomeFormaPagamento = getToken('NOMEFORMAPAGAMENTO');
        this.setState({NomeFormaPagamento}); 
    } 

    fecharModalPagamento = () => {
        this.setState({mostrarModalPagamento: true});
        this.props.history.push('/Sacola');
    }

    
    fecharEstadoSuporte = () => {
        this.setState({mostrarModalSuporte: false});
    }

    mostrarSuporte = () => {
        this.setState({mostrarModalSuporte:true});
        this.props.history.push('/Pedidos/CancelarPedido/Suporte');
    }

    mostrarModalSuporte = () => {
        return(
            <SupportScreen
                fecharEstadoSuporte = {() => this.fecharEstadoSuporte()}
                location={this.props}
                idd={'Suporte'}
                parametros={this.state.parametros}
            />

        )
    }

    mostrarModalPagamento = () => {
        return(
            <FormaPagamento
                idd={'FormaPagamento'}
                totalGeral={this.state.totalSacola}
                fecharModalPagamento={() => this.fecharModalPagamento()}
                alterarEstadoNomeFormaPagamento = {() => this.alterarEstadoNomeFormaPagamento()}
                mostrarModalFormaPagamento = {() => this.mostrarModalFormaPagamento()}
                NomeFormaPagamento={this.NomeFormaPagamento}
                carregarProdutosSacola={() => this.carregarProdutosSacola()}
                parametro ={this.state.tx_entrega}
                entrega={this.state.entrega}
                alterarEntrega={() => this.alterarEntrega()}
                
            />
        )
    }

    teste = () => {
        this.inputRef.current.focus();
    }

    updateStatePromotion = () => {
        this.setState({promotion: true})
    }

    left = async(e) => {
        try {
            e.preventDefault();
            this.setState({countButton:  await  this.state.countButton  -1 });
    
            const BoxPromotion = document.getElementById(`boxcontainerpromotion`);
            const element = document.getElementById(`boxpromotion${this.state.countButton}`);

            if(element !== null) {
                const {x} = element.getBoundingClientRect();
    
                if (element.tabIndex  > 0 ) {
        
                    BoxPromotion.scrollBy((x) -45 , 0) ;
                    this.setState({arrowRight: true});
                }
                else {
                    this.setState({arrowLeft: false, arrowRight: true});
                    BoxPromotion.scroll(0, 0) ;
                }
            }
            else{
                
                console.log(element,'left', this.state.countButton)
            }
        

        } catch (error) {
            
        }


    }

    right = async (e) => {
        try {
            e.preventDefault();
            const{lastProductCategory} = this.state;
            this.setState({countButton: await this.state.countButton < lastProductCategory ? this.state.countButton + 1 : lastProductCategory });
    
   
            const BoxPromotion = document.getElementById(`boxcontainerpromotion`);
            const element = document.getElementById(`boxpromotion${+this.state.countButton}`);

            const {x} = element.getBoundingClientRect();
            this.setState({arrowLeft: true});   
            BoxPromotion.scrollBy(x - 45, 0) ; 

        

        } catch (error) {
            console.log(error)
        }          
    }


 
    verifyScroll = () => {

        const BoxPromotion = document.getElementById(`boxcontainerpromotion`);
       
        if (BoxPromotion.scrollWidth <= BoxPromotion.offsetWidth +BoxPromotion.scrollLeft) {
            this.setState({arrowRight: false})
        }
       
    }

    render () {
        const { categoria, 
                produtos, 
                urlImg, 
                qtdItemSacola, 
                totalSacola,
                mostrarModalAdd, 
                mostrarModalSacola, 
                ProdutoNome,
                mostrarModalLogin,
                mostrarModalCadastrar,
                searchActive,
                userLogged,
                mostrarModalPedido,
                isLoadingEndereco,
                mostrarModalEndereco,
                userLoggedInfo,
                mostrarModalPagamento,
                parametros,
                mostrarModalSobre,
                mostrarModalDetalhesPedido,
                mostrarModalCancelarPedido,
                mostrarModalSuporte,
                mostrarModalEsqueciSenha,
                arrowRight,
                arrowLeft,
                promotion,
                banner 
              } = this.state;

        return(
            <>
                <BoxInicial id='containergeralwrapper'>
                    
                    {
                        urlImg !== '' ? (
                            <Header 
                                parametro={parametros} 
                                urlImg={urlImg}
                                MostrarModalSobre={() => this.MostrarModalSobre()}
                                banner={banner}
                            />
                        )   
                        :(
                            null
                        )
                    }
                        
                    
                    <S.Container isSearchButtonActived={searchActive} id="teste1">
                        <S.SearchIcon>
                            <img src={IconSearch} alt="icone de uma lupa para busca" />
                        </S.SearchIcon>
                        <S.InputBox
                            ref={this.inputRef}
                            id="input"
                            autocomplete="off"
                            placeholder="O que deseja?"
                            onClick={() => this.ActiveSearchs(true)}
                            onChange={(e) => this.carregarProdutoNome(e.target.value)}
                            value={this.state.nome}
                        />

                        <S.ClearSearchIcon
                            isSearchButtonActived={searchActive}
                            onClick={()=> (this.ActiveSearchs(false), this.setState({nome:''}), this.alterarEstadoProdutoNome([]) )}
                        >
                            <img
                            src={IconClearSearch}
                            alt="icone de um x para limpar a barra de pesquisa"
                            />
                        </S.ClearSearchIcon>
                    </S.Container>
                  

                    {/* <SearchBox 
                        isSearchButtonActived={searchActive} 
                        changeSearchButtonState={this.ActiveSearchs} 
                        clearSearchBox={this.clearSearchBox} 
                        alterarNome={this.alterarNome} 
                        nome={nome}
                        automaticSearch={this.onChangeNome}
                        alterarEstadoProdutoNome={this.alterarEstadoProdutoNome}
                        cnpj={cnpj}
                        produtos={produtos}
                    /> */}

                    {

                        ProdutoNome.length > 0 ?(
                            <>
                                <BoxSecaoProduto >
                                    {
                                        ProdutoNome.map(item => 
                                            <div  className="containergrupoprodutos" key={item.id}>                    
                                                <BoxCard> 
                                                    <Link className='link'  onClick={() => this.setState({mostrarModalAdd: false })} to={`/AdicionarProduto/${item.id}/${item.nome}`}>

                                                        <Row >
                                                            <BoxFoto src={`${urlImg}${item.foto_descricao}`} alt={item.foto_descricao}/>                                        
                                                        </Row>
                                                                    
                                                        <BoxCardinterno>
                                                            <p className="nomeproduto">{item.nome}</p>
                                                            <p className="infoitem">{item.descricao_web}</p> 
                                                            <p className="valoritem">{`R$ ${parseFloat(item.preco_venda).toLocaleString('pt-br', {style:'currency', currency:'BRL'})}`}</p> 
                                                        </BoxCardinterno>
                                                        
                                                    </Link> 
                                                </BoxCard> 
                                            </div>  
                                            )
                                    }
                                </BoxSecaoProduto>
                            </>
                        ) 
                        :(
                            <>
                                <div className="itens-categoria-wrapper" >
                                    <Row id='categoria'>
                                        {
                                            categoria.map((item) => {
                                                return(
                                                    
                                                        item.map((item) => {
                                                            return(
                                                                <Links  key={item.id}  to={(item.nome).toUpperCase()} className='linkinterno'
                                                                    spy={true} smooth={true} offset={-100} duration={1000} 
                                                                >

                                                                    <button id = {`buttons${item.id}`} className='button' 
                                                                    onClick={ () => this.getCoordenate(item.id) }
                                                                    >
                                                                        {item.nome}
                                                                    </button>
                            
                                                                </Links> 
                                                            )
                                                        })
                                                )
                                            })
                                        } 

                                    </Row>

                                </div>

              

                                {
                                    this.state.promotion ? (
                                        <Boxcategory id='promotion'>
                                            <h2 >PROMOÇÃO</h2>
                                    
                                        </Boxcategory>
                                    )
                                    :(
                                        null
                                    )
                                }
                      

                                {
                                    this.state.productCategory.length > 0 ? (

                   
                                            <Boxwrapperpromotion >
                                                
                                                {
                                                    arrowLeft? (
                                                        <BoxButtonLeft onClick={this.left} src={IconeVoltar} alt='icone de seta para avançar'
                                            
                                                        />
                                                    )
                                                    :(
                                                        null
                                                    )
                                                }
                                           

                                                <BoxContainerpromotion id='boxcontainerpromotion' onScroll={() => this.verifyScroll()} >
                                            
                                                    {
                                                        
                                                        this.state.productCategory.map((item, index) => 
                                                        
                                                                    
                                                            <BoxCardPromotion className="containergrupoprodutos" key={index+1
                                                            } id={`boxpromotion${index+1}`} tabIndex={index}> 
                                                                <Link className='link'  onClick={() => this.setState({mostrarModalAdd: false })} to={`/AdicionarProduto/${item.id}/${item.link_web}`}>
                                                                    <Row >
                                                                        {/* <BoxFoto src={`${urlImg}${item.foto_descricao}`} alt={item.foto_descricao}/>                                         */}
                                                                        <LazyLoadImage
                                                                            width={100} 
                                                                            height={100}
                                                                            src={`${urlImg}${item.foto_descricao}`}
                                                                            alt={item.foto_descricao}
                                                                        />
                                                                        
                                                                        {/* <BoxFoto src={`http://teste.sistefood.com.br/img/19651715000132_25_1_pd.webp`}/>                                         */}
                                                                    </Row>
                                                            
                                                                    <BoxCardinternoPromotion>
                                                                        <p className="nomeproduto">{item.nome}</p>
                                                                        <p className="infoitem">{item.descricao_web}</p> 

                                                                        <div className="prices">
                                                                            <p className="valuepromotion">{`${parseFloat(item.preco_comparativo_web).toLocaleString('pt-br', {style:'currency', currency:'BRL'})}`}</p> 
                                                                            <p className="valuenotpromotion">{`${parseFloat(item.preco_venda).toLocaleString('pt-br', {style:'currency', currency:'BRL'})}`}</p> 
                                                                            <IconPromotion src={iconPromotion} alt={'ícone de promoção'}/>
                                                                        </div>
                                                                    </BoxCardinternoPromotion>    
                                                    
                                                                </Link> 
                                                            </BoxCardPromotion> 
                                            

                                                        )
                                                    }
                                                 </BoxContainerpromotion>

                                                 {
                                                    arrowRight ? (
                                                        <BoxButtonRight onClick={this.right} src={IconeVoltar} alt='icone de seta para voltar'/>
                                                    )
                                                    :(
                                                        null
                                                    )
                                                }

                                            </Boxwrapperpromotion>
                                          
                                   

                                    
                                 
                                    )
                                    :(
                                        null
                                    )
                                }

                                {
                                    produtos.length === 0 ? 
                                    (
                                        <Row className='spinner'>
                                            <Spinner animation="border" variant="primary" />
                                        </Row>
                                    )
                                    :(
                
                                        Object.entries(produtos).map((item) => {
                                            return(
                                                <BoxContainer  key={item[1].id} >
                                                    <div  className="categoriaitens" id ={`category${item[1].id}`} >
                                                        <h2 id={item[1].nome}>{item[1].nome}</h2>
                                                    </div>
                                            
                                                    <BoxSecaoProduto>
                                                        {
                                                            item[1].itens.map(item =>                                 
                                                    
                                                                <div  className="containergrupoprodutos" key={item.id}>                    
                                                                    <BoxCard> 
                                                                        <Link className='link'  onClick={() => this.setState({mostrarModalAdd: false })} to={`/AdicionarProduto/${item.id}/${item.link_web}`}>
                                                                            <Row >
                                                                                {/* <BoxFoto src={`${urlImg}${item.foto_descricao}`} loading="lazy"  alt={item.foto_descricao}/>                                         */}
         
                                                                                <LazyLoadImage
                                                                                    width={100} 
                                                                                    height={100}
                                                                                    src={`${urlImg}${item.foto_descricao}`}
                                                                                    alt={item.foto_descricao}
                                                                                />
                                                                            </Row>
                                                                    
                                                                       
                                                                      
                                                                                {
                                                                                    item.oferta_web ==='S' ?(
                                                                                        <>
                                                                                        <BoxCardinternoItemsPromotion>
                                                                                            <p className="nomeproduto">{item.nome}</p>
                                                                                            <p className="infoitem">{item.descricao_web}</p> 
                                                                                            <div className="valuespromotion">
                                                                                                
                                                                                                <p className="valuepromotion">{`${parseFloat(item.preco_comparativo_web).toLocaleString('pt-br', {style:'currency', currency:'BRL'})}`}</p> 
                                                                                                <p className="valuenotpromotion">{`${parseFloat(item.preco_venda).toLocaleString('pt-br', {style:'currency', currency:'BRL'})}`}</p> 
                                                                                            </div>
                                                                                        </BoxCardinternoItemsPromotion>

                                                                                        <div className="iconpromotion">
                                                                                            <IconPromotion src={iconPromotion} alt={'ícone de promoção'}/>
                                                                                        </div>    
                                                                                  
                                                                                        </>
                                                                                   
                                                                                    )
                                                                                    :(
                                                                                        <BoxCardinterno>
                                                                                        
                                                                                        
                                                                                        <p className="nomeproduto">{item.nome}</p>
                                                                                        <p className="infoitem">{item.descricao_web}</p> 
                                                                                        
                                                                                        <p className="valoritem">{`${parseFloat(item.preco_venda).toLocaleString('pt-br', {style:'currency', currency:'BRL'})}`}</p>

                                                                          
                                                                                        </BoxCardinterno>
                                                                                    )
                                                                                }
                                                                                
                                                            
                                                            
                                                                        </Link> 
                                                                    </BoxCard> 
                                                                </div>
                                                            )
                                                        }
                                                    </BoxSecaoProduto>                                                    
                                                </BoxContainer>
                                            )
                                        })
                                    )
                                }
                            </>
                        )
                    }

                    {
                        mostrarModalAdd === false ? (
                            <this.MostrarModalAdd/>
                        )
                        :(
                            null
                        )
                    }

                    {

                        mostrarModalSacola === false ? (
                            <this.mostrarModalSacola />
                        )
                        :(
                            null
                        )
                    }

                    {
                        mostrarModalLogin === false ? (
                            <this.MostrarModalLogin/>
                        )
                        :(
                            null
                        )
                    }

                    {
                        mostrarModalCadastrar === false ? (
                            <this.mostrarModalCadastrar/>
                        )
                        :(
                            null
                        )
                    }

                    {
                        userLogged === true? (
                            <this.mostrarModalUser/>
                        )
                        :(
                            null
                        )
                    }

                    {
                        userLoggedInfo === true? (
                            <this.mostrarModalUserInfo/>
                        )
                        :(
                            null
                        )
                    }

                    {
                        mostrarModalPedido === false? (
                            <this.abrirModalPedido/>
                        ):(
                            null
                        )
                    }

                    {
                        isLoadingEndereco === false ? (
                            <this.ListarEnderecos/>
                        ) 
                        :(
                            null
                        )
                    }

                    {
                        mostrarModalEndereco === false ? (
                            <this.mostrarModalEnd/>
                        )
                        :(
                            null
                        )
                    }

                    {   
                        mostrarModalPagamento === false ? (
                            <this.mostrarModalPagamento/>
                        )
                        :(
                            null
                        )
                            
                    }

                    {   
                        mostrarModalSobre === true ? (
                            <this.mostrarModalSobre/>
                        )
                        :(
                            null
                        )
                            
                    }

                    {
                        mostrarModalDetalhesPedido === false ? (
                            <this.mostrarModalMeusPedidos />
                        )
                        :(
                            null
                        )
                    }

                    {
                        mostrarModalCancelarPedido === true ? (
                            <this.mostrarModalCancelarPedido/>
                        )
                        :(
                            null
                        )
                    }

                    {
                        mostrarModalSuporte === true ? (
                            <this.mostrarModalSuporte/>
                        )
                        :(
                            null
                        )
                    }

                    {
                        mostrarModalEsqueciSenha === true ? (
                            <this.MostrarModalEsqueciSenha/>
                        )
                        :(
                            null
                        )
                    }

                </BoxInicial>

                <InfoBagBox totalPurchases={totalSacola} totalItemsBag={qtdItemSacola} carregarProdutosSacola={()=> this.carregarProdutosSacola()} /> 
            
                <BoxInfoPedidos>
                    <BoxIconNavBar src={Pesquisar}  onClick={() => (this.setState({searchActive:true}), this.teste())}/>
                    <BoxIconNavBar src={Pedido} onClick={() => this.carregarPedidos()}/>
                    <ItemsBagButton totalItemsBag={qtdItemSacola} carregarProdutosSacola={()=> this.carregarProdutosSacola()}/>
                    {/* <BoxIconNavBar onClick={() => this.MostrarModalSobre()} src={Sobre}/> */}

                    {
                        promotion ? (
                            <Links  to={'promotion'} className='linkinterno'
                                spy={true} offset={-100} 
                            >
    
                                <BoxIconNavBar src={Promotion} onClick={() => this.removeClassButton(0)} /> 
    
                            </Links>
                        )
                        :(
                            <BoxIconNavBar src={Promotion} onClick={() => alert('Ops, Estamos sem promoção no momento')} />  
                        )
                    }
      

                    <BoxIconNavBar src={Usuario} onClick={() => getToken('TOKEN') === null? this.RotaLogin() : this.verifyLoginState()}/>
                </BoxInfoPedidos> 
        </>
        )
    }
}
export default Headers