import baseApi from "./api";
import baseURL from "../configs/baseURL";



class ServiceCnpjCardapio {
    constructor(){
        this.api = baseApi(baseURL.API_CNPJ_CARDAPIO);
    }

    async getCnpj (modalCnpj) {
        const result = await this.api.post('getCnpjCardapio', modalCnpj);
        
        return result.data;

    }
}


export default ServiceCnpjCardapio ;