import styled from "styled-components";

export const BoxContainer = styled.div`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  flex-direction: column;
  background: ${props=>props.theme.background.White_2};
  width: 100%;
  max-width: 520px;
  @media (min-width: 280px) and (max-width: 520px) {
    width: 100%;
  }
`;

export const BoxHeader = styled.header`
  width: 100%;
  text-align: center;
  padding: 1em;
  background: ${props=>props.theme.background.Primary};
  /* background: aqua; */
  height: 52px;
  border-bottom: solid 1px ${props=>props.theme.colors.Grey_2};

  h1 {
    margin: 0;
    padding: 0;
    text-align: center;
    /* justify-content: center; */
    /* align-items: center; */
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: ${props=>props.theme.text.Black_1};
  }
`;

export const BoxImageHeader = styled.div`
  width: 100%;
  max-width: 520px;
  height: 198px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
`;

export const BoxModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 2020;
  background-color: rgba(0, 0, 0, 0.3);
`;

export const BoxLogin = styled.div`
  padding: 0px 32px 32px;
  display: flex;
  overflow-x: auto;
  align-items: center;
  max-width: 520px;
  flex-direction: column;
  padding-bottom: 1em;
  justify-content: center;

  .login-text-wrapper {
    display: flex;
    justify-content: center;
    font-size: 20px;
    line-height: 24px;
    font-weight: 700;
    color: ${props=>props.theme.text.Black_1};
    margin: 26px 0px;
  }

  /* .row {
    margin: 0;
    padding: 0;
    width: 100%;
    justify-content: center;

    .forgot-password-text-wrapper {
      display: flex;
      justify-content: end;
    }

    .input-text-password,
    .input-text-email {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      color: ${props=>props.theme.text.Black_1};
    }

    .forget-password-text {
      font-size: 12px;
      line-height: 18px;
      font-weight: 400;
      color: ${props=>props.theme.text.Primary};
      margin-right: 14px;
      text-decoration: underline;
    } */
/* 
    form {
      width: 100%;
    }

    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px #fff inset;
    }

    input {
      color: ${props=>props.theme.text.Black_1};
      height: 48px;
      width: 100%;
      border-radius: 10px;
      border: solid 1px ${props=>props.theme.colors.Grey_3};
      font-size: 14px;
      line-height: 12px;
      font-weight: 400;
      background: ${props=>props.theme.background.White_2};
      outline: none;
      padding: 15px 12px;
      margin-bottom: 16px;
      margin-top: 8px;

      :focus {
        border: 2px solid black;
      }
    }

    .senha {
      margin-bottom: 8px;
    } */

    /* .signup-wrapper {
      margin-top: 26px;
      font-size: 14px;
      display: flex;
      justify-content: center;
      padding-top: 32px;
      border: solid 1px ${props=>props.theme.colors.Grey_3};

    .signup-link-text {
      text-decoration: underline;
      margin-left: 6px;
      }
    }

    a {
      color: ${props=>props.theme.text.Red_1};
    }
  } */

  .info-wrapper {
    text-align: center;
    color: ${props=>props.theme.text.Primary};
  }

  @media (min-width: 320px) and (max-width: 520px) {
    width: 100%;
  }
`;

export const Footer = styled.footer`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 168px;
  
`;

export const BoxSuportInfo = styled.div`
  width: 100%;
  height: 135px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 10px;
  border: solid 1px ${props=>props.theme.colors.Grey_2};
  margin-bottom: 20px;

  .logo-image-wrapper {
    margin: 10px 0;
    width: 48px;
    height: 48px;
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    img{
      width: 42px;
      height: 42px;
    }
  }

  h4 {
    font-size: 14px;
    line-height: 18px;
    font-weight: 400;
    color: ${props=>props.theme.text.Black_1};
    margin-bottom: 4px;
  }

  p {
    font-size: 14px;
    line-height: 18px;
    font-weight: 700;
    margin-bottom: 16px;
  }
`;

export const BoxIconeVoltar = styled.img`
  display: flex;
  position: absolute;
  top: 12px;
  border: none;
  cursor: pointer;
`;
